import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  redirectionUrl = '';

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean {
    const userHasAccess =
      !route?.data['role'] || this.authService.hasAccess(route?.data['role']);

    const currentUrl = state.url;
    this.setRedirectionUrl(currentUrl);
    if (!userHasAccess) {
      this.router.navigate(['/error-page']);
      return false;
    }

    if (this.authService.isUserAuthenticated()) {
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }

  setRedirectionUrl(url: string) {
    this.redirectionUrl = url;
  }
}
