<div class="dashboard-container">
  <div class="banner-container">
    <div class="welcome-text__container">
      <span class="welcome">مرحبا بك في </span>
      <h1 class="welcome-text">
        اعتماد البرامج التدريبية في مجال كفاءة الطاقة
      </h1>
    </div>
    <img [src]="banner" alt="banner" class="banner-image" />
  </div>
  <app-information-panel *ngIf="isOrganization()"></app-information-panel>
  <app-auditor-table
    *ngIf="isInternalAuditor() || isTechnicalAdvisor()"
  ></app-auditor-table>
  <app-committee-secretary-dashboard
    *ngIf="isCommitteeSecretary()"
  ></app-committee-secretary-dashboard>
  <committee-table
    *ngIf="isCommitteeMember() || isCommitteeHead()"
  ></committee-table>
  <general-manager-table *ngIf="isGeneralManager()"></general-manager-table>
  <app-financial-auditor-table
    *ngIf="isFinancialAuditor()"
  ></app-financial-auditor-table>
</div>
