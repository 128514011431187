<div class="container">
  <loading-spinner></loading-spinner>
  <app-table
    *ngIf="!(isLoading$ | async)"
    [isDateFilter]="true"
    [title]="'allOrders'"
    [headers]="headers"
    [options]="status"
    [data]="tableData?.data"
    [totalRecords]="tableData?.totalRecords"
    [options]="status"
  ></app-table>
</div>
