<svg
  class="svg-icon"
  version="1.1"
  [attr.width]="desiredWidth"
  [attr.height]="desiredHeight"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <use [attr.xlink:href]="'#' + name"></use>
</svg>
