import { Component, Input } from '@angular/core';
import { IconName } from 'src/app/types/icon-name.type';

@Component({
  selector: 'app-svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss'],
})
export class SvgIconComponent {
  @Input() name: IconName | string = '';
  @Input() desiredWidth;
  @Input() desiredHeight;
}
