<div class="table-container" [ngClass]="customClass">
  <div class="table">
    <div class="table-container__inner">
      <app-page-title
        *ngIf="title"
        [title]="title"
        [customClass]="'medium-font'"
      ></app-page-title>

      <div class="search-bar__container">
        <app-search-bar-audit
          [languageOptions]="languageOptions"
          [isDateFilter]="isDateFilter"
          [applicationTypeOptions]="applicationTypeOptions"
          [options]="options"
          (filtersChanged)="onFiltersChanged($event)"
          [selectionMode]="'range'"
          [receiptsTable]="receiptsTable"
          [isFinancialAuditor]="isFinancialAuditor"
        ></app-search-bar-audit>
      </div>

      <p-table
        [rows]="rowsPerPage"
        (onPage)="onPageChange($event)"
        [columns]="headers"
        [value]="filteredData"
        [tableStyle]="{ 'min-width': '50rem' }"
        paginatorLocale="ar"
      >
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th class="table-header" *ngFor="let col of columns">
              {{ col.name }}
            </th>
            <th *ngIf="showEyeButtonColumn" class="table-header"></th>
            <th *ngIf="receiptsTable" class="table-header"></th>
          </tr>
        </ng-template>
        <ng-template
          pTemplate="body"
          let-rowData
          let-columns="columns"
          let-rowIndex="rowIndex"
        >
          <tr>
            <td class="table-data" *ngFor="let col of columns">
              <ng-container
                *ngIf="
                  col.value === 'trainingOrganizationStatusStr' && receiptsTable
                "
              >
                <span
                  [ngStyle]="getStyles(col, rowData)"
                  class="application-status"
                  >مكتمل</span
                >
              </ng-container>

              <ng-container
                *ngIf="
                  col.value === 'trainingOrganizationStatusStr' &&
                  !receiptsTable
                "
              >
                <span
                  [ngStyle]="getStyles(col, rowData)"
                  class="application-status"
                  >{{ rowData[col.value] }}</span
                >
              </ng-container>
              <ng-container *ngIf="col.value === 'reportId'">
                <span
                  class="report-id"
                  (click)="
                    rowNavigationHandler(
                      rowData.id,
                      rowData.trainingOrganizationStatusId,
                      rowData.reportId
                    )
                  "
                  >{{ rowData[col.value] }}</span
                >
              </ng-container>
              <ng-container *ngIf="col.value == 'totalVotes'">
                <span class="votes">
                  <span class="current-votes">
                    {{ rowData[col.value] }}
                  </span>
                  <span class="total-votes"> /5</span>
                </span>
              </ng-container>
              <ng-container
                *ngIf="
                  col.value !== 'trainingOrganizationStatusId' &&
                  col.value !== 'trainingOrganizationStatusStr' &&
                  col.value !== 'totalVotes' &&
                  col.value !== 'reportId'
                "
              >
                <span [ngStyle]="getStyles(col, rowData)">
                  {{ rowData[col.value] }}
                </span>
              </ng-container>
            </td>
            <td
              *ngIf="showEyeButtonColumn && !rowData?.isEditable"
              class="table-data"
            >
              <app-svg-icon
                (click)="
                  rowNavigationHandler(
                    rowData.id,
                    rowData.trainingOrganizationStatusId,
                    rowData.reportId
                  )
                "
                name="eye-icon"
                class="eye-icon"
              ></app-svg-icon>
            </td>
            <td *ngIf="receiptsTable" class="table-data align-to-end-of-cell">
              <button
                class="send-to-general-manager-button"
                (click)="
                  sendToGeneralManagerButtonHandler(
                    rowData.trainingOrganizationId
                  )
                "
              >
                إرسال إلى المدير العام
              </button>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <div [ngClass]="{ hidden: !filteredData?.length }">
        <p-paginator
          [rows]="rowsPerPage"
          [totalRecords]="totalRecords"
          [first]="(currentPage - 1) * rowsPerPage"
          (onPageChange)="onPageChange($event)"
        ></p-paginator>
      </div>
    </div>
  </div>
  <div class="success-modal__container">
    <app-modal
      [modalContent]="'submission'"
      [showModal]="showModal"
      (closeModal)="closeModel()"
      [customClass]="'success-modal'"
      [isCloseVisible]="!(isLoading$ | async)"
    >
      <loading-spinner>جاري إرسال الطلب...</loading-spinner>
      <div *ngIf="!(isLoading$ | async)" class="modal-body">
        <app-svg-icon class="success-icon" name="success-icon"></app-svg-icon>
        <span class="modal-text">تم إرسال الطلب إلى المدير العام بنجاح</span>
      </div>
    </app-modal>
  </div>
</div>
