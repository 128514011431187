<div class="container">
  <div *ngIf="!readOnly; else resultsBlock">
    <p-tabView
      [(activeIndex)]="activeTabIndex"
      (onChange)="tabIndex.emit($event.index)"
    >
      <p-tabPanel [header]="'executiveSummary' | translate">
        <div class="flex padding">
          <app-page-title
            [title]="'executiveSummary'"
            [customClass]="['application-details']"
          ></app-page-title>
        </div>

        <loading-spinner [isLoading$]="isLoadingSummary" />
        <ng-container *ngFor="let stage of generalNotesArray; let i = index">
          <div [class]="'padding evaluation-stage__' + (i + 1)">
            <div class="stages">
              <div class="stages-title">
                {{ stageHeaderTextHandler(stage.versionNumber) }}
              </div>
            </div>

            <div class="flex">
              <app-page-title
                [title]="'تقييم المدقق الداخلي'"
                [customClass]="['general-note-title']"
              ></app-page-title>
              <img src="assets/images/checkMark.svg" />
            </div>
            <p
              class="text general-note"
              [innerHTML]="stage.internalAuditorNote || noNotesHTML"
            ></p>

            <div class="flex">
              <app-page-title
                title="تقييم الخبير الفني"
                [customClass]="['general-note-title']"
              ></app-page-title>
              <img src="assets/images/checkMark.svg" />
            </div>
            <p
              class="text general-note"
              [innerHTML]="stage.technicalAdvisorNote || noNotesHTML"
            ></p>

            <ng-container *ngIf="getTechnicalAdvisorEvaluationByStage(i + 1)">
              <div class="flex">
                <app-page-title
                  title="تقييم الخبير"
                  [customClass]="['general-note-title']"
                ></app-page-title>
              </div>
              <div class="technical-advisor-evaluation-file flex">
                <file-icon
                  [file]="getTechnicalAdvisorEvaluationByStage(i + 1).file"
                  [index]="i"
                  readOnly="true"
                ></file-icon>
                <img
                  src="assets/images/download-icon.svg"
                  class="img-btn"
                  (click)="getFile(getTechnicalAdvisorEvaluationByStage(i + 1).file)"
                />
              </div>
            </ng-container>

            <div class="flex">
              <app-page-title
                [title]="'needVisit'"
                [customClass]="['general-note-title']"
              ></app-page-title>
              <img src="assets/images/checkMark.svg" />
            </div>
            <p class="text">
              {{
                stage.isNeededFieldVisit
                  ? 'تمت المعاينة'
                  : 'لا توجد حاجة للزيارة الميدانية'
              }}
            </p>

            <div class="flex margin">
              <div class="stages-title">
                {{ 'committeeObservations' | translate }}
              </div>
              <img src="assets/images/checkMark.svg" />
            </div>
            <p
              class="text general-note"
              [innerHTML]="stage.committeeSecretaryNote || noNotesHTML"
            ></p>

            <div *ngIf="i < generalNotesArray.length - 1" class="divider"></div>
          </div>
        </ng-container>

        <div class="buttons">
          <app-custom-button
            [buttonText]="'nextPage' | translate"
            buttonStyle="greenButton"
            (click)="nextPage()"
          />
        </div>
      </p-tabPanel>

      <p-tabPanel header="لوحة النقاش">
        <div class="discussion-board_container">
          <div class="discussion-board_content">
            <app-discussion-board
              [trainingOrganizationId]="trainingOrganizationId"
            />
          </div>
          <div class="buttons">
            <app-custom-button
              [buttonText]="'previousPage' | translate"
              buttonStyle="transperantGreenButton"
              (click)="previousPage()"
            />
            <app-custom-button
              buttonText="الانتقال للتصويت"
              buttonStyle="greenButton"
              (click)="nextPage()"
            />
            <app-custom-button
              *ngIf="isCommitteSecretary"
              buttonStyle="greenButton"
              buttonText="إعادة الطلب إلى المدقق الداخلي"
              (click)="
                showReturnFromCommitteeSecretaryModal = true;
                bodyScrollService.toggleBodyScroll(false)
              "
            />
            <app-custom-button
              *ngIf="isCommitteSecretary"
              buttonStyle="transperantGreenButton"
              buttonText="إعادة الطلب إلى الخبير الفني"
              (click)="
                showReturnFromCommitteeSecretaryModal = true;
                returnToTechnicalAdvisor = true;
                bodyScrollService.toggleBodyScroll(false)
              "
            />
            <app-modal
              *ngIf="isCommitteSecretary"
              [modalContent]="'submission'"
              [showModal]="showReturnFromCommitteeSecretaryModal"
              (closeModal)="closeModel()"
              [customClass]="'success-modal'"
              [isCloseVisible]="!(isLoading | async)"
            >
              <div
                class="modal-body modal-only-text"
                *ngIf="!sentSuccessfully; else successPopup"
              >
                <span class="modal-text"
                  >هل ترغب في تأكيد إعادة الطلب إلى
                  {{
                    returnToTechnicalAdvisor
                      ? 'الخبير الفني'
                      : 'المدقق الداخلي'
                  }}؟</span
                >
                <div class="modal-buttons-container">
                  <app-custom-button
                    buttonText="نعم, تأكيد"
                    buttonStyle="greenButton"
                    (click)="returnAppFromCommitteeSecretary()"
                  />
                  <app-custom-button
                    (click)="closeModel()"
                    buttonText="إلغاء"
                    buttonStyle="transperantGreenButton"
                  />
                </div>
              </div>
              <ng-template #successPopup>
                <loading-spinner>جاري ارسال الطلب...</loading-spinner>
                <div *ngIf="!(isLoading | async)" class="modal-body">
                  <app-svg-icon
                    class="success-icon"
                    name="success-icon"
                  ></app-svg-icon>
                  <span class="modal-text success"
                    >تم إعادة الطلب إلى
                    {{
                      returnToTechnicalAdvisor
                        ? 'الخبير الفني'
                        : 'المدقق الداخلي'
                    }}
                    بنجاح</span
                  >
                </div>
              </ng-template>
            </app-modal>
          </div>
        </div>
      </p-tabPanel>

      <p-tabPanel header="تصويت لجنة الاعتماد">
        <div class="flex padding voting-tab-title-container">
          <app-page-title
            [title]="'accreditedVotes'"
            [customClass]="['application-details']"
          ></app-page-title>

          <div class="center vote-count-container">
            <span class="vote-count">
              {{ 'voteCount' | translate }}
            </span>
            <div class="votecount">
              <span class="grey"> {{ totalVotes }} </span>
              /5
            </div>
          </div>
        </div>

        <div class="padding">
          <div *ngIf="!isCommitteSecretary && editMode">
            <div class="flex center">
              <div>
                <app-page-title
                  [title]="currentMemberVoteData?.member?.userName"
                  [customClass]="['medium-font', 'green-color']"
                ></app-page-title>
                <p class="text role-name">
                  {{ currentMemberVoteData?.member?.role }}
                </p>
              </div>
              <div class="flex" style="gap: 5px">
                <button
                  [ngClass]="
                    currentVote === votes.Accepted
                      ? 'filled-submit'
                      : 'outlined-submit'
                  "
                  (click)="handleVote(votes.Accepted)"
                >
                  مقبول
                </button>
                <button
                  [ngClass]="
                    currentVote === votes.Rejected
                      ? 'filled-reject'
                      : 'outlined-reject'
                  "
                  (click)="handleVote(votes.Rejected)"
                >
                  مرفوض
                </button>
              </div>
            </div>
            <div class="input-container">
              <textarea
                id="userNotes"
                [(ngModel)]="userNotes"
                class="userNotes"
                [class.invalid]="currentVote === votes.Rejected && !userNotes"
              ></textarea>
              <span
                class="error-msg"
                *ngIf="currentVote === votes.Rejected && !userNotes"
              >
                *هذا الحقل مطلوب
              </span>
            </div>
          </div>
          <ng-container *ngIf="!isCommitteSecretary && !editMode">
            <div class="flex center">
              <div class="about">
                <div class="edit">
                  <app-page-title
                    class="edit"
                    [title]="currentMemberVoteData?.member?.userName"
                    [customClass]="['small-font', 'green-color']"
                  ></app-page-title>
                </div>
                <p class="text role-name">
                  {{ currentMemberVoteData?.member?.role }}
                </p>
                <p class="vote-notes">
                  {{ currentMemberVoteData?.note }}
                </p>
              </div>
              <div
                [class]="
                  getDecisionClass(
                    currentMemberVoteData?.commissionerVoteStatusId
                  )
                "
              >
                {{
                  getVoteString(currentMemberVoteData?.commissionerVoteStatusId)
                }}
              </div>
            </div>
            <div class="divider"></div>
          </ng-container>

          <ng-container
            *ngFor="
              let vote of otherMembersVotes;
              let last = last;
              let first = first
            "
          >
            <div
              class="flex center"
              [ngStyle]="{ 'margin-top': first ? '50px' : 0 }"
            >
              <div class="about">
                <app-page-title
                  [title]="vote?.member?.userName"
                  [customClass]="['small-font', 'green-color']"
                ></app-page-title>
                <p class="text role-name">
                  {{ vote?.member?.role }}
                </p>
                <p class="vote-notes">
                  {{ vote.note }}
                </p>
              </div>
              <div
                class="submit"
                [class]="getDecisionClass(vote?.commissionerVoteStatusId)"
              >
                {{ getVoteString(vote?.commissionerVoteStatusId) }}
              </div>
            </div>
            <div *ngIf="!last" class="divider"></div>
          </ng-container>
        </div>

        <div class="edit" style="margin-top: 50px">
          <app-custom-button
            [buttonText]="'previousPage' | translate"
            buttonStyle="transperantGreenButton"
            (click)="previousPage()"
          />
          <app-custom-button
            *ngIf="isCommitteeHead || isCommitteeMember"
            buttonStyle="greenButton"
            [buttonText]="editMode ? 'إعتماد التصويت' : 'تعديل التصويت'"
            (click)="editVoteClickHandler()"
            [disabled]="
              editMode
                ? currentVote === votes.NotVoted ||
                  (currentVote === votes.Rejected && !userNotes)
                : isSubmitButtonDisabled
            "
          />
          <app-custom-button
            *ngIf="isCommitteeHead"
            buttonStyle="greenButton"
            buttonText="إرسال الطلب إلى أمين اللجنة"
            [disabled]="totalVotes < 5 || editMode"
            (click)="
              showSendForCommitteeSecretary = true;
              bodyScrollService.toggleBodyScroll(false)
            "
          />
          <app-modal
            *ngIf="isCommitteeHead"
            [modalContent]="'submission'"
            [showModal]="showSendForCommitteeSecretary"
            (closeModal)="closeModel()"
            [customClass]="'success-modal'"
            [isCloseVisible]="!(isLoading | async)"
          >
            <div
              class="modal-body modal-only-text"
              *ngIf="!sentSuccessfully; else successPopup"
            >
              <span class="modal-text"
                >هل ترغب في تأكيد إرسال الطلب إلى أمين اللجنة؟</span
              >
              <div class="modal-buttons-container">
                <app-custom-button
                  buttonText="نعم, تأكيد"
                  buttonStyle="greenButton"
                  (click)="sendAppToCommitteeSecretary()"
                />
                <app-custom-button
                  (click)="closeModel()"
                  buttonText="إلغاء"
                  buttonStyle="transperantGreenButton"
                />
              </div>
            </div>
            <ng-template #successPopup>
              <loading-spinner>جاري ارسال الطلب...</loading-spinner>
              <div *ngIf="!(isLoading | async)" class="modal-body">
                <app-svg-icon
                  class="success-icon"
                  name="success-icon"
                ></app-svg-icon>
                <span class="modal-text success">{{
                  'تم إرسال الطلب إلى أمين اللجنة بنجاح'
                }}</span>
              </div>
            </ng-template>
          </app-modal>
        </div>

        <app-modal
          [modalContent]="'submission'"
          [showModal]="submittedVote"
          (closeModal)="closeModel()"
          [customClass]="'success-modal'"
        >
          <div class="modal-body">
            <app-svg-icon
              class="success-icon"
              name="success-icon"
            ></app-svg-icon>
            <span class="modal-text success">تم اعتماد التصويت </span>
          </div>
        </app-modal>
      </p-tabPanel>
    </p-tabView>
  </div>

  <ng-template #resultsBlock>
    <div class="results-container">
      <div class="flex">
        <app-page-title
          [title]="'accreditedVotes'"
          [customClass]="['application-details']"
        ></app-page-title>
        <div class="flex center" style="margin-bottom: 30px">
          <span class="vote-count">
            {{ 'voteCount' | translate }}
          </span>
          <div class="votecount">
            <span class="grey"> {{ totalVotes }} </span>
            /5
          </div>
        </div>
      </div>

      <div *ngFor="let vote of otherMembersVotes; let last = last">
        <div class="flex center">
          <div class="about">
            <app-page-title
              [title]="vote?.member?.userName"
              [customClass]="['small-font', 'green-color']"
            ></app-page-title>
            <p class="text role-name">
              {{ vote?.member?.role }}
            </p>
            <p class="vote-notes">
              {{ vote.note }}
            </p>
          </div>
          <div
            class="submit"
            [class]="getDecisionClass(vote?.commissionerVoteStatusId)"
          >
            {{ getVoteString(vote?.commissionerVoteStatusId) }}
          </div>
        </div>
        <div *ngIf="!last" class="divider"></div>
      </div>
      <div
        class="discussion-boards-accordion"
        *ngIf="previousDiscussionBoardsData?.length"
      >
        <app-discussion-board-accordion
          *ngIf="isDiscussionBoardAccordionVisible"
          [previousDiscussionBoards]="previousDiscussionBoardsData"
        />
      </div>
      <div>
        <div class="edit" style="margin-top: 50px">
          <app-custom-button
            *ngIf="votingResultsButtonText()"
            (click)="votingResultsButtonHandler()"
            [buttonText]="votingResultsButtonText()"
            buttonStyle="greenButton"
          />
        </div>
      </div>
    </div>

    <app-modal
      [modalContent]="'submission'"
      [showModal]="showSendForPaymentDialog"
      (closeModal)="closeModel()"
      [customClass]="'success-modal'"
      [isCloseVisible]="!(isLoading | async)"
    >
      <div
        class="modal-body modal-only-text"
        *ngIf="!sentSuccessfully; else successPopup"
      >
        <span class="modal-text">{{
          rejectionReasonControl
            ? 'هل ترغب في تأكيد إرسال الطلب إلى الجهة التدريبية؟'
            : 'هل ترغب في تأكيد إرسال الطلب إلى الجهة التدريبية للدفع؟'
        }}</span>
        <div class="modal-buttons-container">
          <app-custom-button
            [disabled]="isSubmitButtonDisabled"
            buttonText="نعم, تأكيد"
            buttonStyle="greenButton"
            (click)="
              rejectionReasonControl
                ? rejectAppToTrainingOrg()
                : sendApplicationForPayment()
            "
          />
          <app-custom-button
            (click)="closeModel()"
            buttonText="إلغاء"
            buttonStyle="transperantGreenButton"
          />
        </div>
      </div>
      <ng-template #successPopup>
        <loading-spinner>جاري ارسال الطلب...</loading-spinner>
        <div *ngIf="!(isLoading | async)" class="modal-body">
          <app-svg-icon class="success-icon" name="success-icon"></app-svg-icon>
          <span class="modal-text success">{{
            'تم إرسال الطلب إلى الجهة التدريبية بنجاح'
          }}</span>
        </div>
      </ng-template>
    </app-modal>

    <app-dialog
      [isCustomDialog]="true"
      width="600px"
      (onHide)="closeRejectionDialog()"
      [visible]="isRejectionDialogOpen"
    >
      <ng-container>
        <div class="info-header">
          <app-page-title
            title="مسببات الرفض"
            customClass="green-color application-details"
          />
          <span>يُرجى تعبئة مسببات الرفض و إرسالها إلى الجهة التدريبية. </span>
        </div>
        <app-rich-text-editor
          [control]="rejectionReasonControl"
          (textValueEmitter)="rejectionReasonText = $event"
        />
        <div class="buttons-container">
          <app-custom-button
            [buttonText]="'إرسال الطلب للجهة التدريبية'"
            buttonStyle="greenButton"
            [disabled]="rejectionReasonControl?.invalid"
            (click)="
              isRejectionDialogOpen = false; showSendForPaymentDialog = true
            "
          />
          <app-custom-button
            [buttonText]="'cancel' | translate"
            buttonStyle="transperantGreenButton"
            (click)="isRejectionDialogOpen = false"
          />
        </div>
      </ng-container>
    </app-dialog>
  </ng-template>
</div>
