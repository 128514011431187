<app-header includeSubHeader="true"></app-header>
<div class="container">
  <div class="title__container">عن الاعتماد</div>
  <div class="services_container">
    <div class="sub_title">عن الاعتماد</div>
    <p>
      أطلق المركز مبادرة اعتماد البرامج التدريبية في مجال كفاءة الطاقة والتي تم
      تطويرها بهدف تحسين جودة هذا النوع من البرامج التدريبية، وذلك لبناء منظومة
      تدريبية متميزة في مجالات كفاءة الطاقة حيث تسهم هذه المبادرة في تحقق
      المستفيدين من مناسبة البرامج التدريبية المنفذة وضمان جودتها، فضلاً عن
      وصولهم إلى شبكة واسعة من المراكز التدريبية في مجال كفاءة الطاقة بالمملكة.
    </p>
    <p>
      ويجدر بالذكر أن المركز السعودي لكفاءة الطاقة بحكم اختصاصاته التنظيمية
      يتولى اعتماد البرامج التدريبية المهنية والجهات المانحة للشهادات المختصة
      بالتدريب في المجالات المتعلقة بكفاءة الطاقة، وذلك بهدف تنمية القدرات
      البشرية في المملكة للقيام بدورها المأمول في هذا المجال الحيوي.
    </p>
    <p>
      يقدم المركز السعودي لكفاءة الطاقة خدمتين تخص اعتماد البرامج التدريبية في
      مجال كفاءة الطاقة وهما:​
    </p>

    <ul class="text-list">
      <li>
        <span class="sub-text">
          البرامج التدريبية في مجال كفاءة الطاقة والتي يقوم بتقديمها
          المركز:​</span
        >

        يمتلك المركز السعودي لكفاءة الطاقة عدد من البرامج التدريبية المتنوعة
        والمميزة في مجال كفاءة الطاقة، قام بإعدادها مجموعة من الخبراء المحليين
        والعالميين المختصين في مجال كفاءة الطاقة لتقديمها للمهتمين بها للاعتماد.
      </li>
      <li>
        <span class="sub-text">
          ​ البرامج التدريبية المتعلقة بكفاءة الطاقة والتي ترغب المراكز/ الجهات
          التدريبية باعتمادها:​
        </span>

        هي البرامج التدريبية التي قامت المراكز/الجهات التدريبية بتأليفها عن طريق
        عمل دراسات وأبحاث لتحديد الاحتياج التدريبي في قطاع كفاءة الطاقة بناءً
        على تحليل مفصل للمهارات الوظيفية المطلوبة أو مجموعة الوظائف المستهدفة،
        والتي قد ترغب هذه المراكز/الجهات التدريبية في الحصول على اعتمادها من
        المركز السعودي لكفاءة الطاقة.
      </li>
    </ul>
  </div>
</div>
