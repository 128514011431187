import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrl: './rich-text-editor.component.scss',
})
export class RichTextEditorComponent {
  @Input() control: FormControl;

  @Output() textValueEmitter: EventEmitter<string> = new EventEmitter<string>();

  onTextChange($event) {
    this.textValueEmitter.emit($event.textValue);
  }

  onEditorInit(event: any) {
    const quill = event.editor;
    this.customizeLinkTooltip(quill);
  }

  customizeLinkTooltip(quill: any) {
    const tooltip = quill.theme.tooltip;
    const input = tooltip.root.querySelector(
      'input[data-link]',
    ) as HTMLInputElement;
    const saveLink = tooltip.root.querySelector(
      '.ql-action',
    ) as HTMLAnchorElement;
    saveLink.classList.add('disabled');

    if (input && saveLink) {
      input.addEventListener('input', () => {
        const isValid = this.isValidUrl(input.value);
        saveLink.classList.toggle('disabled', !isValid);
      });
    }
  }

  isValidUrl(url: string): boolean {
    const urlPattern =
      /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[a-z0-9-]+(?:\.[a-z0-9-]+)+[^\s]*$/i;
    return urlPattern.test(url);
  }
}
