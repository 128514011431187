<app-comment-section [data]="threadData" #parentComment />
<div class="replies-container" *ngIf="readonly ? openedReplyTextField : true">
  <div
    *ngFor="let reply of threadData.discussionBoardReplies"
    class="reply-container"
  >
    <app-comment-section [data]="reply" />
  </div>
</div>
<app-custom-button
  *ngIf="readonly && threadData.discussionBoardReplies?.length"
  class="show-replies-button"
  [buttonStyle]="
    openedReplyTextField ? 'greenButton' : 'transperantGreenButton'
  "
  [buttonText]="openedReplyTextField ? 'إخفاء الردود' : 'إظهار الردود'"
  (click)="handleShowReplies()"
/>
<div class="thread-reply-container" *ngIf="!readonly">
  <text-area
    [control]="replyControl"
    [id]="'reply_' + i"
    *ngIf="openedReplyTextField && !(isSaving | async)"
    [errorMessage]="''"
  />
  <div class="buttons-container" *ngIf="!(isSaving | async)">
    <app-custom-button
      buttonText="الرد"
      buttonStyle="transperantGreenButton"
      (click)="openedReplyTextField = true"
      *ngIf="!openedReplyTextField"
    />
    <app-custom-button
      buttonText="حفظ"
      buttonStyle="greenButton"
      (click)="submitReply()"
      [disabled]="replyControl.invalid"
      *ngIf="openedReplyTextField"
    />
    <app-custom-button
      buttonText="الغاء"
      buttonStyle="transperantGreenButton"
      (click)="openedReplyTextField = false; replyControl.reset()"
      *ngIf="openedReplyTextField"
    />
  </div>
</div>
<loading-spinner [isLoading$]="isSaving" *ngIf="!readonly" />
