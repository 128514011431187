<div
  [ngSwitch]="valueToCheck"
  class="input-container"
  [class.readOnly]="isReadOnly"
>
  <div *ngSwitchCase="'case1'">Content for Case 1</div>
  <input
    *ngSwitchCase="'textInput'"
    type="text"
    [placeholder]="placeholderText"
    [id]="id"
    [(ngModel)]="value"
    (ngModelChange)="onValueChange($event)"
    [formControlName]="formControlName"
    [readOnly]="isReadOnly"
    [ngClass]="customClass"
  />
  <input
    *ngSwitchCase="'phone'"
    type="tel"
    [placeholder]="placeholderText"
    [id]="id"
    [(ngModel)]="value"
    (ngModelChange)="onValueChange($event)"
  />
  <input
    *ngSwitchCase="'email'"
    type="email"
    [placeholder]="placeholderText"
    [id]="id"
    [(ngModel)]="value"
    (ngModelChange)="onValueChange($event)"
  />
  <input
    *ngSwitchCase="'date'"
    type="date"
    [placeholder]="placeholderText"
    [id]="id"
    [(ngModel)]="value"
    (ngModelChange)="onValueChange($event)"
  />
  <input
    (change)="onCheckboxChange($event)"
    *ngSwitchCase="'checkbox'"
    type="checkbox"
    [placeholder]="placeholderText"
    [id]="id"
    [(ngModel)]="value"
    (ngModelChange)="onValueChange($event)"
  />
  <textarea
    *ngSwitchCase="'textarea'"
    [id]="id"
    [rows]="rows"
    [placeholder]="placeholderText"
    [(ngModel)]="value"
    [ngClass]="customClass"
    (ngModelChange)="onValueChange($event)"
    [disabled]="isDisabled"
  ></textarea>
</div>
