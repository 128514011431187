<div class="text-area-container">
  <label [for]="id">{{ label }} <small *ngIf="isRequired">*</small></label>
  <textarea
    [id]="id"
    [rows]="rows"
    [cols]="columns"
    pInputTextarea
    [placeholder]="placeholder"
    [formControl]="control"
  ></textarea>
  <small
    *ngIf="control?.touched && !control?.valid && errorMessage"
    [id]="id + '-text-area-error-message'"
    >{{ errorMessage }}</small
  >
</div>
