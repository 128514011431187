import { Component, OnInit } from '@angular/core';
import { Headers } from '../my-applications/my-applications.component.js';
import { AuditService } from '@app/dashboard/services/audit.service';
import { LookupsTypeEnum } from '@app/dashboard/types/lookUps';
import { LoadingService } from '@app/services/loading.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-auditor-table',
  templateUrl: './auditor-table.component.html',
  styleUrls: ['./auditor-table.component.scss'],
})
export class AuditorTableComponent implements OnInit {
  headers: Headers[] = [
    { name: 'رقم الطلب', value: 'reportId' },
    { name: 'اسم الجهة', value: 'organizationName' },
    { name: 'تاريخ الطلب', value: 'creationDate' },
    { name: 'حالة الطلب', value: 'trainingOrganizationStatusStr' },
  ];

  applicationType: string[] = [];
  status: string[] = [];
  applicationTypeOptions: string[] = [];
  tableData: any;

  constructor(
    private auditService: AuditService,
    private loadingService: LoadingService,
  ) {}

  isLoading$: Observable<boolean> = this.loadingService.isLoading$();

  ngOnInit(): void {
    this.getTableData();
    this.getFilterData(
      LookupsTypeEnum.TrainingOrganizationStatusEnum,
      this.status,
    );
  }
  getFilterData(lookup: number, filterValue: string[]) {
    this.auditService.getFiltersData(lookup).subscribe({
      next: (data) => {
        filterValue.length = 0; // Clear the existing array
        Array.prototype.push.apply(filterValue, data.data); // Add new elements to the array
      },
      error: (error) => {
        console.error('Error fetching data:', error);
      },
    });
  }

  getTableData() {
    this.loadingService.startLoading();
    const body = {
      pageCount: 1,
      reportId: '',
      pageSize: 10,
      allowSearch: false,
      organizationName: '',
      fromDate: '',
      toDate: '',
      languageTypeId: '',
      requestTypeId: 1, //not for org
      trainingOrganizationStatusId: 1,
    };

    this.auditService.getOrgFilteredData(body).subscribe({
      next: (data) => {
        this.tableData = data.data;
        this.loadingService.stopLoading();
      },
      error: (error) => {
        console.log('error', error);
      },
    });
  }
}
