<div class="user-guide__container">
  <div class="user-guide__inner-container">
    <div class="user-guide">
      <div class="user-guide__title-container">
        <div class="user-guide__title">
          <app-svg-icon
            (click)="closeModal($event)"
            name="close-icon"
            class="close-icon"
          ></app-svg-icon>
          {{ title }}
        </div>
        <app-custom-button
          [buttonStyle]="'greenButton'"
          [buttonText]="'downloadAll'"
          (click)="downloadPDF()"
          ><app-svg-icon
            class="download-icon"
            name="download-icon"
          ></app-svg-icon
        ></app-custom-button>
      </div>
      <app-svg-icon
        name="long-two-sided-arrow"
        class="two-sided-arrow__desktop"
        [desiredWidth]="'100%'"
        [desiredHeight]="'100%'"
      ></app-svg-icon>
      <div class="user-guide__page">
        <div class="page-container">
          <pdf-viewer
            #pdfViewerContainer
            [src]="getFullPDFURL()"
            [render-text]="true"
            [original-size]="false"
            style="width: 100%; height: 100%"
          ></pdf-viewer>
        </div>
      </div>
    </div>
  </div>
</div>
