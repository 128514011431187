<div class="flex padding">
  <app-page-title
    [title]="'لوحة النقاش'"
    [customClass]="['application-details']"
  ></app-page-title>
  <app-custom-button
    buttonText="ملاحظات الأمين"
    buttonStyle="transperantGreenButton"
    (click)="isSavingNotes.next(false); isSecretaryNotesDialogOpen = true"
  />
</div>
<app-dialog
  [isCustomDialog]="true"
  width="600px"
  (onHide)="hideSecretaryNotesDialog()"
  [visible]="isSecretaryNotesDialogOpen"
>
  <loading-spinner [isLoading$]="isSavingNotes"
    >جاري حفظ ملاحظات الأمين</loading-spinner
  >
  <ng-container *ngIf="!(isSavingNotes | async)">
    <div class="info-header">
      <app-page-title
        title="ملاحظات الأمين"
        customClass="green-color application-details"
      />
      <span *ngIf="isCommitteSecretary">يُرجى صياغة ملاحظات أعضاء اللجنة</span>
      <span *ngIf="!isCommitteSecretary"
        >صياغة ملاحظات أعضاء اللجنة من قبل أمين اللجنة</span
      >
    </div>
    <app-rich-text-editor
      [control]="committeeSecretaryNoteControl"
      (textValueEmitter)="committeeNoteTextValue = $event"
      *ngIf="isCommitteSecretary"
    />
    <div class="buttons-container" *ngIf="isCommitteSecretary">
      <app-custom-button
        [buttonText]="'save' | translate"
        buttonStyle="greenButton"
        [disabled]="!committeeNoteTextValue"
        (click)="saveCommitteeSecretaryNotes()"
      />
      <app-custom-button
        [buttonText]="'close' | translate"
        buttonStyle="transperantGreenButton"
        (click)="hideSecretaryNotesDialog()"
      />
    </div>
    <div class="ltr">
      <p
        class="committe-note"
        *ngIf="!isCommitteSecretary"
        [innerHTML]="committeeSecretaryNoteControl?.value || noNotesHTML"
      ></p>
    </div>
  </ng-container>
</app-dialog>
<div class="padding">
  <app-discussion-board-accordion
    [previousDiscussionBoards]="
      discussionBoardData?.slice(0, discussionBoardData?.length - 1)
    "
    *ngIf="discussionBoardData?.length > 1"
  />
  <form class="current-board-container" (ngSubmit)="onCommentSubmit()">
    <div
      class="comment-row"
      *ngFor="
        let comment of currentDiscussionBoardData?.discussionBoardThreads;
        let i = index;
        trackBy: commentReplyCount
      "
    >
      <app-thread
        [index]="i"
        [threadData]="comment"
        [trainingOrganizationId]="trainingOrganizationId"
        [onCommentSubmit]="onCommentSubmit.bind(this)"
      />
    </div>

    <div class="comment-field-container" *ngIf="!(isSaving | async)">
      <img [src]="images.altImage" class="comment-img" />
      <div class="text-area-container">
        <text-area
          [control]="commentControl"
          [errorMessage]="''"
          id="comment"
          *ngIf="!(isSaving | async)"
        />
        <app-custom-button
          buttonText="حفظ"
          buttonStyle="greenButton"
          type="submit"
          [disabled]="!commentControl?.value"
          *ngIf="!(isSaving | async)"
        />
      </div>
    </div>
    <loading-spinner [isLoading$]="isSaving" />
  </form>
</div>
