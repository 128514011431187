<div class="date-picker-container">
  <label [for]="id" *ngIf="label">{{ label }} <small *ngIf="isRequired">*</small></label>
  <p-calendar
    [placeholder]="placeholder"
    [id]="id"
    [formControl]="control"
    [showIcon]="showIcon"
    [selectionMode]="selectionMode"
    (onSelect)="onDateSelected($event)"
    dateFormat="dd/mm/yy"
    [minDate]="minDate"
    [maxDate]="maxDate"
  ></p-calendar>
  <small
    *ngIf="control !== undefined && control.touched && !control.valid"
    [id]="id + '-datepicker-error-message'"
    >{{ errorMessage }}</small
  >
  <small *ngIf="customErrorMessage">{{ customErrorMessage }}</small>
</div>
