<div class="tab-data" *ngIf="trainingOrganization">
  <div class="training-body__title">معلومات الجهة</div>
  <div class="row-data">
    <div class="training-body__info">
      <div class="field-container">
        <app-svg-icon class="field-icon" name="users-icon"></app-svg-icon>
        <div class="field-name">اسم الجهة التدريبية</div>
      </div>
      <div class="field-value">
        {{ trainingOrganization.organizationName }}
      </div>
    </div>
    <div class="training-body__info">
      <div class="field-container">
        <app-svg-icon class="field-icon" name="document-icon"></app-svg-icon>
        <div class="field-name">نوع الطلب</div>
      </div>
      <div class="field-value">
        {{ trainingOrganization.requestTypeName }}
      </div>
    </div>
  </div>
  <div class="row-data">
    <div class="training-body__info">
      <div class="field-container">
        <app-svg-icon class="field-icon" name="date-icon"></app-svg-icon>
        <div class="field-name">
          تاريخ رخصة المؤسسة العامة للتدريب التقني والمهني
        </div>
      </div>
      <div class="field-value">
        {{ trainingOrganization.licenseDate }}
      </div>
    </div>
    <div class="training-body__info">
      <div class="field-container">
        <app-svg-icon
          class="field-icon"
          name="location-pin-icon"
        ></app-svg-icon>
        <div class="field-name">المدينة</div>
      </div>
      <div class="field-value">
        {{ trainingOrganization.cityName }}
      </div>
    </div>
  </div>
  <div class="row-data">
    <div class="training-body__info">
      <div class="field-container">
        <app-svg-icon class="field-icon" name="phone-icon"></app-svg-icon>
        <div class="field-name">رقم الجوال</div>
      </div>
      <div class="field-value">
        {{ trainingOrganization.phoneNumber }}
      </div>
    </div>
    <div class="training-body__info">
      <div class="field-container">
        <app-svg-icon class="field-icon" name="date-icon"></app-svg-icon>
        <div class="field-name">
          تاريخ انتهاء رخصة المؤسسة العامة للتدريب التقني والمهني
        </div>
      </div>
      <div class="field-value">
        {{ trainingOrganization.licenseExpirationDate }}
      </div>
    </div>
  </div>
  <div class="row-data">
    <div class="training-body__info">
      <div class="field-container">
        <app-svg-icon
          class="field-icon"
          name="location-pin-icon"
        ></app-svg-icon>
        <div class="field-name">العنوان</div>
      </div>
      <div class="field-value">
        {{ trainingOrganization.address }}
      </div>
    </div>
    <div class="training-body__info">
      <div class="field-container">
        <app-svg-icon class="field-icon" name="email-icon"></app-svg-icon>
        <div class="field-name">البريد الإلكتروني</div>
      </div>
      <div class="field-value">
        {{ trainingOrganization.email }}
      </div>
    </div>
    <div class="training-body__info">
      <div class="field-container">
        <app-svg-icon class="field-icon" name="license-icon"></app-svg-icon>
        <div class="field-name">
          رقم رخصة المؤسسة العامة للتدريب التقني والمهني
        </div>
      </div>
      <div class="field-value">
        {{ trainingOrganization.trainingLicenseNumber }}
      </div>
    </div>
    <div class="training-body__info">
      <div class="field-container">
        <app-svg-icon class="field-icon" name="user-icon"></app-svg-icon>
        <div class="field-name">اسم المنسق</div>
      </div>
      <div class="field-value">
        {{ trainingOrganization.coordinatorName }}
      </div>
    </div>
  </div>
</div>
