import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoadingService } from 'src/app/services/loading.service';
import { AuditService } from '@app/dashboard/services/audit.service';

@Component({
  selector: 'app-financial-auditor-table',
  templateUrl: './financial-auditor-table.component.html',
  styleUrl: './financial-auditor-table.component.scss',
})
export class FinancialAuditorTableComponent implements OnInit {
  headers: any[] = [
    { name: 'رقم الطلب', value: 'reportId' },
    { name: 'اسم الجهة', value: 'organizationName' },
    { name: 'تاريخ الطلب', value: 'creationDate' },
    { name: 'حالة الإيصال', value: 'trainingOrganizationStatusStr' },
  ];

  status: any[] = [{ key: 10, value: 'تم الدفع' }];
  tableData: any;
  showModal: boolean = false;
  modifiedTableData: any[];

  constructor(
    private router: Router,
    private auditService: AuditService,
    private loadingService: LoadingService,
  ) {}

  isLoading: Observable<boolean> = this.loadingService.isLoading$();

  ngOnInit(): void {
    this.loadingService.startLoading();
    this.getTableData();
  }

  getFilterData(lookup: number, filterValue: string[]) {
    this.auditService.getFiltersData(lookup).subscribe({
      next: (data) => {
        filterValue.length = 0;
        Array.prototype.push.apply(filterValue, data.data);
      },
      error: (error) => {
        console.error('Error fetching data:', error);
      },
    });
  }

  getTableData() {
    const body = {
      pageCount: 1,
      pageSize: 10,
      reportId: '',
      allowSearch: false,
      organizationName: '',
      // fromDate: '',
      // toDate: '',
      // languageTypeId: '',
      trainingOrganizationStatusId: 8,
    };

    this.auditService.getInvoicesList(body).subscribe({
      next: (data) => {
        this.loadingService.stopLoading();
        this.tableData = data.data;
        this.modifiedTableData = data?.data?.data?.map((item: any) => {
          return {
            ...item,
            trainingOrganizationStatusStr: item.trainingOrganizationStatusStr,
            id: item.trainingOrganizationId,
            appId: item.id,
          };
        });
      },
      error: (error) => {
        console.log('error', error);
      },
    });
  }

  sendToGeneralManagerHandler() {
    this.showModal = true;
  }
}
