import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SignupFormComponent } from './signup-form/signup-form.component';
import { SigninFormComponent } from './signin-form/signin-form.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { VerifyOTPComponent } from './verify-otp/verify-otp.component';
import { CreateNewPasswordComponent } from './create-new-password/create-new-password.component';
import { FaqComponent } from './faq/faq.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { UsagePolicyComponent } from './usage-policy/usage-policy.component';
import { OurServicesComponent } from '../our-services/our-services.component';
import { ErrorPageComponent } from './error-page/error-page.component';

const SharedRoutes: Routes = [
  {
    path: 'signup',
    component: SignupFormComponent,
  },
  {
    path: 'login',
    component: SigninFormComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'verify-otp',
    component: VerifyOTPComponent,
  },
  {
    path: 'change-password',
    component: CreateNewPasswordComponent,
  },
  {
    path: 'faq',
    component: FaqComponent,
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'usage-policy',
    component: UsagePolicyComponent,
  },
  {
    path: 'our-services',
    component: OurServicesComponent,
  },
  {
    path: 'error-page',
    component: ErrorPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(SharedRoutes)],
  exports: [RouterModule],
})
export class SharedRoutingModule {}
