<p-dialog
  [(visible)]="visible"
  [modal]="true"
  [style]="{ width: width, height: height }"
  [draggable]="isDraggable"
  [resizable]="false"
  (onHide)="closeModal()"
  [closable]="!(isLoading | async) && isClosable"
>
  <loading-spinner *ngIf="(isLoading | async) && !isCustomDialog">
    جاري ارسال الطلب...
  </loading-spinner>
  <form
    *ngIf="!(isLoading | async) && !isCustomDialog"
    [formGroup]="expertForm"
  >
    <app-page-title
      [title]="
        userIsCommitteeSecretary
          ? 'اختيار الخبير الفني'
          : 'technicalExpertSuggestion'
      "
      [customClass]="'green-color'"
    >
    </app-page-title>
    <span class="subheader">
      {{
        userIsCommitteeSecretary
          ? 'تم تحديد مقترح خبير فني من قبل المدقق الداخلي، يمكنك إختيار خبير فني آخر.'
          : 'يرجى اقتراح اسم الخبير الفني الذي ترغب في تعيينه لهذا الطلب مع عددالساعات.'
      }}
    </span>

    <div class="input-container">
      <label class="form-label" for="name">{{
        'chooseTechnicalExpert' | translate
      }}</label>
      <app-custom-dropdown
        [selectorName]="expertSelectedOptionValue"
        [dropdownOptions]="expertOptions"
        (selectedOptionKey)="getExpert($event)"
      ></app-custom-dropdown>

      <div class="hours-container">
        <label class="form-label" for="name">{{
          'workingHours' | translate
        }}</label>
        <input
          class="form-input"
          required
          type="number"
          formControlName="hours"
        />
      </div>
    </div>
    <div class="btns-container">
      <app-custom-button
        [buttonText]="'إرسال'"
        [buttonStyle]="'greenButton'"
        (click)="sendTechnicalAdvisor(userIsCommitteeSecretary)"
      ></app-custom-button>
      <app-custom-button
        [buttonText]="'إلغاء'"
        [buttonStyle]="'transperantGreenButton'"
        (click)="cancel()"
      ></app-custom-button>
    </div>
  </form>
  <ng-content *ngIf="isCustomDialog"></ng-content>
</p-dialog>
