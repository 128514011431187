export const paymentData = {
  programName: 'طلب إعتماد برنامج شركة الطاقة الشمسية',
  account: 6543234,
  billing: [
    {
      title: 'طلب إعتماد برنامج ',
      amount: 11000,
    },
  ],
  totalAmount: 11000,
};
