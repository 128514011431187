import {
  Component,
  Input,
  ElementRef,
  HostListener,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { ApplicationDataService } from 'src/app/dashboard/services/application-data.service';

@Component({
  selector: 'app-custom-dropdown',
  templateUrl: './custom-dropdown.component.html',
  styleUrls: ['./custom-dropdown.component.scss'],
})
export class CustomDropdownComponent {
  @ViewChild('myValue') myValue: ElementRef;

  @Input() dropdownOptions: string[];
  @Input() selectorName: string = '';
  @Input() customClass: string = '';
  @Input() value: string;
  @Output() selectedOptionChange = new EventEmitter<string>();
  @Output() selectedOptionKey = new EventEmitter<number>();
  @Output() onSelectOption = new EventEmitter<{ key: number; value: string }>();

  isDropdownOpen = false;
  selectedOption: string | null = null;

  constructor(
    private el: ElementRef,
    private applicationService: ApplicationDataService,
  ) {}

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (!this.el.nativeElement.contains(event.target)) {
      this.isDropdownOpen = false;
    }
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  selectOption(option: string) {
    this.selectedOption = option['value'];
    this.isDropdownOpen = false;
    this.selectedOptionChange.emit(option['value']);
    this.selectedOptionKey.emit(option['key']);
    this.onSelectOption.emit({ key: option['key'], value: option['value'] });
  }
}
