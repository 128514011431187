import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'date-picker',
  templateUrl: './date-picker.component.html',
  styleUrl: './date-picker.component.scss',
})
export class DatePicker {
  date: Date | undefined;
  dateRange = [];
  @Input() id: string;
  @Input() isRequired: boolean;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() control: FormControl;
  @Input() errorMessage: string = 'هذا الحقل مطلوب';
  @Input() customErrorMessage: string;
  @Input() showIcon: boolean;
  @Input() selectionMode: string = 'single';
  @Input() minDate: Date;
  @Input() maxDate: Date;

  @Output() dateSelected = new EventEmitter<{
    startDate: Date;
    endDate: Date;
  }>();

  selectedDates: Date[] = [];

  onDateSelected(event: any) {
    this.selectedDates.push(event);
    if (this.selectedDates.length === 1) {
      this.dateSelected.emit({
        startDate: this.selectedDates[0],
        endDate: this.selectedDates[0],
      });
    } else if (this.selectedDates.length === 2) {
      this.dateSelected.emit({
        startDate: this.selectedDates[0],
        endDate: this.selectedDates[1],
      });
      this.selectedDates = [];
    }
  }
}
