import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-countdown-timer',
  templateUrl: './countdown-timer.component.html',
  styleUrls: ['./countdown-timer.component.scss'],
})
export class CountdownTimerComponent implements OnInit {
  @Input() deadline: Date;
  timeRemaining: any;
  timerClass: string;

  ngOnInit(): void {
    this.calculateTimeRemaining();
    setInterval(() => {
      this.calculateTimeRemaining();
    }, 1000);
  }

  calculateTimeRemaining(): void {
    const now = new Date();
    const difference = this.deadline.getTime() - now.getTime();

    if (difference > 0) {
      const hours = Math.floor(difference / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      this.timeRemaining = {
        hours: this.padZero(hours),
        minutes: this.padZero(minutes),
        seconds: this.padZero(seconds),
      };
      //condition: if less than a day is left => to be adjusted by requirements
      this.timerClass = hours < 24 ? 'red' : 'gray';
    } else {
      this.timeRemaining = {
        hours: '00',
        minutes: '00',
        seconds: '00',
      };
      this.timerClass = 'gray';
    }
  }

  padZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }
}
