import { Component, Input } from '@angular/core';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
})
export class LoadingSpinner {
  @Input() isLoading$ = this.loadingService.isLoading$();
  @Input() isSmall: boolean = false;

  constructor(private loadingService: LoadingService) {}
}
