<div class="tabs-container" #tabsContainer>
  <div class="tab-wrap">
    <p-tabView [(activeIndex)]="activeTabIndex">
      <p-tabPanel
        *ngFor="let item of tabs; let i = index"
        [header]="item.label"
        (click)="setActiveTab(i)"
      >
        <ng-content *ngIf="activeTabIndex === i"></ng-content>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>
