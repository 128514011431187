import {
  Component,
  Input,
  EventEmitter,
  Output,
  OnChanges,
} from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnChanges {
  @Input() currentPage: number;
  @Input() totalPages: number;
  pages: number[];
  constructor() {}
  ngOnChanges(): void {
    this.pages = Array.from(
      { length: this.totalPages },
      (_, index) => index + 1,
    );
  }

  @Output() pageChange = new EventEmitter<number>();

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.pageChange.emit(this.currentPage + 1);
    }
  }

  prevPage() {
    if (this.currentPage > 1) {
      this.pageChange.emit(this.currentPage - 1);
    }
  }

  calculatePagination(): number {
    if (this.totalPages <= 8) {
      return 1;
    } else if (this.currentPage <= 4) {
      return 2;
    } else if (this.currentPage <= this.totalPages - 4) {
      return 3;
    } else {
      return 4;
    }
  }

  handlePaginationClick(page: number) {
    this.pageChange.emit(page);
  }
}
