export const informationPanel = {
  progressStatus: [
    {
      status: 'في انتظار موافقة المدقق الداخلي',
      program: 'برنامج الطاقة الشمسية',

      //out of 100
    },
    {
      status: 'في انتظار تصويت اللجنة',
      program: 'برنامج الطاقة المتجددة السعودي',
    },
    {
      status: 'في انتظار موافقة اللجنة',
      program: 'برنامج اقتصاد الطاقة',
    },
    {
      status: 'في انتظار تصويت اللجنة',
      program: 'برنامج الطاقة المتجددة السعودي',
    },
    {
      status: 'في انتظار موافقة اللجنة',
      program: 'برنامج اقتصاد الطاقة',
    },
    {
      status: 'تم إصدار الشهادة',
      program: 'برنامج أنظمة الطاقة',
    },
  ],
  programsStatus: {
    pending: 25,
    obtained: 10,
  },
};
