import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GetLookupsService {
  private lookupsUrl: string = `${environment.apiUrl}/api/Lookup/GetLookups?lookupType=`;
  private programDocumentslookupUrl: string = `${environment.apiUrl}/api/Lookup/GetProgramDocumentLookup`;

  constructor(private http: HttpClient) {}

  getOptions(lookUpType: number): Observable<any> {
    const res = this.http.get<any>(this.lookupsUrl + lookUpType);
    return res;
  }

  getFileUploadData(): Observable<any> {
    const res = this.http.get<any>(this.programDocumentslookupUrl);
    return res;
  }
}
