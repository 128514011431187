<app-header></app-header>
<div class="reset-password__container">
  <div class="form-container reset-password-container__form">
    <h1 class="reset-password__title">تحقق من بريدك الإلكتروني</h1>
    <p class="reset-password__statment">
      يرجى إدخال رمز التحقق المؤلف من 6 أرقام المرسل إلى
      {{ email }}
    </p>
    <form [formGroup]="verifyOTPForm" (ngSubmit)="verifyOtp()">
      <div class="input-container">
        <input
          *ngFor="let digit of verifyOTPForm.controls | keyvalue; let i = index"
          class="form-input"
          type="text"
          id="otp-input-{{ i }}"
          [formControlName]="'otp' + i"
          maxlength="1"
          (input)="onInput($event, i)"
          (keydown)="onKeyDown($event, i)"
          (paste)="onPaste($event)"
        />
      </div>
      <div *ngIf="errorMessage" class="error">{{ errorMessage }}</div>

      <app-custom-button
        (click)="resendOtp()"
        [buttonText]="'إعادة إرسال الرمز'"
        [buttonStyle]="'transperantGreenButton'"
        class="continue-btn"
      ></app-custom-button>

      <button
        [disabled]="!verifyOTPForm.valid"
        class="submit-btn"
        type="submit"
      >
        إستمرار
      </button>
    </form>
  </div>
  <div class="image-container">
    <img src="assets/images/login.png" alt="Image" />
  </div>
</div>
