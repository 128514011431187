<header>
  <div class="logo-container">
    <a class="logo" routerLink="/">
      <img class="logo-svg" [src]="logoSvg" alt="" />
    </a>
    <div class="signing-btns" *ngIf="!isLoggedIn">
      <button class="btn btn-signIn" routerLink="/login">
        {{ 'signin' | translate }}
      </button>
      <button class="btn btn-signUp" routerLink="/signup">
        {{ 'signup' | translate }}
      </button>
    </div>
  </div>
  <div class="divider" *ngIf="includeSubHeader"></div>
  <div *ngIf="includeSubHeader">
    <app-sub-header (resetIsUserLogin)="resetIsUserLogin($event)"></app-sub-header>
  </div>
</header>
