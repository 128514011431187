import { Component, OnInit } from '@angular/core';
import { ScrollToTopService } from '@app/dashboard/services/scroll-to-top.service';

@Component({
  selector: 'app-our-services',
  templateUrl: './our-services.component.html',
  styleUrl: './our-services.component.scss',
})
export class OurServicesComponent implements OnInit {
  public logoSvg = 'assets/images/logo.svg';

  constructor(private scrollService: ScrollToTopService) {}

  ngOnInit(): void {
    this.scrollService.scrollToTop();
  }
}
