export enum LookupsTypeEnum {
  CommissionerVoteStatusEnum = 1,
  FileTypeEnum = 2,
  LanguageEnum = 3,
  ProgramLevelEnum = 4,
  RequestTypeEnum = 5,
  RoleEnum = 6,
  TrainingOrganizationInvoiceStatusEnum = 7,
  TrainingOrganizationStatusEnum = 8,
  WorkFlowTrackTypeEnum = 9,
  ProgramDocumentEnum = 10,
  DocumentCriteriaEnum = 11,
  CitiesEnum = 12,
  ProgramTypeEnum = 13,
}
