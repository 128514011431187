<app-custom-button
  *ngIf="
    tabIndex === trainingProgramDocumentsTabIndex && allArchivedFiles.length
  "
  buttonText="الملفات المؤرشفة"
  buttonStyle="greenButton"
  (click)="visible = true"
/>
<p-dialog
  *ngIf="tabIndex === trainingProgramDocumentsTabIndex"
  id="archived-files-dialog"
  [(visible)]="visible"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '70vw' }"
  (onHide)="closeDialog()"
>
  <span class="dialog-title">الملفات المؤرشفة</span>
  <div class="archived-files_container">
    <div class="archived-files-grid">
      <file-icon
        *ngFor="let fileObj of allArchivedFiles"
        [file]="fileObj.file"
        [readOnly]="true"
        [showDate]="true"
        [showSize]="false"
        [creationDate]="fileObj.creationDate"
        (click)="viewFile(fileObj)"
      />
    </div>
  </div>
  <app-custom-button
    buttonText="إغلاق"
    buttonStyle="transperantGreenButton"
    (click)="closeDialog()"
  />
  <p-dialog
    id="pdf-container"
    [(visible)]="pdfDialogOpen"
    [modal]="true"
    [draggable]="false"
    [resizable]="false"
    (onHide)="closePdfDialog()"
  >
    <div class="pdf-container">
      <loading-spinner *ngIf="isLoading | async" />
      <pdf-viewer
        *ngIf="fileUrl && !(isLoading | async)"
        [src]="fileUrl"
        [show-all]="true"
        [zoom-scale]="'page-width'"
        style="height: 80vh; width: 80vw"
      />
    </div>
  </p-dialog>
</p-dialog>
