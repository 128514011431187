import { Component } from '@angular/core';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent {
  searchQuery: string = '';
  onSearch() {
    // Implement your search logic here, e.g., filtering data based on the searchQuery.
    console.log('Search query: ' + this.searchQuery);
  }

  options: string[] = ['Option 1', 'Option 2', 'Option 3'];
}
