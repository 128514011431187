// form-input.component.ts
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
})
export class FormInputComponent {
  @Input() valueToCheck: string = '';
  @Input() customClass: string = '';
  @Input() placeholderText: string = '';
  @Input() formControlName;
  @Input() id: string = '';
  @Input() rows: number;
  @Input() cols: number;
  @Input() isChecked: boolean = false;
  @Output() checkboxChange = new EventEmitter<boolean>();
  @Input() value: string;
  @Input() isReadOnly: boolean;
  @Output() valueChange = new EventEmitter<string>();
  @Input() isDisabled: boolean = false;
  onCheckboxChange(event: any) {
    this.isChecked = event.target.checked;
    this.checkboxChange.emit(this.isChecked);
  }

  onValueChange(newValue: string) {
    this.value = newValue;
    this.valueChange.emit(newValue);
  }
}
