<div dir="ltr" class="container">
  <button (click)="prevPage()" [disabled]="currentPage === 1">&lt;</button>

  <div [ngSwitch]="calculatePagination()">
    <div *ngSwitchCase="1" class="pages">
      <div *ngFor="let page of pages" class="pages">
        <button
          (click)="handlePaginationClick(page)"
          [class.active]="page === currentPage"
        >
          {{ page }}
        </button>
      </div>
    </div>
    <div *ngSwitchCase="2" class="pages">
      <div *ngFor="let page of pages | slice: 0 : 4" class="pages">
        <button
          (click)="handlePaginationClick(page)"
          [class.active]="page === currentPage"
        >
          {{ page }}
        </button>
      </div>
      <span> ... </span>
      <div
        *ngFor="let page of pages | slice: totalPages - 1 : totalPages"
        class="pages"
      >
        <button
          (click)="handlePaginationClick(page)"
          [class.active]="page === currentPage"
        >
          {{ page }}
        </button>
      </div>
    </div>
    <div *ngSwitchCase="3">
      <div *ngFor="let page of pages | slice: 0 : 1" class="pages">
        <button
          (click)="handlePaginationClick(page)"
          [class.active]="page === currentPage"
        >
          {{ page }}
        </button>
      </div>
      <span> ... </span>
      <div
        *ngFor="let page of pages | slice: currentPage - 2 : currentPage + 1"
        class="pages"
      >
        <button
          (click)="handlePaginationClick(page)"
          [class.active]="page === currentPage"
        >
          {{ page }}
        </button>
      </div>
      <span> ... </span>
      <div
        *ngFor="let page of pages | slice: totalPages - 1 : totalPages"
        class="pages"
      >
        <button
          (click)="handlePaginationClick(page)"
          [class.active]="page === currentPage"
        >
          {{ page }}
        </button>
      </div>
    </div>

    <div *ngSwitchDefault>
      <div *ngFor="let page of pages | slice: 0 : 1" class="pages">
        <button
          (click)="handlePaginationClick(page)"
          [class.active]="page === currentPage"
        >
          {{ page }}
        </button>
      </div>
      <span> ... </span>

      <div
        *ngFor="let page of pages | slice: totalPages - 4 : totalPages"
        class="pages"
      >
        <button
          (click)="handlePaginationClick(page)"
          [class.active]="page === currentPage"
        >
          {{ page }}
        </button>
      </div>
    </div>
  </div>

  <button (click)="nextPage()" [disabled]="currentPage === totalPages">
    >
  </button>
</div>
