import { Component, OnInit } from '@angular/core';
import { Status } from '@app/dashboard/types/status';
import { UserRole } from 'src/app/types/roles';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { AuditService } from '@app/dashboard/services/audit.service';

@Component({
  selector: 'voting-page',
  templateUrl: './voting-page.component.html',
  styleUrl: './voting-page.component.scss',
})
export class VotingPageComponent implements OnInit {
  id: number | string = null;

  levels = [
    {
      name: 'dashboard',
      path: '/dashboard',
    },
  ];

  requestData: any = {};
  requestStatus: number;
  userShouldNotSeeVoting: boolean;
  readonly: boolean = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private auditService: AuditService,
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.id = params['id'];

      this.auditService.getApplicationData(this.id).subscribe((response) => {
        if (response.data) {
          this.requestData = response.data;
          this.requestStatus =
            response.data.workFlowTrainingOrganization.trainingOrganizationStatusLookupId;

          switch (this.requestStatus) {
            case Status.DiscussionAndVoting:
              this.userShouldNotSeeVoting =
                !this.isCommitteeMember() &&
                !this.isCommitteeHead() &&
                !this.isCommitteeSecretary();
              this.readonly = false;
              this.levels.push({
                name: 'executiveSummary',
                path: '',
              });
              break;
            case Status.ApprovedByCommitteeHeadWaitingCommitteeSecretary:
            case Status.RejectedByCommitteeHeadToCommitteeSecretary:
            case Status.RenewalRequestHasBeenRejectedByCommitteeHeadToCommitteeSecretary:
              this.userShouldNotSeeVoting = !this.isCommitteeSecretary();
              this.levels.push({
                name: 'تصويت لجنة الاعتماد',
                path: '',
              });
              break;
            case Status.ApprovedByCommitteeSecretaryWaitingGeneralManager:
            case Status.RenewalRequestHasBeenAcceptedByCommitteeSecretaryToGeneralManager:
              this.userShouldNotSeeVoting = !this.isGeneralManager();
              this.levels.push({
                name: 'تصويت لجنة الاعتماد',
                path: '',
              });
              break;
            case Status.ApprovedByTheFinancialAuditorWaitingCommitteeSecretary:
            case Status.Draft:
            case Status.NewOrderWaitingInternalAuditor:
            case Status.RenewalRequestFromTrainingOrganizationToInternalAuditor:
            case Status.AddressedByTrainingOrganization:
            case Status.ApprovedByTheInternalAuditorWaitingCommitteeSecretary:
            case Status.ApprovedByCommitteeSecretaryWaitingTechnicalExpert:
            case Status.ApprovedByTechnicalExpertWaitingCommitteeSecretary:
            case Status.ApprovedByCommitteeSecretaryWaitingPayment:
            case Status.ThePaymentWasMadeWaitingFinancialAuditor:
            case Status.ApprovedByTheGeneralManagerAndTheCertificateHasBeenIssued:
            case Status.CertificateHasBeenRenewedByGeneralManagerToTrainingOrganization:
            default:
              this.userShouldNotSeeVoting = true;
              break;
          }
        } else this.router.navigate(['/error-page']);
        if (
          this.userShouldNotSeeVoting ||
          this.requestData.trainingOrganization.isEditable
        )
          this.router.navigate(['/error-page']);
      });
    });
  }

  updateBreadcrumbs(tabIndex: number) {
    switch (tabIndex) {
      case 0:
        this.levels[1].name = 'الملخص التنفيذي';
        break;
      case 1:
        this.levels[1].name = 'لوحة النقاش';
        break;
      case 2:
        this.levels[1].name = 'تصويت لجنة الاعتماد';
        break;
    }
  }

  isInternalAuditor(): boolean {
    return this.authService.hasRole(UserRole.InternalAuditor);
  }

  isTechnicalAdvisor(): boolean {
    return this.authService.hasRole(UserRole.TechnicalAdvisor);
  }

  isCommitteeSecretary(): boolean {
    return this.authService.hasRole(UserRole.CommitteeSecretary);
  }

  isCommitteeMember(): boolean {
    return this.authService.hasRole(UserRole.CommitteeMember);
  }

  isCommitteeHead(): boolean {
    return this.authService.hasRole(UserRole.CommitteeHead);
  }

  isGeneralManager(): boolean {
    return this.authService.hasRole(UserRole.GeneralManager);
  }

  requestDetailsButtonHandler() {
    this.router.navigate(['/dashboard/application-details', this.id]);
  }

  get programTitleTextHandler() {
    if (!this.requestData?.trainingProgram?.nameAr) return null;
    return `برنامج ${this.requestData?.trainingProgram?.nameAr} (طلب رقم `;
  }
}
