import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
} from '@microsoft/signalr';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private notificationsSource = new BehaviorSubject<NotificationDto[]>([]);
  private notificationOpenSubject = new BehaviorSubject<boolean>(false);
  notifications$ = this.notificationsSource.asObservable(); // Expose as observable for other components
  isNotificationOpen = new BehaviorSubject<boolean>(false);
  notificationOpen$ = this.notificationOpenSubject.asObservable();
  hubConnection?: HubConnection;

  private apiUrl = environment.apiUrl;
  private hubUrl = environment.apiUrl + '/hub/notifications';

  constructor(private http: HttpClient) {}

  createHubConnection(token: string) {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(this.hubUrl, {
        accessTokenFactory: () => token,
        withCredentials: true,
      })
      .withAutomaticReconnect()
      .build();

    this.hubConnection.start().catch((error) => console.error(error));

    // Handle receiving a single notification
    this.hubConnection.on(
      'ReceiveNotification',
      (notification: NotificationDto) => {
        const currentNotifications = this.notificationsSource.value; // Get the current array of notifications
        const updatedNotifications = [notification, ...currentNotifications]; // Add new notification at the start of the array
        this.notificationsSource.next(updatedNotifications); // Update the BehaviorSubject with the new array
      },
    );
  }

  stopHubConnection() {
    if (this.hubConnection?.state === HubConnectionState.Connected) {
      this.hubConnection.stop().catch((error) => console.error(error));
    }
  }

  setNotificationOpen(isOpen: boolean) {
    this.notificationOpenSubject.next(isOpen);
  }

  sendReadNotification(id: string | number) {
    return this.http.post<any>(
      this.apiUrl + `/api/Notifications/Read/${id}`,
      null,
    );
  }

  getMoreNotifications(pageNumber: number) {
    return this.http.get<any>(
      this.apiUrl +
        `/api/Notifications/Latest?PageCount=${pageNumber}&PageSize=10`,
    );
  }

  toggleNotificationDropdown(event: Event): void {
    event.stopImmediatePropagation();
    const currentState = this.isNotificationOpen.value;
    this.isNotificationOpen.next(!currentState); // Toggle the state
  }

  closeNotificationDropdown(): void {
    this.isNotificationOpen.next(false); // Close the dropdown
  }
}

export interface NotificationDto {
  notificationId: number;
  messageAr: string;
  messageEn: string;
  redirectUrl: string;
  deliveredAt: Date;
  isRead: boolean;
  readAt: Date;
  notificationType: NotificationTypeEnum;
  notificationTypeStr: string;
  fromAccountId: number;
  fromAccount: UserResponse;
  recipientAccountId: number;
  recipientAccount: UserResponse;
}

export interface UserResponse {
  id: number;
  userName: string;
  email: string;
  role: string;
  roleId: number;
}

export enum NotificationTypeEnum {
  ApplicationStatus = 1, // application status
  DiscussionBoardNewMessage = 2, // for committee when participate in discussion board
  Reminder = 3,
}
