<div class="search-container">
  <div class="seach-input__container">
    <button class="search">
      <app-svg-icon name="search-icon" class="search-icon"></app-svg-icon>
    </button>
    <input
      class="search-field"
      [(ngModel)]="searchQuery"
      (input)="onSearch()"
      placeholder="{{ 'search' | translate }}"
    />
  </div>
  <app-custom-dropdown
    [selectorName]="'dropDownStatus' | translate"
    [dropdownOptions]="options"
  ></app-custom-dropdown>
  <app-custom-dropdown
    [selectorName]="'level' | translate"
    [dropdownOptions]="options"
  ></app-custom-dropdown>
  <app-custom-dropdown
    [selectorName]="'programLanguage' | translate"
    [dropdownOptions]="options"
  ></app-custom-dropdown>
</div>
