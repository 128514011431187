<header>
  <nav class="navigation-container">
    <div class="navigation-items-container">
      <a class="logo" routerLink="/">
        <img
          class="logo-svg"
          src="https://www.seec.gov.sa/media/ytvb5gnn/new-logo.svg"
          alt=""
        />
      </a>
    </div>
    <div class="input-container">
      <input
        class="search-field"
        [(ngModel)]="searchQuery"
        (input)="onSearch()"
      />
      <app-svg-icon name="search-icon" class="search-icon"></app-svg-icon>
    </div>

    <div class="navigation-items" [class.is-open]="isMenuOpen">
      <button class="btn help-btn" (click)="handleDivClick($event)">
        <app-svg-icon class="help-icon" name="help-icon"></app-svg-icon>
      </button>
      <div class="modal-overlay" *ngIf="modalService.showModal$ | async">
        <app-user-guide
          [pdfURL]="pdfURL"
          [title]="'الدليل الإرشادي'"
        ></app-user-guide>
      </div>
      <div class="pipe"></div>
        <app-notifications-drop-down
          [isNotificationOpen]="isNotificationOpen"
          (clickOutside)="this.notificationService.closeNotificationDropdown()"
          (click)="handleNotificationClick($event)"
        ></app-notifications-drop-down>
      <div class="pipe"></div>
      <div
        class="card flex justify-content-center"
        (click)="handleUserMenuClick($event)"
      >
        <div class="down-arrow-container">
          <svg
            width="14"
            height="9"
            viewBox="0 0 14 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1.5L7 7.5L13 1.5"
              stroke="#9D9B9B"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <p-menu #menu [model]="items" [popup]="true"></p-menu>
        <app-profile [isProfileVisible]="false"></app-profile>
      </div>
    </div>
    <div class="mobile-container">
      <div class="search-icon__mobile">
        <app-svg-icon
          name="search-icon-mobile"
          class="search-icon"
        ></app-svg-icon>
      </div>
      <div class="burger-menu" (click)="toggleMenu()">
        <app-svg-icon class="menu-home" name="menu-home"></app-svg-icon>
      </div>
    </div>
  </nav>
  <div class="menu-container" [class.menu-open]="isMenuOpen">
    <app-menu></app-menu>
  </div>
</header>
