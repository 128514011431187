<button
  class="btn"
  [type]="type"
  [class]="buttonStyle"
  [disabled]="disabled"
  (click)="onClick()"
  [ngClass]="customClass"
>
  <ng-content></ng-content>
  <ng-container *ngIf="hasText">{{ buttonText | translate }}</ng-container>
</button>
