import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { LoadingService } from 'src/app/services/loading.service';
import { AuditService } from '@app/dashboard/services/audit.service';
import { LookupsTypeEnum } from '@app/dashboard/types/lookUps';
import { Status } from '@app/dashboard/types/status';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'committee-table',
  templateUrl: './committee-table.component.html',
  styleUrl: './committee-table.component.scss',
})
export class CommitteeTableComponent {
  headers: any[] = [
    { name: 'رقم الطلب', value: 'reportId' },
    { name: 'تاريخ الطلب', value: 'creationDate' },
    { name: 'حالة الطلب', value: 'trainingOrganizationStatusStr' },
    { name: 'عدد الأصوات', value: 'totalVotes' },
  ];

  applicationType: string[] = [];
  status: any[] = [];
  applicationTypeOptions: string[] = [];
  tableData: any;

  constructor(
    private auditService: AuditService,
    private loadingService: LoadingService,
    private authService: AuthService,
  ) {}

  userRole: number;
  isLoading$: Observable<boolean>;

  ngOnInit(): void {
    this.userRole = this.authService.getUserRole();
    this.getTableData();
    this.getFilterData(
      LookupsTypeEnum.TrainingOrganizationStatusEnum,
      this.status,
    );
  }

  getFilterData(lookup: number, filterValue: string[]) {
    this.auditService.getFiltersData(lookup).subscribe({
      next: (data) => {
        filterValue.length = 0; // Clear the existing array
        Array.prototype.push.apply(filterValue, data.data); // Add new elements to the array
      },
      error: (error) => {
        console.error('Error fetching data:', error);
      },
    });
  }

  getTableData() {
    const body = {
      pageCount: 1,
      pageSize: 10,
      allowSearch: false,
      reportId: '',
      organizationName: '',
      fromDate: '',
      toDate: '',
      languageTypeId: '',
      requestTypeId: 1,
      trainingOrganizationStatusId: Status.DiscussionAndVoting,
    };

    this.loadingService.startLoading();

    this.auditService.getOrgFilteredData(body).subscribe({
      next: (data) => {
        this.loadingService.stopLoading();
        this.tableData = data.data;
      },
      error: (error) => {
        console.log('error', error);
      },
    });
  }
}
