import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.scss'],
})
export class TextField {
  @Input() id: string = '';
  @Input() isRequired: boolean;
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() keyFilter: string | RegExp = '';
  @Input() control: FormControl;
  @Input() errorMessage: string = 'هذا الحقل مطلوب';
}
