import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

export type DropdownOption = {
  key: string | number;
  value: string;
};
@Component({
  selector: 'dropdown',
  templateUrl: './dropdown.component.html',
  styleUrl: './dropdown.component.scss',
})
export class Dropdown {
  @Input() id: string;
  @Input() isRequired: boolean;
  @Input() options: DropdownOption[];
  @Input() label: string;
  @Input() placeholder: string;
  @Input() control: FormControl;
  @Input() clearable: boolean = false;
  @Input() errorMessage: string = 'هذا الحقل مطلوب';
}
