<div class="logo-container">
  <img
    class="logo-svg"
    src="https://www.seec.gov.sa/media/ytvb5gnn/new-logo.svg"
    alt=""
  />
</div>
<div class="container">
  <p class="error-number">404</p>
  <p>حدث خطأ ما</p>
  <button class="btn" (click)="onClick()">الرجوع للصفحة الرئيسية</button>
</div>
