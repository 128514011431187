import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { AuditService } from 'src/app/dashboard/services/audit.service';
import { BodyScrollService } from 'src/app/dashboard/services/body-scroll.service';
import { ScrollToTopService } from 'src/app/dashboard/services/scroll-to-top.service';
import { Status } from 'src/app/dashboard/types/status';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingService } from 'src/app/services/loading.service';
import { UserRole } from 'src/app/types/roles';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrl: './table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [LoadingService],
})
export class TableComponent implements OnInit {
  @Input() displayedColumns: string[] = [];
  @Input() data: any;
  @Input() headers: string[];
  @Input() title: string;
  @Input() customClass: string;
  @Input() isDateFilter: boolean;
  @Input() options: string[];
  @Input() applicationTypeOptions: string[];
  @Input() languageOptions: string[];
  @Input() totalRecords: number;
  @Input() showEyeButtonColumn: boolean = true;
  @Input() receiptsTable: boolean = false;
  @Input() isFinancialAuditor: boolean = false;
  filteredData: any;
  tableData: any;
  currentPage = 1;
  rowsPerPage = 10;
  status: number;
  body = {
    pageCount: 1,
    pageSize: this.rowsPerPage,
    allowSearch: true,
    organizationName: '',
  };
  userRole: number;
  showModal: boolean = false;
  showEditNotesIcon: boolean = false;

  constructor(
    private auditService: AuditService,
    private authService: AuthService,
    private router: Router,
    private scrollService: ScrollToTopService,
    private cdr: ChangeDetectorRef, // Add this line
    private bodyScrollService: BodyScrollService,
    private loadingService: LoadingService,
  ) {}

  isLoading$ = this.loadingService.isLoading$();

  ngOnInit() {
    this.bodyScrollService.toggleBodyScroll(true);
    this.filteredData = this.data;
    this.userRole = this.authService.getUserRole();
  }

  onFiltersChanged(filters: any) {
    this.currentPage = 1;
    const isUserRoleValid =
      this.authService.hasRole(UserRole.CommitteeSecretary) ||
      this.authService.hasRole(UserRole.TechnicalAdvisor) ||
      this.authService.hasRole(UserRole.GeneralManager) ||
      this.authService.hasRole(UserRole.FinancialAuditor) ||
      this.authService.hasRole(UserRole.InternalAuditor);

    this.body = Object.assign(this.body, {
      pageCount: 1,
      pageSize: 10,
      allowSearch: true,
      organizationName:
        isUserRoleValid && !this.receiptsTable ? filters?.searchQuery : '',
      reportId:
        !isUserRoleValid || this.receiptsTable ? filters?.searchQuery : '',
      fromDate: filters.startDate || '',
      toDate: filters.endDate || '',
      languageTypeId: filters.languageTypeId,
      requestTypeId: filters.applicationType || '',
      trainingOrganizationStatusId: filters.status || '',
    });
    if (this.isFinancialAuditor || this.receiptsTable) {
      this.auditService.getInvoicesList(this.body).subscribe((filteredData) => {
        this.filteredData = filteredData.data.data;
        this.totalRecords = filteredData.data.totalRecords;
        this.cdr.detectChanges(); // Manually trigger change detection
      });
    } else {
      this.auditService
        .getOrgFilteredData(this.body)
        .subscribe((filteredData) => {
          this.filteredData = filteredData.data.data;
          this.totalRecords = filteredData.data.totalRecords;
          this.cdr.detectChanges(); // Manually trigger change detection
        });
    }
  }
  onPageChange(event: any): void {
    const pageNumber = event.first / this.rowsPerPage + 1;
    this.currentPage = pageNumber;
    this.body.pageCount = pageNumber;
    if (this.isFinancialAuditor || this.receiptsTable) {
      this.auditService.getInvoicesList(this.body).subscribe({
        next: (data) => {
          this.filteredData = data.data.data;
          this.cdr.detectChanges(); // Manually trigger change detection
        },
        error: (error) => {
          console.log('error', error);
        },
      });
    } else {
      this.auditService.getOrgFilteredData(this.body).subscribe({
        next: (data) => {
          this.filteredData = data.data.data;
          this.cdr.detectChanges(); // Manually trigger change detection
        },
        error: (error) => {
          console.log('error', error);
        },
      });
    }

    this.scrollService.scrollToTop();
  }

  getStyles(col, rowData) {
    if (
      col.value === 'trainingOrganizationStatusStr' &&
      rowData?.trainingOrganizationStatusId
    ) {
      switch (rowData.trainingOrganizationStatusId) {
        case Status.Draft:
          return { background: '#E0E1E3', color: '#595A5C' };
        case Status.NewOrderWaitingInternalAuditor:
        case Status.RenewalRequestFromTrainingOrganizationToInternalAuditor:
          return { color: '#2560D9', background: '#E0EAFF' };
        case Status.ApprovedByTheInternalAuditorWaitingCommitteeSecretary:
          return { background: '#ECF3F1', color: '#2C6E59' };
        case Status.ApprovedByCommitteeSecretaryWaitingTechnicalExpert:
          return { background: '#ECF3F1', color: '#2C6E59' };
        case Status.ApprovedByTechnicalExpertWaitingCommitteeSecretary:
          return { background: '#ECF3F1', color: '#2C6E59' };
        case Status.AddressedByTrainingOrganization:
          return { background: '#ECF3F1', color: '#2C6E59' };
        case Status.ApprovedByCommitteeSecretaryWaitingPayment:
          return { background: '#ECF3F1', color: '#2C6E59' };
        case Status.ApprovedByCommitteeHeadWaitingCommitteeSecretary:
          return { background: '#ECF3F1', color: '#2C6E59' };
        case Status.ThePaymentWasMadeWaitingFinancialAuditor:
          return { background: '#ECF3F1', color: '#2C6E59' };
        case Status.ApprovedByTheFinancialAuditorWaitingCommitteeSecretary:
          return { background: '#ECF3F1', color: '#2C6E59' };
        case Status.ApprovedByCommitteeSecretaryWaitingGeneralManager:
        case Status.RenewalRequestHasBeenAcceptedByCommitteeSecretaryToGeneralManager:
          return { background: '#ECF3F1', color: '#2C6E59' };
        case Status.ApprovedByTheGeneralManagerAndTheCertificateHasBeenIssued:
        case Status.CertificateHasBeenRenewedByGeneralManagerToTrainingOrganization:
          return { background: '#ECF3F1', color: '#2C6E59' };
        case Status.ReturnedByInternalAuditorToTrainingOrganization:
        case Status.ReturnedByCommitteeSecrataryToInternalAuditor:
        case Status.ReturnedByCommitteeSecrataryAfterTechnicalAdvisor:
        case Status.ReturnedByTechnicalAdvisorToInternalAuditor:
        case Status.RejectedByCommitteeHeadToCommitteeSecretary:
        case Status.RenewalRequestHasBeenRejectedByCommitteeHeadToCommitteeSecretary:
        case Status.RejectedByCommitteeSecretaryToTrainingOrganization:
        case Status.ReturnedByCommitteeSecretaryToInternalAuditorAfterFormulationNote:
        case Status.ReturnedByCommitteeSecretaryToTechnicalAdvisorAfterFormulationNote:
        case Status.RejectedByFinancialAuditorToTrainingOrganization:
          return { background: '#FFE4E4', color: '#E44848' };

        default:
          return { background: '#E0E1E3', color: '#595A5C' };
      }
    } else if (
      !rowData?.trainingOrganizationStatusId &&
      col.name === 'حالة الإيصال'
    ) {
      switch (rowData.trainingOrganizationStatusStr) {
        case 'مكتمل':
          return { background: '#ECF3F1', color: '#2C6E59' };
        case 'تم الدفع':
          return { background: '#ECF3F1', color: '#2C6E59' };
        default:
          return { background: '#E0E1E3', color: '#595A5C' };
      }
    } else return '';
  }

  rowNavigationHandler(id: number, status: number, reportId: string) {
    if (this.receiptsTable) return;
    switch (this.userRole) {
      case UserRole.TrainingOrganization:
        this.router.navigate(['/dashboard/accreditation-form', id]);
        break;

      case UserRole.GeneralManager:
        switch (status) {
          case Status.DiscussionAndVoting:
          case Status.ApprovedByCommitteeSecretaryWaitingPayment:
          case Status.ThePaymentWasMadeWaitingFinancialAuditor:
          case Status.ApprovedByTheFinancialAuditorWaitingCommitteeSecretary:
          case Status.ApprovedByCommitteeSecretaryWaitingGeneralManager:
          case Status.RenewalRequestHasBeenAcceptedByCommitteeSecretaryToGeneralManager:
          case Status.ApprovedByTheGeneralManagerAndTheCertificateHasBeenIssued:
          case Status.CertificateHasBeenRenewedByGeneralManagerToTrainingOrganization:
            this.router.navigate(['/dashboard/voting', id]);
            break;

          case Status.ApprovedByTheInternalAuditorWaitingCommitteeSecretary:
            this.router.navigate(['/dashboard/application-details', id]);
            break;
          default:
            this.router.navigate(['/dashboard/application-details', id]);
            break;
        }
        break;

      case UserRole.CommitteeMember:
      case UserRole.CommitteeHead:
        this.router.navigate(['/dashboard/voting', id]);
        break;

      case UserRole.CommitteeSecretary:
        switch (status) {
          case Status.ApprovedByCommitteeHeadWaitingCommitteeSecretary:
          case Status.ApprovedByCommitteeSecretaryWaitingPayment:
          case Status.ThePaymentWasMadeWaitingFinancialAuditor:
          case Status.ApprovedByTheFinancialAuditorWaitingCommitteeSecretary:
          case Status.ApprovedByCommitteeSecretaryWaitingGeneralManager:
          case Status.RenewalRequestHasBeenAcceptedByCommitteeSecretaryToGeneralManager:
          case Status.ApprovedByTheGeneralManagerAndTheCertificateHasBeenIssued:
          case Status.CertificateHasBeenRenewedByGeneralManagerToTrainingOrganization:
          case Status.RejectedByCommitteeHeadToCommitteeSecretary:
          case Status.RenewalRequestHasBeenRejectedByCommitteeHeadToCommitteeSecretary:
          case Status.DiscussionAndVoting:
            this.router.navigate(['/dashboard/voting', id]);
            break;

          case Status.ApprovedByTheInternalAuditorWaitingCommitteeSecretary:
            this.router.navigate(['/dashboard/application-details', id]);
            break;
          default:
            this.router.navigate(['/dashboard/application-details', id]);
            break;
        }
        break;

      case UserRole.FinancialAuditor:
        this.router.navigate(['/dashboard/payment-receipt-details', id], {
          queryParams: { reportId: reportId.slice(1) },
        });
        break;

      default:
        this.router.navigate(['/dashboard/application-details', id]);
        break;
    }
  }

  sendToGeneralManagerButtonHandler(trainingOrganizationId: number) {
    this.showModal = true;
    this.loadingService.startLoading();
    this.auditService
      .updateApplicationStatus({
        TrainingOrganizationStatusEnum:
          Status.ApprovedByCommitteeSecretaryWaitingGeneralManager,
        TrainingOrganizationId: trainingOrganizationId,
      })
      .subscribe(() => {
        this.loadingService.stopLoading();
      });
  }

  statusCellHandler(value: number) {
    switch (this.userRole) {
      case UserRole.GeneralManager:
        return value ===
          Status.ApprovedByCommitteeSecretaryWaitingGeneralManager ||
          value ===
            Status.RenewalRequestHasBeenAcceptedByCommitteeSecretaryToGeneralManager
          ? 'تم الدفع'
          : 'لم يتم الدفع';

      case UserRole.FinancialAuditor:
        return false;

      default:
        return value;
    }
  }

  closeModel() {
    this.showModal = false;
    const filters = {
      pageCount: 1,
      pageSize: 10,
      allowSearch: false,
      organizationName: '',
      reportId: '',
      fromDate: '',
      toDate: '',
      languageTypeId: '',
      requestTypeId: '',
      trainingOrganizationStatusId: '',
    };
    this.onFiltersChanged(filters);
  }

  // getApplicationDetails(rowData: any) {
  //   if (
  //     this.authService.hasRole(UserRole.TrainingOrganization) &&
  //     rowData.trainingOrganizationStatusId ===
  //       Status.ApprovedByCommitteeSecretaryWaitingPayment
  //   ) {
  //     const reportId: string = (<string>rowData.reportId).slice(1);
  //     this.router.navigate(['/dashboard/payment-details', rowData.id], {
  //       queryParams: { reportId: reportId },
  //     });
  //   } else {
  //     this.router.navigate(['/dashboard/application-details', rowData.id]);
  //   }
  // }
}
