export const Assets = {
  calendar: 'assets/images/calendar.svg',
  card1: 'assets/images/card1.png',
  card2: 'assets/images/card2.png',
  card3: 'assets/images/card3.png',
  card4: 'assets/images/card4.png',
  card5: 'assets/images/card5.png',
  card6: 'assets/images/card6.png',
  checkMark: 'assets/images/checkMark.svg',
  close: 'assets/images/close.png',
  closeImg: 'assets/images/close-img.svg',
  course: 'assets/images/course.png',
  circleLogo: 'assets/images/circle-logo.png',
  docsImg1: 'assets/images/docs-img1.png',
  docsImg2: 'assets/images/docs-img2.png',
  downloadIcon: 'assets/images/download-icon.svg',
  edit: 'assets/images/edit.png',
  electronicSignature: 'assets/images/electronicSignature.png',
  email: 'assets/images/emaillcon.svg',
  eye: 'assets/images/eyelcon.png',
  face: 'assets/images/face.png',
  face2: 'assets/images/face2.png',
  file: 'assets/images/file.svg',
  group: 'assets/images/group.svg',
  human: 'assets/images/human.svg',
  licenseNumber: 'assets/images/licenseNumber.svg',
  location: 'assets/images/location.svg',
  logoCopy: 'assets/images/logo copy.svg',
  logo: 'assets/images/logo.svg',
  mail: 'assets/images/mail.svg',
  modalSubmitted: 'assets/images/modalSubmitted.svg',
  phone: 'assets/images/phone.svg',
  phoneCopy: 'assets/images/phonelcon.svg',
  removeSelected: 'assets/images/removeSelected.png',
  royaCopy: 'assets/images/roya copy.svg',
  roya: 'assets/images/roya.svg',
  signature: 'assets/images/signature.png',
  subheader: 'assets/images/subheader.png',
  userImage: 'assets/images/userlmage.svg',
  dividerTriangle: 'assets/images/DividerTriangle.svg',
  pdf: 'assets/images/pdf.svg',
  checked: 'assets/images/checked.svg',
  altImage: 'assets/images/alt-person.png',
  clock: 'assets/images/clock.svg',
  calenadar: 'assets/images/calenadar.svg',
  banner: 'assets/images/banner.png',
  check: 'assets/images/check.svg',
  trainingRequest: 'assets/images/trainingRequest.svg',
  trainingApproval: 'assets/images/trainingApproval.svg',
  dividerHomePage: 'assets/images/divider-homepage.svg',
  mainLogo: 'assets/images/main-logo.svg',
  journey1: 'assets/images/journey1.svg',
  journey2: 'assets/images/journey2.svg',
  journey3: 'assets/images/journey3.svg',
  journey4: 'assets/images/journey4.svg',
  journey5: 'assets/images/journey5.svg',
  journey6: 'assets/images/journey6.svg',
  editNotes: 'assets/images/edit-notes.svg',
  upload: 'assets/images/uploadIcon.svg',
  kldStamp: 'assets/images/kld-stamp.svg',
};
