import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'field-visit-criteria',
  templateUrl: './field-visit-criteria.component.html',
  styleUrl: './field-visit-criteria.component.scss',
})
export class FieldVisitCriteriaComponent {
  @Input() formGroupControl: FormGroup;
  @Input() criteriaIndex: number;

  onFileUpload(event) {
    const fieldVisitFiles = this.formGroupControl.get('files')?.value || [];
    if (event.target.files.length) fieldVisitFiles.push(...event.target.files);
    this.formGroupControl.get('files').setValue(fieldVisitFiles);
  }

  errorMessageTextHandler() {
    return 'هذا الحقل مطلوب';
  }

  clearCriteria() {
    this.formGroupControl.reset();
    this.formGroupControl.setErrors(null);
  }

  removeFile(index: number) {
    const fieldVisitFiles = this.formGroupControl.get('files')?.value;
    fieldVisitFiles.splice(index, 1);
    this.formGroupControl.get('files').setValue(fieldVisitFiles);
  }

  get criteriaControl() {
    return this.formGroupControl.get('criteria');
  }

  get noteControl() {
    return this.formGroupControl.get('note');
  }

  get fileControl() {
    return this.formGroupControl.get('files');
  }

  get radioControl() {
    return this.formGroupControl.get('radio');
  }

  get errors() {
    return this.formGroupControl?.errors;
  }
}
