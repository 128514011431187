import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'; //for future
import { Observable, of } from 'rxjs';
import { committeVotes } from '@assets/mock-jsons/committee-votes.js';

import { informationPanel } from '@assets/mock-jsons/information-panel.js';
import { paymentData } from '@assets/mock-jsons/payment.js';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private apiUrl = '';

  constructor(private http: HttpClient) {}

  getEvaluationData(): Observable<any> {
    // return this.http.get<any>(this.apiUrl); -> to be added
    return of(committeVotes); //for mock
  }

  getInfromationData(): Observable<any> {
    return of(informationPanel);
  }

  getPaymentData(): Observable<any> {
    return of(paymentData);
  }
}
