<div class="search-container">
  <div class="seach-input__container">
    <button class="search" (click)="onSearch()">
      <app-svg-icon name="search-icon" class="search-icon"></app-svg-icon>
    </button>
    <input
      class="search-field"
      [(ngModel)]="filters.searchQuery"
      placeholder="{{ 'search' | translate }}"
      (keydown.enter)="onSearch()"
    />
  </div>

  <app-custom-dropdown
    *ngIf="applicationTypeOptions"
    [selectorName]="'نوع الطلب'"
    [dropdownOptions]="applicationTypeOptions"
    (selectedOptionKey)="getApplicationType($event)"
  ></app-custom-dropdown>

  <app-custom-dropdown
    *ngIf="options"
    [selectorName]="
      receiptsTable
        ? 'الحالة'
        : isFinancialAuditor
          ? 'حالة الايصال'
          : ('dropDownStatus' | translate)
    "
    [dropdownOptions]="options"
    (selectedOptionKey)="receiveMessage($event)"
  ></app-custom-dropdown>

  <date-picker
    [selectionMode]="selectionMode"
    *ngIf="isDateFilter"
    [control]="date"
    [placeholder]="receiptsTable ? 'تاريخ الايصال' : 'تاريخ الطلب'"
    [showIcon]="true"
    (dateSelected)="handleDateSelected($event)"
  >
  </date-picker>
  <app-custom-dropdown
    *ngIf="languageOptions"
    [selectorName]="'لغة البرنامج'"
    [dropdownOptions]="languageOptions"
    (selectedOptionKey)="getProgramLanguage($event)"
  ></app-custom-dropdown>
</div>
