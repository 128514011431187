import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'text-area',
  templateUrl: './text-area.component.html',
  styleUrl: './text-area.component.scss',
})
export class TextArea {
  @Input() id: string;
  @Input() isRequired: boolean;
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() control: FormControl;
  @Input() columns: string = '88';
  @Input() rows: string = '5';
  @Input() errorMessage: string = 'هذا الحقل مطلوب';
}
