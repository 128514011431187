import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { AuthGuard } from '../guards/auth.guard';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private authService: AuthService,
    private authguard: AuthGuard,
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap((event: HttpEvent<unknown>) => {
        const responseBody = event['body'];
        const errorCode = responseBody?.errorCode;
        if (errorCode === 2) {
          this.authService.logOut().subscribe({
            next: (response) => {
              if (response.errorCode === 0) {
                this.router.navigate(['/home']);
                localStorage.removeItem('auth_token');
                localStorage.removeItem('email');
                localStorage.removeItem('role');
                localStorage.removeItem('isAuthenticated');
                localStorage.removeItem('userName');
              }
              this.authguard.setRedirectionUrl('');
              this.router.navigate(['/login']);
            },
          });
        }
      }),

      catchError((err: HttpErrorResponse) => {
        this.router.navigate(['/error-page']);
        return throwError(err);
      }),
    );
  }
}
