import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class ResponseInterceptorService implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      map((event) => {
        if (event instanceof HttpResponse) {
          return event.clone({
            headers: event.headers
              .append('X-Content-Type-Options', 'nosniff')
              .append('X-Frame-Options', 'DENY')
              .append('strict-transport-security', 'max-age=31536000')
              .append('X-XSS-Protection', '1; mode=block'),
          });
        }
        return event;
      }),
    );
  }
}
