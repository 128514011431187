import { Component, Input } from '@angular/core';
import { AuditService } from '@app/dashboard/services/audit.service';
import { LoadingService } from '@app/services/loading.service';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'archived-files-dialog',
  templateUrl: './archived-files-dialog.component.html',
  styleUrl: './archived-files-dialog.component.scss',
  providers: [LoadingService],
})
export class ArchivedFilesDialogComponent {
  @Input() tabIndex: number;
  @Input() trainingProgramDocumentsTabIndex: number;
  @Input() visible: boolean = false;
  @Input() allArchivedFiles: any[] = [];

  fileUrl: string = '';
  pdfDialogOpen: boolean = false;
  isLoading: Observable<boolean> = this.loadingService.isLoading$();
  fileFethSubscription: Subscription;

  constructor(
    private auditService: AuditService,
    private loadingService: LoadingService,
  ) {}

  viewFile(file) {
    console.log(file);
    this.loadingService.startLoading();
    this.pdfDialogOpen = true;
    document.body.style.paddingRight = '15px';
    this.fileFethSubscription = this.auditService
      .getFile(file.file?.id)
      .subscribe({
        next: (data) => {
          console.log(data);
          this.fileUrl = data;
          this.loadingService.stopLoading();
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  closePdfDialog() {
    this.fileUrl = '';
    this.pdfDialogOpen = false;
    document.body.classList.add('p-overflow-hidden');
    document.body.style.removeProperty('padding-right');
    document.body.style.setProperty('--scrollbar-width', '15px');
    this.fileFethSubscription?.unsubscribe();
  }

  closeDialog() {
    this.visible = false;
  }
}
