import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Assets } from '@assets/images';
import { AuditService } from '@app/dashboard/services/audit.service';
import { Status } from '@app/dashboard/types/status';
import { BehaviorSubject, Observable } from 'rxjs';
import { BodyScrollService } from '@app/dashboard/services/body-scroll.service';
import { LoadingService } from '@app/services/loading.service';

@Component({
  selector: 'app-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrl: './payment-details.component.scss',
  providers: [LoadingService],
})
export class PaymentDetailsComponent implements OnInit {
  @Input() data: any;

  @Output() isSendInvoiceButtonDisabledEmitter = new EventEmitter<boolean>();

  paymentData: any;
  organizationName: string;
  organizationId: number;
  reportId: string;
  imagesArr = [];
  file = Assets.pdf;
  fileData: any;
  showUploadFile: boolean;
  showSubmitModal: boolean = false;
  submittedSuccessfully: boolean = false;
  isLoading = new BehaviorSubject<boolean>(false);
  receiptPreview = '';
  showReceiptPreview = false;
  isPaymentLoading$: Observable<boolean> = this.loadingService.isLoading$();
  uploadFileTypeError = false;
  constructor(
    private router: Router,
    private auditService: AuditService,
    private bodyScrollingService: BodyScrollService,
    private loadingService: LoadingService,
  ) {}

  ngOnInit(): void {
    this.reportId = this.data.trainingOrganization.reportId;
    this.organizationId = this.data.trainingOrganization.trainingOrganizationId;
    this.organizationName = this.data.trainingProgram.nameAr;

    const requestStatus =
      this.data.workFlowTrainingOrganization.trainingOrganizationStatusLookupId;

    if (
      requestStatus === Status.ApprovedByCommitteeSecretaryWaitingPayment ||
      requestStatus === Status.RejectedByFinancialAuditorToTrainingOrganization
    )
      this.showUploadFile = true;

    this.getData();
  }

  removeImage() {
    this.fileData = undefined;
    this.isSendInvoiceButtonDisabledEmitter.emit(true);
  }

  getData() {
    this.auditService
      .getInvoiceData(this.organizationId)
      .subscribe(({ data }) => {
        this.paymentData = data;
        this.imagesArr = data.trainingOrganizationInvoiceFiles?.map(
          (item) => item.file,
        );
      });
  }

  createOrUpdateInvoice() {
    this.isLoading.next(true);
    this.bodyScrollingService.toggleBodyScroll(false);
    const body = {
      id: this.paymentData.id ?? '',
      trainingOrganizationId: this.organizationId,
      trainingOrganizationInvoiceFiles: [
        { file: this.fileData, fileId: '', id: '' },
      ],
    };

    this.showSubmitModal = true;
    this.auditService.sendInvoiceData(body).subscribe({
      next: (response) => {
        if (response.data) {
          this.auditService
            .updateApplicationStatus({
              TrainingOrganizationStatusEnum:
                Status.ThePaymentWasMadeWaitingFinancialAuditor,
              TrainingOrganizationId: this.organizationId,
            })
            .subscribe({
              next: () => {
                this.isLoading.next(false);
                this.submittedSuccessfully = true;
              },
              error: (error) => {
                console.log('Error sending the data', error);
              },
            });
        }
      },
      error: (error) => {
        console.error('data failed', error);
      },
    });
  }

  closeSubmitModal() {
    this.showSubmitModal = false;
    this.router.navigate(['/dashboard']);
  }
  openReceipt(file) {
    this.showReceiptPreview = true;
    this.loadingService.startLoading();

    this.auditService.getFile(file.id ?? file.fileId).subscribe({
      next: (res) => {
        this.receiptPreview = res;
        this.loadingService.stopLoading();
        document.body.style.overflow = 'hidden';
      },
    });
  }
  closePreview() {
    document.body.style.overflow = 'hidden';
    this.receiptPreview = '';
    this.showReceiptPreview = false;
    document.body.style.overflow = 'auto';
  }
}
