import { Component, Input, OnInit } from '@angular/core';
import { NotificationDto, NotificationsService } from './notifications.service';

@Component({
  selector: 'app-notifications-drop-down',
  templateUrl: './notifications-drop-down.component.html',
  styleUrl: './notifications-drop-down.component.scss',
})
export class NotificationsDropDownComponent implements OnInit {
  notificationsList: NotificationDto[] = [];
  pageNumber: number = 1;
  isMoreNotificationsClicked: boolean = false;
  newNotifications: NotificationDto[] = [];
  isThereMoreNotification: boolean = false;
  @Input() isNotificationOpen: boolean = false;
  isNewNotification: boolean = false;
  totalRecords: number = 0;

  constructor(public notificationsService: NotificationsService) {}

  ngOnInit(): void {
    this.getMoreNotification();
    this.notificationsService.notifications$.subscribe((notifications) => {
      if (notifications) {
        const newNotifications = notifications?.filter(
          (newNotification) =>
            !this.notificationsList?.some(
              (existingNotification) =>
                existingNotification?.notificationId ===
                newNotification?.notificationId,
            ),
        );
        this.notificationsList = [
          ...newNotifications,
          ...this.notificationsList,
        ];
        this.isNewNotification = true;
      }
    });
  }

  getIsNotReadNotifications() {
    this.isNewNotification = this.notificationsList.some(
      (notification) => notification.isRead == false,
    );
  }

  handleReadNotification(
    notificationId: number | string,
    redirectUrl: string,
    isRead: boolean,
  ) {
    if (!isRead) {
      this.notificationsService.sendReadNotification(notificationId).subscribe({
        next: () => {
          window.location.href = redirectUrl;
        },
        error: (error) => {
          console.error('Error fetching data:', error.message);
        },
      });
    } else {
      window.location.href = redirectUrl;
    }
  }

  handleSeeMoreNotifications() {
    if (this.isThereMoreNotification) {
      this.getMoreNotification();
      this.isMoreNotificationsClicked = true;
      this.isNewNotification = false;
    }
  }

  handleDefaultClick(event: Event) {
    event.stopPropagation();
  }

  getMoreNotification() {
    this.notificationsService.getMoreNotifications(this.pageNumber).subscribe({
      next: (response) => {
        this.totalRecords = response?.data?.totalRecords;
        if (response?.data?.data) {
          const newNotifications = response?.data?.data?.filter(
            (newNotification: NotificationDto) =>
              !this.notificationsList?.some(
                (existingNotification) =>
                  existingNotification?.notificationId ===
                  newNotification?.notificationId,
              ),
          );
          this.notificationsList?.push(...newNotifications);
        }

        this.isThereMoreNotification =
          this.pageNumber < this.totalRecords / 10 ? true : false;

        this.pageNumber == 1
          ? this.getIsNotReadNotifications()
          : (this.isNewNotification = false);

        this.pageNumber += 1;
      },
      error: (error) => {
        console.error('Error fetching data:', error.message);
      },
    });
  }

  getRelativeTime(date: Date): string {
    if (!date) {
      return 'وقت غير محدد';
    }

    const now = new Date();
    const diff = now.getTime() - new Date(date).getTime();

    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (seconds < 60) {
      return `منذ ${
        seconds == 1 ? 'ثانية' : seconds == 2 ? '' : seconds + ' ثواني'
      }`;
    } else if (minutes < 60) {
      return `منذ ${
        minutes == 1 ? 'دقيقة' : minutes == 2 ? 'دقيقتين' : minutes + ' دقيقة '
      }`;
    } else if (hours < 24) {
      return `منذ ${
        hours == 1 ? 'ساعة' : hours == 2 ? 'ساعتين' : hours + ' ساعات'
      }`;
    } else if (days < 7) {
      return `منذ ${days == 1 ? 'يوم' : days == 2 ? 'يومين' : days + ' أيام'}`;
    } else if (weeks < 4) {
      return `منذ ${
        weeks == 1 ? 'أسبوع' : weeks == 2 ? 'أسبوعين' : weeks + ' أسابيع'
      }`;
    } else if (months < 12) {
      return `منذ ${
        months == 1 ? 'شهر' : months == 2 ? 'شهرين' : months + ' شهور'
      }`;
    } else {
      return `منذ ${
        years == 1 ? 'سنة' : years == 2 ? 'سنتين' : years + ' سنوات'
      }`;
    }
  }
}
