import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FileDownloadService {
  constructor(private http: HttpClient) {}
  private apiUrl = `${environment.apiUrl}/api`;

  async downloadFile(fileId: number, fileName: string) {
    const getFileUrl = `${this.apiUrl}/File/GetFileById?id=${fileId}`;
    const blob: Blob = await firstValueFrom(
      this.http.get(getFileUrl, { responseType: 'blob' }),
    );
    this.triggerDownload(blob, fileName);
  }

  private triggerDownload(blob: Blob, fileName: string) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
}
