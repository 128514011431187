import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-homepage-docs-card',
  templateUrl: './homepage-docs-card.component.html',
  styleUrls: ['./homepage-docs-card.component.scss'],
})
export class HomepageDocsCardComponent {
  @Input() imgSrc!: string;
  @Input() title!: string;
  @Input() content!: string;
  @Input() link!: string;
}
