<loading-spinner class="download-loading-spinner"></loading-spinner>
<div
  class="logo-download-container"
  (click)="downloadFiles()"
  *ngIf="!(isLoading | async)"
>
  <app-custom-button
    [buttonStyle]="'greenButton'"
    [hasText]="hasText"
    [buttonText]="buttonText"
    *ngIf="hasText"
  >
    <app-svg-icon class="download-icon" name="download-icon"></app-svg-icon
  ></app-custom-button>

  <app-custom-button
    [buttonStyle]="'download-icon-only'"
    [hasText]="false"
    *ngIf="!hasText"
  >
    <app-svg-icon class="download-icon" name="download-icon"></app-svg-icon
  ></app-custom-button>
</div>
