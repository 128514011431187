<app-header></app-header>
<div class="change-password__container">
  <div class="form-container change-password-container__form">
    <h1 class="change-password__title">إنشاء كلمة مرور جديدة</h1>
    <p class="change-password__statment">
      تجنب استخدام معلومات يمكن تخمينها بسهولة.
    </p>
    <form
      class="form"
      [formGroup]="createNewPassword"
      (ngSubmit)="confirmNewPassword()"
    >
      <div class="password-container">
        <label for="password" class="form-label">إدخال كلمة مرور جديدة</label>
        <input
          type="{{ showPassword ? 'text' : 'password' }}"
          id="password"
          class="form-input"
          formControlName="newPassword"
          required
          placeholder="كلمة المرور"
        />
        <app-svg-icon
          *ngIf="!showPassword"
          name="eye-icon"
          (click)="togglePassword('password')"
          [ngClass]="
            createNewPassword.get('newPassword')?.hasError('pattern') &&
            createNewPassword.get('newPassword')?.touched
              ? 'error-eye'
              : 'eye-icon'
          "
        />
        <app-svg-icon
          *ngIf="showPassword"
          name="hide-eye"
          [ngClass]="
            createNewPassword.get('newPassword')?.hasError('pattern') &&
            createNewPassword.get('newPassword')?.touched
              ? 'hide-error-icon'
              : 'hide-eye'
          "
          (click)="togglePassword('password')"
        />

        <div
          class="error"
          *ngIf="
            createNewPassword.get('newPassword')?.hasError('pattern') &&
            createNewPassword.get('newPassword')?.touched
          "
        >
          الرجاء ادخال كلمة مرور مطابقة للشروط:
          <ul>
            <li>8-25 أحرف على الأقل</li>
            <li>حرف كبير واحد على الأقل</li>
            <li>حرف صغير واحد على الأقل</li>
            <li>رمز من الرموز الخاصة مثل * أو #</li>
            <li>رقم واحد على الأقل</li>
          </ul>
        </div>
      </div>
      <div class="password-container">
        <label for="conformPassword" class="form-label"
          >تأكيد كلمة المرور</label
        >
        <input
          type="{{ showConfirmPassword ? 'text' : 'password' }}"
          id="confirmPassword"
          class="form-input"
          formControlName="confirmNewPassword"
          required
          placeholder="كلمة المرور"
        />
        <app-svg-icon
          *ngIf="!showConfirmPassword"
          name="eye-icon"
          class="eye-icon"
          (click)="togglePassword('confirmPassword')"
        />
        <app-svg-icon
          *ngIf="showConfirmPassword"
          name="hide-eye"
          class="hide-eye"
          (click)="togglePassword('confirmPassword')"
        />
      </div>
      <div
        *ngIf="
          createNewPassword.hasError('passwordMismatch') &&
          createNewPassword.get('confirmNewPassword')?.touched
        "
        class="error"
      >
        كلمة السر غير متطابقة
      </div>
      <button type="submit" class="submit-btn">تعيين كلمة المرور</button>
    </form>
  </div>
  <div class="image-container">
    <img src="assets/images/login.png" alt="Image" />
  </div>
</div>
