import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { LoadingService } from 'src/app/services/loading.service';
import { AuditService } from '../../services/audit.service';
import { LookupsTypeEnum } from '../../types/lookUps';
import { Headers } from '../my-applications/my-applications.component';

@Component({
  selector: 'app-information-panel',
  templateUrl: './information-panel.component.html',
  styleUrls: ['./information-panel.component.scss'],
})
export class InformationPanelComponent implements OnInit {
  informationPanel: any;
  tableData: any;
  applicationTypeOptions: string[] = [];
  status: string[] = [];
  headers: Headers[] = [
    { name: 'رقم الطلب', value: 'reportId' },
    { name: 'البرنامج التدريبي', value: 'trainingProgramName' },
    { name: 'تاريخ الطلب', value: 'creationDate' },
    { name: 'حالة الطلب', value: 'trainingOrganizationStatusStr' },
  ];

  constructor(
    private auditService: AuditService,
    private loadingService: LoadingService,
  ) {}

  isLoading: Observable<boolean> = this.loadingService.isLoading$();

  ngOnInit(): void {
    this.loadingService.startLoading();
    const body = {
      pageCount: 1,
      pageSize: 10,
      allowSearch: false,
      reportId: '',
      organizationName: '',
      fromDate: '',
      toDate: '',
      languageTypeId: '',
      trainingOrganizationStatusId: 1,
    };

    this.auditService.getOrgFilteredData(body).subscribe({
      next: (data) => {
        this.tableData = data.data;
        this.auditService
          .getFiltersData(LookupsTypeEnum.TrainingOrganizationStatusEnum)
          .subscribe({
            next: (data) => {
              this.status.length = 0;
              Array.prototype.push.apply(this.status, data.data);
              this.auditService // this request is redundant and should be removed when endpoint is modified
                .getTrainingProgramsData({
                  pageCount: 1,
                  pageSize: 10,
                })
                .subscribe({
                  next: (data) => {
                    if (data.errorCode === 0) {
                      this.informationPanel = data.data;
                      this.loadingService.stopLoading();
                    }
                  },
                });
            },
          });
      },
    });
  }
}
