import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { AuthService } from 'src/app/services/auth.service';
import { UserRole } from '@app/types/roles';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  isTrainingOrganizationRole: boolean;
  items: MenuItem[] = [];

  constructor(
    public authService: AuthService,
    private router: Router,
  ) {
    this.isTrainingOrganizationRole = this.authService.hasRole(
      UserRole.TrainingOrganization,
    );
  }

  redirectToDashboard() {
    this.router.navigate(['/dashboard']);
  }

  redirectToProfilePage() {
    this.router.navigate(['/profile']);
  }

  clearLocalStorage() {
    const keysToRemove = [
      'auth_token',
      'email',
      'role',
      'isAuthenticated',
      'userName',
      'isFirstLogin',
    ];
    keysToRemove.forEach((key) => localStorage.removeItem(key));
  }
}
