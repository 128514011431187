import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router'; // Import the Router service
import { ScrollToTopService } from '@app/dashboard/services/scroll-to-top.service';
import { AuthService } from '@app/services/auth.service';
import { Rejex } from '@utils/rejex';

@Component({
  selector: 'app-signup-form',
  templateUrl: './signup-form.component.html',
  styleUrls: ['./signup-form.component.scss'],
})
export class SignupFormComponent implements OnInit {
  signupForm: FormGroup;
  showPassword: boolean = false;
  showConfirmPassword: boolean = false;
  errorSignup: boolean = false;
  signupButtonIsDisabled: boolean = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router, // Inject the Router service
    private scrollService: ScrollToTopService,
  ) {
    this.signupForm = this.fb.group(
      {
        organizationName: ['', Validators.required],
        phoneNumber: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        password: [
          '',
          [Validators.required, Validators.pattern(Rejex.StrongPasswordRegx)],
        ],
        confirmPassword: ['', Validators.required],
        userName: ['mays', Validators.required],
      },
      {
        validator: this.passwordMatchValidator,
      },
    );
  }

  ngOnInit(): void {
    this.scrollService.scrollToTop();
  }

  onSubmit() {
    if (this.signupForm.valid) {
      const user = this.signupForm.value;
      this.signupButtonIsDisabled = true;

      this.authService.signUp(user).subscribe({
        next: (response) => {
          if (response.errorCode === 0) {
            localStorage.setItem('otp_email', user.email);
            this.router.navigate(['/verify-otp']);
          }
          if (response.errorCode === 13) {
            this.signupButtonIsDisabled = false;
            this.errorSignup = true;
          }
        },
      });
    }
  }

  togglePassword(field: 'password' | 'confirmPassword') {
    if (field === 'password') {
      this.showPassword = !this.showPassword;
    } else if (field === 'confirmPassword') {
      this.showConfirmPassword = !this.showConfirmPassword;
    }
  }

  passwordMatchValidator(g: FormGroup) {
    return g.get('password').value === g.get('confirmPassword').value
      ? null
      : { passwordMismatch: true };
  }
}
