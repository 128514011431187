import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as html2pdf from 'html2pdf.js';
import { AuthService } from 'src/app/services/auth.service';
import { UserRole } from '@app/types/roles';
import { Assets } from '@assets/images';
import { Formatter } from '@utils/formatters';
import { AuditService } from '@app/dashboard/services/audit.service';
import { Status } from '@app/dashboard/types/status';
import { LoadingService } from 'src/app/services/loading.service';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';

@Component({
  selector: 'certificate',
  templateUrl: './certificate.component.html',
  styleUrl: './certificate.component.scss',
  providers: [LoadingService],
})
export class CertificateComponent implements OnInit {
  @Input() certificateData!: any;
  imageSrc: string = '';
  logo = Assets.logo;
  circleLogo = Assets.kldStamp;
  showModal: boolean = false;
  startDate: string;
  endDate: string;
  certificatePdfConfig: any = {
    filename: 'شهادة الاعتماد',
    margin: [0, 0],
    image: { type: 'webp', quality: 1 },
    jsPDF: { unit: 'px', format: [620, 1055], orientation: 'landscape' },
    html2canvas: {
      dpi: 192,
      scale: 3,
      letterRendering: true,
    },
  };

  submitButtonIsClicked: boolean = false;

  constructor(
    private auditService: AuditService,
    private authService: AuthService,
    private router: Router,
    private loadingService: LoadingService,
    private cdr: ChangeDetectorRef,
  ) {}
  isLoading$: Observable<boolean> = this.loadingService.isLoading$();

  buttonText: string = this.authService.hasRole(UserRole.GeneralManager)
    ? 'إصدار الشهادة'
    : 'تحميل الشهادة';

  ngOnInit() {
    this.startDate =
      environment['isDevelopment'] && this.certificateData?.isTrainingOrg
        ? Formatter.dateToTimestampString(this.certificateData.startDate)
        : Formatter.reverseDateString(this.certificateData.startDate);
    this.endDate =
      environment['isDevelopment'] && this.certificateData?.isTrainingOrg
        ? Formatter.dateToTimestampString(this.certificateData.endDate)
        : Formatter.reverseDateString(this.certificateData.endDate);
  }

  buttonClickHandler() {
    if (this.authService.hasRole(UserRole.GeneralManager)) {
      this.submitButtonIsClicked = true;
      this.showModal = true;
      this.loadingService.startLoading();
      this.auditService
        .updateApplicationStatus({
          TrainingOrganizationStatusEnum:
            Status.ApprovedByTheGeneralManagerAndTheCertificateHasBeenIssued,
          TrainingOrganizationId: this.certificateData.trainingOrganizationId,
        })
        .subscribe({
          next: () => {
            this.loadingService.stopLoading();
          },
          error: (error) => {
            console.log('Error sending the data', error);
          },
        });
    } else this.generateCertificatePDF();
  }

  generateCertificatePDF() {
    this.submitButtonIsClicked = true;
    const element = document.querySelector('.certificate');
    html2pdf()
      .from(element)
      .set(this.certificatePdfConfig)
      .save()
      .then(() => {
        this.submitButtonIsClicked = false;
        this.cdr.detectChanges();
      });
  }

  closeModel() {
    this.showModal = false;
    this.router.navigate(['/dashboard']);
  }
}
