import { Component } from '@angular/core';
import { Assets } from '@assets/images';

@Component({
  selector: 'app-homepage-docs',
  templateUrl: './homepage-docs.component.html',
  styleUrls: ['./homepage-docs.component.scss'],
})
export class HomepageDocsComponent {
  docsData = [
    {
      imgSrc: Assets.docsImg1,
      title: 'trainingPrograms',
      content: '',
      link: '/',
    },
    {
      imgSrc: Assets.docsImg2,
      title: 'userManual',
      content: 'stepByStep',
      link: '/',
    },
  ];
}
