<div class="tab-navigation" *ngIf="!documentArrow">
  <app-custom-button
    [buttonStyle]="'greenButton'"
    *ngIf="activeTabIndex !== 0"
    [buttonText]="'السابقة'"
    (click)="previousTab.emit()"
  ></app-custom-button>

  <app-custom-button
    [buttonStyle]="'greenButton'"
    *ngIf="activeTabIndex !== tabs.length - 1"
    [buttonText]="'الصفحة التالية'"
    (click)="nextTab.emit()"
  ></app-custom-button>
</div>
