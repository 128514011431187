import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { NgChartsModule } from 'ng2-charts';
import { DashboardSharedModule } from './components/shared/dashboard-shared.module';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '../components/shared.module';
import { TableModule } from 'primeng/table';

import { DashboardHeaderComponent } from './components/header/dashboard-header.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DashboardRootcomponent } from './dashboard-root.component';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { IntroCardComponent } from './components/intro-card/intro-card.component';
import { ApplicationCardsComponent } from './components/dashboard/application-cards/application-cards.component';
import { MenuComponent } from './components/header/menu/menu.component';

import { ModalService } from './services/modal.service';
import { BodyScrollService } from './services/body-scroll.service';
import { DialogService } from './services/dialog.service';
import { DataService } from './services/data.service';

import { TrainingProgramsComponent } from './components/training-programs/training-programs.component';
import { MyApplicationsComponent } from './components/my-applications/my-applications.component';
import { AccreditationApplicationComponent } from './components/accreditation-application/accreditation-application.component';
import { ApplicationDetailsComponent } from './components/application-details/application-details.component';
import { AuditorTableComponent } from './components/auditor-table/auditor-table.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { CommitteeVotingComponent } from './components/committee-voting/committee-voting.component';
import { OrderDetailsComponent } from './components/order-details/order-details.component';
import { InformationPanelComponent } from './components/information-panel/information-panel.component';
import { ApplicationDetailsUserRoleComponent } from './components/application-details-user-role/application-details-user-role.component';
import { ExpertApplicationView } from './components/expert-application-view/expert-application-view.component';
import { CommitteeSecretaryDashboardComponent } from './components/committee-secretary-dashboard/committee-secretary-dashboard.component';
import { CommitteeTableComponent } from './components/committee-table/committee-table.component';
import { ReceiptsTableComponent } from './components/receipts-table/receipts-table.component';
import { GeneralManagerTableComponent } from './components/general-manager-table/general-manager-table.component';
import { CertificatePageComponent } from './components/certificate-page/certificate-page.component';
import { VotingPageComponent } from './components/voting-page/voting-page.component';
import { PaymentReceiptDetailsComponent } from './components/payment-receipt-details/payment-receipt-details.component';
import { FinancialAuditorTableComponent } from './components/financial-auditor-table/financial-auditor-table.component';
import { OurServicesComponent } from '../our-services/our-services.component';
import { SecretaryFormComponent } from './components/secretary-form/secretary-form.component';
import { ProfilePageComponent } from '@app/components/profile-page/profile-page.component';

@NgModule({
  declarations: [
    DashboardHeaderComponent,
    ProfilePageComponent,
    DashboardComponent,
    SideBarComponent,
    IntroCardComponent,
    ApplicationCardsComponent,
    MenuComponent,
    TrainingProgramsComponent,
    MyApplicationsComponent,
    AccreditationApplicationComponent,
    ApplicationDetailsComponent,
    DashboardRootcomponent,
    AuditorTableComponent,
    CommitteeVotingComponent,
    OrderDetailsComponent,
    InformationPanelComponent,
    PaymentReceiptDetailsComponent,
    ApplicationDetailsUserRoleComponent,
    ExpertApplicationView,
    CommitteeSecretaryDashboardComponent,
    CommitteeTableComponent,
    ReceiptsTableComponent,
    GeneralManagerTableComponent,
    CertificatePageComponent,
    VotingPageComponent,
    FinancialAuditorTableComponent,
    OurServicesComponent,
    SecretaryFormComponent,
  ],

  imports: [
    BrowserModule,
    NgChartsModule,
    FormsModule,
    TranslateModule,
    DashboardSharedModule,
    DashboardRoutingModule,
    SharedModule,
    MatDatepickerModule,
    MatTableModule,
    TableModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
  ],
  providers: [ModalService, BodyScrollService, DialogService, DataService],
})
export class DashboardModule {}
