import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() includeSubHeader = false;
  public logoSvg!: SafeResourceUrl;
  isLoggedIn: boolean;

  constructor(
    private sanitizer: DomSanitizer,
    private authService: AuthService,
  ) {
    this.logoSvg = this.sanitizer.bypassSecurityTrustResourceUrl(
      'assets/images/logo.svg',
    );
    this.isLoggedIn = this.authService.isUserAuthenticated();
  }

  resetIsUserLogin = (isLoggedIn: boolean) => {
    this.isLoggedIn = isLoggedIn;
  };
}
