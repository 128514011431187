<app-document-inspector
  [isCheckbox]="true"
  [openModal]="showModal"
  [fileIndex]="fileIndex"
  [fileURL]="fileURL"
  (closeModal)="hideModal()"
  (showTrainingDataChange)="onShowTrainingDataChange($event)"
  [customClass]="'initial-document'"
  [criteriaLookup]="criteriaLookup"
  [requestStatus]="requestStatus"
  (showPledgeChange)="onShowPledge($event)"
  [allNotes]="allNotes"
  (showTabDataIndexValueChanged)="onTabDataIndexValueChange($event)"
  [updateRequestNumber]="updateRequestNumber"
  [trainingOrganizationId]="
    requestData.trainingProgramDocuments.trainingOrganizationId
  "
  [trainingProgramDocumentsId]="
    requestData.trainingProgramDocuments.trainingProgramDocumentsId
  "
  #inspectorComponent
>
  <div *ngIf="showTrainingData" class="tab-data training-program__data">
    <div class="training-body__title">بيانات البرنامج التدريبي</div>
    <div class="row-data">
      <div class="training-body__info">
        <div class="field-container">
          <app-svg-icon class="field-icon" name="document-icon"></app-svg-icon>
          <div class="field-name">اسم البرنامج التدريبي</div>
        </div>
        <div class="field-value">
          {{ requestData.trainingProgram.nameAr }}
        </div>
      </div>
      <div class="training-body__info">
        <div class="field-container">
          <app-svg-icon class="field-icon" name="document-icon"></app-svg-icon>
          <div class="field-name">اسم البرنامج التدريبي باللغة الإنجليزية</div>
        </div>
        <div class="field-value">
          {{ requestData.trainingProgram.nameEn }}
        </div>
      </div>
    </div>
    <div class="row-data">
      <div class="training-body__info">
        <div class="field-container">
          <app-svg-icon class="field-icon" name="note-icon"></app-svg-icon>
          <div class="field-name">نوع البرنامج التدريبي</div>
        </div>
        <div class="field-value">
          {{ requestData.trainingProgram.trainingProgramType }}
        </div>
      </div>
      <div class="training-body__info">
        <div class="field-container">
          <app-svg-icon class="field-icon" name="badge-icon"></app-svg-icon>
          <div class="field-name">مستوى البرنامج التدريبي</div>
        </div>
        <div class="field-value">
          {{ requestData.trainingProgram.programLevelName }}
        </div>
      </div>
    </div>
    <div class="row-data">
      <div class="training-body__info">
        <div class="field-container">
          <app-svg-icon class="field-icon" name="clock-icon"></app-svg-icon>
          <div class="field-name">مدة البرنامج التدريبي (بالأيام)</div>
        </div>
        <div class="field-value">
          {{ requestData.trainingProgram.trainingProgramDuration }}
        </div>
      </div>
      <div class="training-body__info">
        <div class="field-container">
          <app-svg-icon class="field-icon" name="clock-icon"></app-svg-icon>
          <div class="field-name">عدد الساعات التدريبية الإجمالية</div>
        </div>
        <div class="field-value">
          {{ requestData.trainingProgram.totalTrainingHours }}
        </div>
      </div>
    </div>
    <div class="row-data">
      <div class="training-body__info">
        <div class="field-container">
          <app-svg-icon class="field-icon" name="target-icon"></app-svg-icon>
          <div class="field-name">الهدف العام من البرنامج التدريبي</div>
        </div>
        <div class="field-value">
          {{ requestData.trainingProgram.programObjective }}
        </div>
      </div>
      <div class="training-body__info">
        <div class="field-container">
          <app-svg-icon class="field-icon" name="globe-icon"></app-svg-icon>
          <div class="field-name">لغة البرنامج التدريبي</div>
        </div>
        <div class="field-value">
          {{ requestData.trainingProgram.languageLookupName }}
        </div>
      </div>
    </div>
    <div class="row-data">
      <div class="training-body__info">
        <div class="field-container">
          <app-svg-icon class="field-icon" name="users-icon"></app-svg-icon>
          <div class="field-name">الفئة المستهدفة</div>
        </div>
        <div class="field-value">
          {{ requestData.trainingProgram.targetCategory }}
        </div>
      </div>
    </div>
    <div>
      <div class="training-body__info">
        <div class="field-container">
          <app-svg-icon class="field-icon" name="book-icon"></app-svg-icon>
          <div class="field-name">وصف البرنامج التدريبي</div>
        </div>
        <div class="field-value training-program-description">
          {{ requestData.trainingProgram.description }}
        </div>
      </div>
    </div>
  </div>
  <div
    class="tab-content__container tab-data"
    *ngIf="generalCommitment && showPledge"
  >
    <div class="training-body__title">التعهد العام</div>

    <div class="pledge-container">
      <span class="pledge-intro"
        >السادة المركز السعودي لكفاءة الطاقة حفظهم االله
      </span>
      <p class="pledge-intro">السلام عليكم ورحمة االله وبركاته</p>
      <p class="pledge-para">
        نتعهد نحن {{ trainingOrganization.organizationName }}، بالالتزام بلائحة
        اعتماد البرامج التدريبية في مجال كفاءة الطاقة والدليل الإرشادي للائحة
        وكافة التعاميم والتعليمات الخاصة التي يصدرها المركز السعودي لكفاءة
        الطاقة بهذا الشأن.
      </p>
      <span class="pledge-para salute">وتقبلوا وافر التحية والتقدير</span>
      <div class="applicant-container">
        <span class="applicant-name__text">اسم مقدم الطلب</span>
        <span class="applicant-name">{{
          generalCommitment.applicantsName
        }}</span>
      </div>
      <div class="electronic-signature__container" *ngIf="imgUrl">
        <span>التوقيع الإلكتروني</span>
        <div class="electronic-signature">
          <div class="signature-container">
            <img
              [src]="imgUrl"
              alt="Electronic Signature"
              class="signature-img"
            />
          </div>
        </div>
      </div>
      <div
        *ngIf="
          isEditableApplication &&
          (filterCriteria(
            requestData?.generalCommitment
              ?.trainingProgramDocumentAndCriteriaNotes
              ?.programDocumentCriteriaMappings
          )?.length ||
            requestData?.generalCommitment
              ?.trainingProgramDocumentAndCriteriaNotes?.documentNotes?.length)
        "
        class="accordions-container"
      >
        <accordion
          *ngIf="
            filterCriteria(
              requestData?.generalCommitment
                ?.trainingProgramDocumentAndCriteriaNotes
                ?.programDocumentCriteriaMappings
            )?.length
          "
          [customClass]="'criteria-accordion'"
          [header]="'معايير التعهد العام'"
        >
          <div class="checked-section">
            <app-view-evaluation
              [filterAccordionCriteria]="
                filterCriteria(
                  requestData?.generalCommitment
                    ?.trainingProgramDocumentAndCriteriaNotes
                    ?.programDocumentCriteriaMappings
                )
              "
            />
          </div>
        </accordion>
        <accordion
          [customClass]="'notes-accordion'"
          *ngIf="
            requestData?.generalCommitment
              ?.trainingProgramDocumentAndCriteriaNotes?.documentNotes?.length
          "
          [header]="'ملاحظات أخرى'"
        >
          <div class="user-notes">
            <div
              *ngFor="
                let note of requestData?.generalCommitment
                  ?.trainingProgramDocumentAndCriteriaNotes?.documentNotes
              "
              class="user-note"
              [class.empty]="!note.note"
            >
              <app-comment-section [data]="note" />
            </div>
          </div>
        </accordion>
      </div>
    </div>
  </div>
</app-document-inspector>
<div class="container" *ngIf="!showCommitteeSecretaryNote">
  <app-custom-button
    buttonText="ملاحظات الأمين"
    buttonStyle="transperantGreenButton"
    *ngIf="showCommitteeSecretaryNoteButton"
    class="secretary-discussion-note-button"
    (click)="
      showCommitteeSecretaryNoteChange.emit(true);
      showCommitteeSecretaryNote = true
    "
  />
  <h1>التقييم الأولي</h1>
  <div
    class="documents-container"
    *ngFor="
      let data of documentInspectorStateService?.trainingProgramDocumentMapping;
      let dataIndex = index
    "
  >
    <div class="files-container">
      <div class="label-container">
        <div class="file-label">{{ data.programDocumentLookup.name }}</div>
        <ul class="field-subtitles">
          <li
            *ngFor="
              let subtitle of data.programDocumentLookup
                .programDocumentRequirements
            "
          >
            {{ subtitle }}
          </li>
        </ul>
      </div>
      <div class="file-icons-list-container">
        <file-icon
          *ngIf="!data.files.length"
          [file]="{ name: data.programDocumentLookup.name, size: 10000 }"
          (click)="viewTrainingOrPledgeData(dataIndex)"
          [readOnly]="true"
        />
        <file-icon
          *ngFor="let file of data.files; let i = index"
          (click)="viewFile(dataIndex, i)"
          [file]="file?.archivedFiles?.at(-1)?.file"
          [index]="i"
          [readOnly]="true"
        />
      </div>
    </div>
    <accordion
      *ngIf="
        accordionVisibilityHandler(
          data.programDocumentLookup.programDocumentCriteriaMappings
        )
      "
      [header]="'معايير ' + data.programDocumentLookup.name"
      [customClass]="'criteria'"
    >
      <div class="checked-section">
        <app-view-evaluation
          [filterAccordionCriteria]="
            criteriaFilter(
              data.programDocumentLookup.programDocumentCriteriaMappings
            )
          "
        />
      </div>
    </accordion>
    <accordion
      [customClass]="'notes-accordion'"
      *ngIf="data.documentNotes?.length"
      [header]="'ملاحظات أخرى'"
    >
      <div class="user-notes">
        <div
          *ngFor="let note of data.documentNotes"
          class="user-note"
          [class.empty]="!note.note"
        >
          <app-comment-section [data]="note" />
        </div>
      </div>
    </accordion>
  </div>
  <app-field-visit-wrapper
    *ngIf="fieldVisitData.length"
    [requestStatus]="requestStatus"
    [fieldVisitData]="fieldVisitData"
  />

  <div class="technical-advisor-evaluation files-container">
    <div class="technical-advisor-evaluation-header">
      <p class="file-label">تقييم الخبير</p>
      <a href="assets/files/technicalAdvisorEvaluationForm.xlsx">
        <app-custom-button
          [buttonStyle]="'greenButton'"
          [buttonText]="'تحميل النموذج'"
          class="download-form"
          ><app-svg-icon
            class="download-icon"
            name="download-icon"
          ></app-svg-icon
        ></app-custom-button>
      </a>
    </div>
    <div
      class="files-icons-wrapper"
      *ngIf="
        requestData.technicalAdvisorEvaluationsFiles?.length ||
        technicalAdvisorEvaluationFiles?.length
      "
    >
      <div class="file-icons-list-container">
        <ng-container
          class="older-files"
          *ngFor="let file of getOlderEvaluationsFiles(); let i = index"
        >
          <div class="field-visit-files-container older-files">
            <div class="file__container">
              <file-icon
                [file]="file.file"
                [index]="i"
                showIcon="true"
                someText="text"
                (removeFileEmmiter)="
                  onRemoveTechnicalAdvisorEvaluationFile(file)
                "
                readOnly="true"
                (click)="getFile(file.file)"
              ></file-icon>
            </div></div
        ></ng-container>
        <ng-container
          class="new-uploaded-files"
          *ngFor="let file of technicalAdvisorEvaluationFiles; let i = index"
        >
          <div class="field-visit-files-container">
            <div class="file__container">
              <file-icon
                [file]="file.file"
                [index]="i"
                (removeFileEmmiter)="
                  onRemoveTechnicalAdvisorEvaluationFile(file)
                "
              ></file-icon>
            </div></div
        ></ng-container>
      </div>
    </div>
    <div class="file-input">
      <div class="file-input_placeholder" *ngIf="!isUploadEvaluationFileHidden">
        إرفاق ملف
      </div>
      <div class="file-input_container" *ngIf="!isUploadEvaluationFileHidden">
        <label for="expert-form-file" class="file-input-label"
          ><img src="assets/images/attachment.svg"
        /></label>
        <input
          class="file-input"
          type="file"
          name="expert-form-file"
          id="expert-form-file"
          (change)="onUploadTechnicalAdvisorEvaluationFile($event)"
          accept=".pdf"
        />
      </div>
    </div>
    <span class="error-msg" *ngIf="isFileTypeInvalid">يرجى تحميل ملف بصيغة PDF</span>
  </div>

  <check-box
    label="هذا الطلب بحاجة زيارة ميدانية"
    [control]="expertForm.get('needsFieldVisit')"
  />

  <div
    class="field-visit-container"
    *ngIf="expertForm.get('needsFieldVisit').value"
  >
    <a [href]="pdfUrl" download="معايير الزيارة.pdf">
      <app-custom-button
        [buttonStyle]="'greenButton'"
        [buttonText]="'تنزيل'"
        class="download-field-visit-form"
        ><app-svg-icon class="download-icon" name="download-icon"></app-svg-icon
      ></app-custom-button>
    </a>
    <p class="field-visit-header">تقييم الزيارة الميدانية</p>
    <form class="field-visit-grid">
      <div class="field-visit-row header">
        <div class="field-visit-criteria-column">
          <h3>المعيار</h3>
        </div>
        <div class="field-visit-evaluation-column">
          <h3>التقييم</h3>
        </div>
        <div class="field-visit-notes-column">
          <h3>الملاحظات</h3>
        </div>
      </div>
      <ng-container
        *ngFor="
          let criteriaFormGroup of fieldVisitCriteriaControlList;
          let i = index
        "
      >
        <field-visit-criteria
          [formGroupControl]="criteriaFormGroup"
          [criteriaIndex]="i"
        />
      </ng-container>
      <button
        type="button"
        class="add-field-visit-criteria-button"
        (click)="addCriteria()"
      >
        إضافة معيار جديد <img src="assets/images/plus-icon.svg" />
      </button>
      <div class="attach-file additional-attachments">
        <div
          class="file-input_placeholder"
          *ngIf="!fieldVisitForm.get('fieldVisitFiles')?.value?.length"
        >
          إرفاق ملف
        </div>

        <div
          class="files-icons-wrapper"
          *ngIf="fieldVisitForm.get('fieldVisitFiles')?.value?.length"
        >
          <div class="file-icons-list-container">
            <ng-container
              *ngFor="
                let file of fieldVisitForm.get('fieldVisitFiles').value;
                let i = index
              "
            >
              <div
                *ngIf="file.constructor.name === 'File'"
                class="field-visit-files-container"
              >
                <div class="file__container">
                  <file-icon
                    [file]="file"
                    [index]="i"
                    showIcon="{{ file?.size }}"
                    (removeFileEmmiter)="removeFile($event)"
                  ></file-icon>
                </div></div
            ></ng-container>
          </div>
        </div>

        <div class="file-input_container">
          <label for="field-visit-file" class="file-input-label"
            ><img src="assets/images/attachment.svg"
          /></label>
          <input
            class="file-input"
            type="file"
            name="field-visit-file"
            id="field-visit-file"
            (change)="onFileUpload($event)"
            multiple
          />
        </div>
      </div>
      <text-field
        class="field-visit-general-notes"
        [control]="fieldVisitForm.get('fieldVisitNotes')"
        [placeholder]="'ملاحظات الزيارة الميدانية'"
      />
    </form>
  </div>
  <div class="general-notes-container">
    <check-box
      label="ملاحظات فنية"
      [control]="expertForm.get('hasGeneralNotes')"
    ></check-box>
    <app-rich-text-editor
      *ngIf="expertForm.get('hasGeneralNotes').value"
      [control]="expertForm.get('generalNotes')"
      [isDisabled]="!expertForm.get('hasGeneralNotes').value"
      (textValueEmitter)="generalNoteText = $event"
    />
  </div>
  <div class="buttons-container">
    <div class="buttons-inner-container">
      <app-custom-button
        [disabled]="isButtonDisabled()"
        (click)="submitHandler()"
        [buttonText]="'إرسال الطلب إلى أمين اللجنة'"
        [buttonStyle]="'greenButton'"
      ></app-custom-button>
      <app-custom-button
        [disabled]="isSendToIAButtonDisabled()"
        (click)="showConfirmationModal()"
        [buttonText]="'إعادة الطلب إلى المدقق الداخلي'"
        [buttonStyle]="'transperantGreenButton'"
      />
    </div>
    <span class="error-msg" *ngIf="!isAllCriteriaEvaluated()">
      لم تقم بإكمال عملية التقييم
    </span>
  </div>
  <div class="success-modal__container">
    <app-modal
      [modalContent]="'submission'"
      [showModal]="showSubmitModal"
      (closeModal)="closeModel()"
      [customClass]="'success-modal'"
      [isCloseVisible]="submittedSuccessfully"
    >
      <loading-spinner>جاري إرسال الطلب...</loading-spinner>
      <div
        *ngIf="
          submittedSuccessfully && !(isLoading | async);
          else confirmationModal
        "
        class="modal-body"
      >
        <app-svg-icon class="success-icon" name="success-icon"></app-svg-icon>
        <span class="modal-text">{{
          isSubmitButtonClicked
            ? 'تم إرسال الطلب إلى أمين اللجنة'
            : 'تم إرسال الطلب إلى المدقق الداخلي'
        }}</span>
      </div>
      <ng-template #confirmationModal>
        <div class="confirmation-modal-body" *ngIf="!(isLoading | async)">
          <span class="modal-text">
            هل ترغب في تأكيد إعادة إرسال الطلب إلى المدقق الداخلي؟
          </span>
          <div class="confirmation-buttons">
            <app-custom-button
              (click)="handleReturnToInternalAuditor()"
              [buttonText]="'نعم، تأكيد'"
              [buttonStyle]="'greenButton'"
            ></app-custom-button>
            <app-custom-button
              (click)="cancelReturnToInternalAuditor()"
              [buttonText]="'إلغاء'"
              [buttonStyle]="'transperantGreenButton'"
            ></app-custom-button>
          </div>
        </div>
      </ng-template>
    </app-modal>
  </div>
</div>
<section id="secretary-note-container" *ngIf="showCommitteeSecretaryNote">
  <app-page-title customClass="secretary-note-title"
    >ملاحظات الأمين على الطلب رقم
    <span class="green-color">{{
      requestData.trainingOrganization.reportId
    }}</span></app-page-title
  >
  <article
    class="secretary-note"
    [innerHTML]="committeeSecretaryDiscussionNote?.note ?? noNotesHTML"
  ></article>
  <app-custom-button
    buttonText="العودة إلى تفاصيل الطلب"
    buttonStyle="greenButton"
    (click)="hideCommitteeSecretaryNote()"
  />
</section>
