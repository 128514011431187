<app-header includeSubHeader="true"></app-header>
<div class="container">
  <div class="title__container">سياسة الاستخدام</div>

  <div class="policy_container">
    <div class="policy_content">
      <div>
        <div class="policy_sub_title">سياسة الاستخدام</div>
        <p class="policy_sub">
          يرجى قراءة اتفاقية مستوى الخدمة وسياسة الخصوصية وأي اتفاقيات أخرى
          مذكورة في هذه الوثيقة بعناية.
        </p>
      </div>
      <div>
        <h3>سياسة الاستخدام وإخلاء المسؤولية</h3>
        <p>
          هذه المنصة الإلكترونية للمركز السعودي لكفاءة الطاقة في المملكة العربية
          السعودية متاحة لاستخدامك الشخصي، ويخضع دخولك واستخدامك لهذه المنصة
          لشروط وأحكام الاستخدام هذه وأنظمة المملكة العربية السعودية. كما أن
          دخولك إلى هذه المنصة يعتبر قبولاً غير مشروط لشروط وأحكام الاستخدام،
          سواء كنت مستخدماً مسجلاً أم لا، وتسري هذه الموافقة اعتباراً من تاريخ
          أول استخدام لك لهذه المنصة.
        </p>
        <p>
          يتضمن استخدام المنصة عدداً من الشروط والأحكام التي تخضع لتحديثات
          وتغييرات مستمرة حسب الحاجة، وأي تعديل أو تحديث لأي من هذه الشروط
          والأحكام يصبح ساري المفعول بمجرد اعتماده من قبل إدارة المنصة، الأمر
          الذي يتطلب عليك مراجعة شروط الاستخدام ومبادئ إخلاء المسؤولية بشكل
          مستمر لمعرفة أي تحديثات تطرأ عليها، حيث أن استمرارك في استخدام هذه
          المنصة يعني أنك على اطلاع وقبول كامل لأي تعديل على شروط وأحكام
          استخدامها. علماً بأن هذه الشروط والأحكام تتضمن حقوق الملكية، ولا يتوجب
          على إدارة المنصة الإعلان عن أي تحديث لهذه الشروط.
        </p>
      </div>
      <div>
        <h3>القيود المفروضة على الاستخدام</h3>
        <p>
          باستخدامك لمنصة المركز السعودي لكفاءة الطاقة، فإنك توافق على الامتناع
          عما يلي:
        </p>
        <ol>
          <li>
            توفير أو تحميل ملفات تحتوي على برامج أو مواد أو بيانات أو معلومات
            أخرى لا تملكها أو ليس لديك ترخيص بها.
          </li>
          <li>
            استخدام هذه المنصة بأي شكل من الأشكال لإرسال أي بريد إلكتروني تجاري
            أو غير مرغوب فيه أو أي إساءة استخدام من هذا النوع إلى منصة المركز
            السعودي لكفاءة الطاقة.
          </li>
          <li>
            توفير أو تحميل ملفات على هذه المنصة تحتوي على فيروسات أو بيانات
            تالفة.
          </li>
          <li>
            نشر أو إعلان أو توزيع أو تداول مواد أو معلومات تحتوي على تشهير أو
            مخالفة للأنظمة أو مواد إباحية أو فاحشة تخالف التعاليم الإسلامية أو
            الآداب العامة أو أي مواد أو معلومات غير قانونية من خلال منصة المركز
            السعودي لكفاءة الطاقة.
          </li>
          <li>
            المشاركة عبر منصة المركز السعودي لكفاءة الطاقة في أنشطة غير مشروعة
            في المملكة العربية السعودية.
          </li>
          <li>
            الإعلان – عبر منصة المركز السعودي لكفاءة الطاقة – عن أي منتج أو خدمة
            تجعلنا مخالفين لأي قانون أو نظام مطبق في أي مجال.
          </li>
          <li>
            استخدام أي وسيلة أو برنامج أو إجراء لاعتراض أو محاولة التدخل في
            التشغيل الصحيح للمركز السعودي لكفاءة الطاقة.
          </li>
          <li>
            اتخاذ أي إجراء يفرض حملاً غير معقول أو كبير أو غير مناسب على البنية
            التحتية لمنصة المركز السعودي لكفاءة الطاقة.
          </li>
        </ol>
      </div>

      <div>
        <h3>روابط إلى منصتنا</h3>
        <p>
          باستخدام الروابط الخاصة بمنصة المركز السعودي لكفاءة الطاقة، باستثناء
          ما هو مذكور أدناه، يمنع نقل أو نسخ أي من محتويات منصة المركز السعودي
          لكفاءة الطاقة، أو إنشاء أي روابط إلكترونية خاصة بها، أو عرض أي منها في
          إطار .
        </p>
        <ol>
          <li>
            يمكن وضع روابط منصة المركز السعودي لكفاءة الطاقة في أي مواقع أخرى لا
            تتعارض في أهدافها وتوجهها العام مع أهداف وسياسات وأطر منصة وزارة
            العمل والتنمية الاجتماعية.
          </li>
          <li>
            ولا تعتبر منصة المركز السعودي لكفاءة الطاقة بأي حال من الأحوال
            مشاركة أو مرتبطة بأي شكل من الأشكال بأي علامات تجارية أو شعارات أو
            رموز تجارية أو خدمية أو أي وسيلة أخرى مستخدمة أو تظهر على المواقع
            الإلكترونية المرتبطة بهذه المنصة أو أي من محتوياتها.
          </li>
          <li>
            تحتفظ منصة المركز السعودي لكفاءة الطاقة بجميع حقوقها في إيقاف وعرقلة
            أي رابط بأي شكل من الأشكال من أي موقع يحتوي على مواضيع أو أسماء أو
            مواد أو معلومات غير لائقة أو فاضحة أو مخالفة أو فاحشة أو إباحية أو
            غير مقبولة أو غير قانونية. أو تنتهك أي حقوق ملكية فكرية أو حقوق
            خصوصية أو حقوق عامة.
          </li>
          <li>
            تحتفظ منصة المركز السعودي لكفاءة الطاقة بالحق في تعطيل أي رابط بأي
            طريقة غير مصرح بها ولا تتحمل أي مسؤولية عن المحتويات المتوفرة في أي
            موقع آخر يتم الوصول إليه من خلال هذه المنصة أو يتم الدخول إلى هذه
            المنصة منه.
          </li>
        </ol>
      </div>
      <div>
        <h3>روابط من منصة المركز السعودي لكفاءة الطاقة</h3>
        <p>
          يتم توفير روابط الاتصال الخاصة بالمنصات و/أو المواقع الأخرى بغرض
          التسهيل على الزائر، ونحن غير مسؤولين عن محتويات أو مصداقية المنصات
          و/أو المواقع التي نرتبط بها ولا نصادق على محتوياتها، وبالتالي إن
          استخدام أي من هذه الروابط للوصول إلى تلك المواقع أو المنصات يكون على
          مسؤوليتك الخاصة. من خلال السعي لاستبدال الروابط المعطلة التي لا تعمل
          مع المواقع الأخرى، وبما أننا لا نملك السيطرة على تلك الروابط، فإننا لا
          نضمن بأي شكل من الأشكال أن هذه الروابط ستعمل بشكل دائم.
        </p>
      </div>
      <div>
        <h3>الحماية من الفيروس</h3>
        <p>
          نحن نبذل قصارى جهدنا لفحص واختبار محتويات هذه المنصة في جميع مراحل
          الإنتاج، وننصحك دائمًا بتشغيل برنامج مكافحة الفيروسات على جميع المواد
          التي يتم تنزيلها من الإنترنت. نحن لسنا مسؤولين عن أي خسارة أو انقطاع
          أو ضرر لبياناتك أو جهاز الكمبيوتر أو أي جهاز يستخدم للتصفح قد يحدث
          أثناء الاتصال بهذه المنصة أو عند استخدام مواد من هذه المنصة.
        </p>
      </div>
      <div>
        <h3>التنازل عن المطالبات</h3>
        <p>
          إن منصة المركز السعودي لكفاءة الطاقة والخدمات والمعلومات والمواد
          والخصائص المتوفرة فيها أو التي يمكن الوصول إليها من خلال المنصة مقدمة
          لاستخدامك الشخصي "كما هي" و"كما هي متاحة" دون أي إقرار أو وعود أو
          ضمانات من أي نوع. ولا يمكننا أن نضمن أو نكون مسؤولين عن أي انقطاعات أو
          أخطاء أو تجاوزات قد تنشأ عن استخدام هذه المنصة أو محتوياتها أو أي موقع
          مرتبط بها، سواء بعلمنا أو بدون علمنا.
        </p>
        <p>
          إن أي اتصالات أو معلومات قد يرسلها المستخدم عبر هذه المنصة لن يكون له
          الحق في امتلاكها أو الحق في ضمان سريتها، وأي استخدام أو استخدام تفاعلي
          تحتويه هذه المنصة لا يضمن أو لا يهدف إلى ضمان المستخدم أي الحقوق أو
          التراخيص أو أي امتيازات من أي نوع. إن تنازل منصة المركز السعودي لكفاءة
          الطاقة عن أي حق متاح لها والمحدد في هذه الشروط في أحد الأماكن أو
          الفعاليات لا يعني بأي حال من الأحوال تنازلاً تلقائياً ودائماً عن أي
          حقوق في أماكن ومناسبات أخرى.
        </p>
      </div>
      <div>
        <h3>تحديد المسؤولية</h3>
        <p>
          الخدمات الإلكترونية التي تقدمها منصة المركز السعودي لكفاءة الطاقة عبر
          الإنترنت والحصول على معلومات عن الدوائر والجهات الحكومية المختلفة يتم
          تقديمها فقط لتسهيل الإجراءات اليدوية، وبذلك فإنك تقر بعلمك الكامل بأن
          الاتصالات عبر الإنترنت قد تتعرض للتداخل أو الاعتراض من قبل الغير، وأن
          المنصة لا تقوم باستبدال المعلومات المتوفرة من خلال الجهات الرسمية،
          وأنه يمكن اتخاذ الطلبات والإجراءات الإدارية مباشرة أمام الجهات
          المختصة. ولذلك فإن اللجوء إلى هذه المنصة يبقى على مسؤوليتك الخاصة، ولن
          نكون مسؤولين بأي شكل من الأشكال عن أي خسارة أو ضرر من أي نوع قد تتكبده
          نتيجة استخدامك أو زيارتك للمنصة أو اعتمادك على أي بيان أو رأي. أو
          الإعلان في المنصة أو ما قد ينجم عن أي تأخير في التشغيل أو فشل الاتصال
          أو مشاكل في الوصول إلى شبكة الإنترنت أو أعطال في الأجهزة أو البرامج أو
          سلوك أو أفكار أي شخص يدخل إلى المنصة. هذه المنصة. أنت بموجب هذا تقر
          وتوافق على أن وسيلتك الحصرية والوحيدة لمعالجة أي ضرر أو خسارة قد تحدث
          نتيجة وصولك أو استخدامك لهذه المنصة هي الامتناع عن استخدامها أو الوصول
          إليها أو عدم الاستمرار في ذلك.
        </p>
      </div>
      <div>
        <h3>تعويض</h3>
        <p>
          بهذا تقر بعدم اتخاذ أي إجراء ضد البوابة الإلكترونية للمركز السعودي
          لكفاءة الطاقة أو أي من إدارتها وتعويضها وتأمينها من المسئولية وكذلك
          أية جهات أو موظفين أو وكلاء يكونون مسئولين عن إدارة أو صيانة أو تحديث
          أو تقديم البوابة الإلكترونية للمركز السعودي لكفاءة الطاقة، وذلك من
          كافة الالتزامات والمسئوليات التي قد تطرأ فيما يتصل بأية مطالبة تنشأ عن
          أي إخلال من جانبك ببنود وشروط الاستخدام أو أي من القوانين السارية سواء
          في المملكة العربية السعودية أو المكان الذي تقيم فيه.
        </p>
      </div>
      <div>
        <h3>إنهاء الاستخدام</h3>
        <p>
          يجوز لنا، وفقًا لتقديرنا المطلق، إنهاء أو تقييد أو تعليق حقك في الوصول
          إلى المنصة واستخدامها دون إشعار ولأي سبب، بما في ذلك انتهاك شروط
          وأحكام الاستخدام أو أي سلوك آخر قد نعتبره وفقًا لتقديرنا الخاص غير
          قانوني. أو ضارة بالآخرين، وفي الحالة الإنهاء، لن يسمح لك بالدخول إلى
          هذه المنصة.
        </p>
      </div>
      <div>
        <h3>حقوق الملكية</h3>
        <ol>
          <li>
            تخضع هذه المنصة للإشراف الفني من قبل إدارة تقنية المعلومات، وجميع
            المواد المتوفرة في هذه المنصة، بما في ذلك الرسومات المعلوماتية
            والبرمجيات (المحتويات)، محمية بموجب حقوق النشر والعلامات التجارية
            وغيرها من أشكال حقوق الملكية.
          </li>
          <li>
            بما أن أحد الأهداف الرئيسية للمنصة هو رفع مستوى الوعي وإثراء ثقافة
            مستخدمي وزوار المنصة، فإنه يسمح فقط للمستخدم الشخصي وللاستخدام غير
            الربحي بالاستفادة من محتوى المنصة وأي معلومات منشورة عليها .
          </li>
          <li>
            ومن ناحية أخرى، لا يجوز بأي شكل من الأشكال بيع أو ترخيص أو تأجير أو
            تعديل أو نسخ أو إعادة إنتاج أو إعادة طبع أو تنزيل أو الإعلان أو نقل
            أو توزيع أو العرض العلني أو تحرير أو إنشاء أعمال مشتقة من أي مواد أو
            محتويات هذه المادة. المنصة للعامة أو لأغراض تجارية دون الحصول على
            موافقة كتابية مسبقة من المركز السعودي لكفاءة الطاقة في المملكة
            العربية السعودية.
          </li>
          <li>
            يمنع منعا باتا إجراء أي تعديل على أي من محتويات المنصة. الرسومات
            والصور الموجودة على هذه المنصة محمية بموجب حقوق النشر ولا يجوز إعادة
            إنتاجها أو استغلالها بأي شكل من الأشكال دون الحصول على موافقة كتابية
            مسبقة من المركز السعودي لكفاءة الطاقة في المملكة العربية السعودية.
          </li>
        </ol>
      </div>
      <div>
        <h3>المرجع القضائي</h3>
        <p>
          بموجب هذا فإنك توافق على الخضوع حصرياً للسلطات القضائية في المملكة
          العربية السعودية فيما يتعلق بجميع المطالبات والنزاعات الناشئة عن
          استخدامك لهذه المنصة، مع ملاحظة أن اللغة العربية ستكون اللغة الرسمية
          المستخدمة لحل أي نزاعات تنشأ عن استخدامك للمنصة. المنصة أو أي من
          محتوياتها.
        </p>
      </div>
    </div>
  </div>
</div>
