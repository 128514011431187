import { Injectable } from '@angular/core';
import { Assets } from '@assets/images';

@Injectable({
  providedIn: 'root',
})
export class ApplicationDataService {
  constructor() {}
  private formData: { [key: string]: string } = {
    signatureImage: Assets.signature,
  };
  private initialFormData: { [key: string]: string } = {};
  private selectedOption: string;

  getFormData(): { [key: string]: any } {
    return { ...this.formData };
  }

  updateFormData(key: string, value: any): void {
    this.formData[key] = value;
  }

  setInitialFormData(initialFormData: { [key: string]: string }): void {
    this.initialFormData = { ...initialFormData };
  }

  getInitialFormData(): { [key: string]: string } {
    return { ...this.initialFormData };
  }

  getSignatureImage(): string {
    return this.formData['signatureImage'];
  }
}
