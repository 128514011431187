<div class="container">
  <loading-spinner></loading-spinner>
  <div *ngIf="tableData?.data" class="table-container">
    <app-table
      title="جميع إيصالات الطلبات"
      [headers]="headers"
      [data]="modifiedTableData"
      [options]="status"
      [isFinancialAuditor]="true"
      [totalRecords]="tableData?.totalRecords"
      [showEyeButtonColumn]="true"
      [isDateFilter]="true"
    >
    </app-table>
  </div>
</div>
