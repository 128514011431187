<div class="custom-dropdown" [ngClass]="customClass">
  <div class="dropdown-toggle" (click)="toggleDropdown()">
    <span>{{ selectedOption || selectorName }}</span>
    <div class="arrow-down">
      <app-svg-icon name="arrow-down" class="arrow-down"></app-svg-icon>
    </div>
  </div>
  <div class="dropdown-options" *ngIf="isDropdownOpen">
    <div
      class="option"
      *ngFor="let option of dropdownOptions"
      (click)="selectOption(option)"
    >
      {{ option.value }}
    </div>
  </div>
</div>
