<div [id]="id" class="container">
  <div class="label-container">
    <div class="file-label">
      {{ title }}
      <small *ngIf="isRequired">*</small>

      <svg
        *ngIf="!!descriptionLines.length"
        (click)="infoIconClickHandler()"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <use [attr.xlink:href]="'#info-icon'"></use>
      </svg>
      <ul class="subtitles-list" *ngIf="subtitles.length">
        <li *ngFor="let subtitle of subtitles">
          {{ subtitle }}
        </li>
      </ul>
    </div>

    <app-modal
      [modalContent]="modalContent"
      [showModal]="showModal"
      (closeModal)="closeModal()"
      [customClass]="'documents-modal'"
    >
      <div class="modal-header">
        <h2>{{ title }}</h2>
        <p class="popup-subtitle">{{ popupSubtitle }}</p>
      </div>
      <div class="modal-body">
        <ul>
          <li *ngFor="let descriptionLine of descriptionLines">
            {{ descriptionLine.value }}
          </li>
        </ul>
      </div>
    </app-modal>

    <div
      *ngIf="!readonly"
      [id]="id + '-input-container'"
      class="input-container"
    >
      <label [for]="id + '-input-field'">أرفق الملف</label>
      <input
        #fileInput
        [id]="id + '-input-field'"
        accept=".pdf"
        type="file"
        (change)="onFileSelect($event)"
        multiple
      />
    </div>
  </div>
  <div class="files-icons-wrapper">
    <div
      class="file-icons-list-container"
      [id]="id + '-file-list'"
      *ngIf="files?.length"
    >
      <ng-container *ngFor="let file of files; let i = index">
        <div
          *ngIf="
            file?.archivedFiles?.length || file.constructor.name === 'File'
          "
          class="compare-files-container"
          (click)="readonly ? onFileClick(documentLookup, i) : null"
        >
          <div class="file__container">
            <file-icon
              [readOnly]="readonly"
              [file]="file?.archivedFiles?.at(-1)?.file || file"
              [index]="i"
              showIcon="{{
                file?.fileSize ||
                  file?.size ||
                  file?.archivedFiles?.at(-1)?.file?.size ||
                  file?.archivedFiles?.at(-1)?.file?.fileSize
              }}"
              (removeFileEmmiter)="removeFile($event)"
              (uploadFileEmitter)="
                canReplaceFile ? replaceFile($event, i) : onFileSelect($event)
              "
              [canReplaceFile]="canReplaceFile"
              [haveFilesToReplace]="file?.archivedFiles?.length"
              [updateRequestNumber]="updateRequestNumber"
              [fileVersion]="file?.archivedFiles?.at(-1)?.fileVersion"
            ></file-icon>
            <file-icon
              *ngIf="file?.archivedFiles?.at(-2)?.file"
              class="shaded-file-icon"
              [readOnly]="true"
              [file]="file?.archivedFiles?.at(-2)?.file"
              [index]="i"
              showIcon="{{
                file?.fileSize ||
                  file?.size ||
                  file?.archivedFiles?.at(-2)?.file?.size ||
                  file?.archivedFiles?.at(-2)?.file?.fileSize
              }}"
              [canReplaceFile]="false"
              [haveFilesToReplace]="file?.archivedFiles?.length"
              [updateRequestNumber]="updateRequestNumber"
              [fileVersion]="file?.archivedFiles?.at(-2)?.fileVersion"
            ></file-icon>
          </div></div
      ></ng-container>
    </div>
  </div>
  <small *ngIf="viewErrorMessage" [id]="id + '-file-upload-error-message'">{{
    errorMessage
  }}</small>
  <ng-content></ng-content>
</div>
