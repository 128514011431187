import { Component, OnInit } from '@angular/core';
import { ScrollToTopService } from '@app/dashboard/services/scroll-to-top.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrl: './faq.component.scss',
})
export class FaqComponent implements OnInit {
  public logoSvg = 'assets/images/logo.svg';

  constructor(private scrollService: ScrollToTopService) {}

  ngOnInit(): void {
    this.scrollService.scrollToTop();
  }

  faqContent = [
    {
      question:
        'في حال تكرار البرامج في الاعتماد، ماهي الآلية للتعامل مع تكرار البرامج التي تطلب الاعتماد؟',
      answer:
        ' من الممكن اعتماد أكثر من برنامج بنفس المواضيع حيث أنه لا علاقة لها ببعض وأن المنافسة تكون بناء على معايير أخرى.',
    },
    {
      question: 'هل برامج الأنظمة الشمسية تندرج تحت كفاءة الطاقة؟',
      answer:
        'بعض برامج الأنظمة الشمسية تندرج تحت مظلة كفاءة الطاقة، القرار يتضح بعد المراجعة الأولية.',
    },
    {
      question:
        'هل اعتماد البرامج التدريبية خاص بالمراكز والجهات التدريبية ام ان الافراد يستطيعون التقديم للحصول على الاعتماد؟',
      answer: 'خاص بالمراكز والجهات المرخص لها من قبل الجهات المعنية.',
    },
    {
      question: 'ما هي آلية تجديد الرخصة قبل انتهاء رخصة البرنامج ب 3 أشهر؟ ',
      answer: 'تقديم طلب تجديد اعتماد.',
    },
    {
      question: 'ماهي آلية تجديد رخصة البرنامج بعد مضي 3 سنوات؟',
      answer: ' يعاد تقديم طلب الاعتماد.',
    },
    {
      question:
        'ماهي كيفية تعليق الرخصة بناء على رغبة مقدم الخدمة؟ وما هي اقصى مدة يمكن للجهة تعليق الرخصة؟',
      answer: {
        main: 'للمركز تعليق الاعتماد بناءً على طلب مقدم الخدمة، ويراعى قبل ذلك قيامه بتنفيذ كافة التزاماته القائمة.',
        points: [
          'تكون مدة تعليق الاعتماد الصادر من المركز للبرنامج التدريبي في مجال كفاءة مؤقته.',
          'يكون طلب التعليق مسبباً ويتم تقييمه من قبل اللجنة.',
        ],
      },
    },
  ];
}
