<div class="container">
  <div class="header">
    <div class="header flex">
      <div class="flex next-flex">
        <app-page-title
          [title]="'طلب رقم '"
          [customClass]="['title-container', 'black-color']"
        ></app-page-title>
        <app-page-title
          [title]="'#' + reportId"
          [customClass]="['title-container', 'green-color']"
        ></app-page-title>
      </div>
    </div>
  </div>
  <app-breadcrumbs [levels]="levels"></app-breadcrumbs>
  <div class="main-container">
    <app-page-title
      [title]="'recieptDetails'"
      [customClass]="['application-details']"
    ></app-page-title>
    <div class="new-section">
      <p class="title">
        {{ 'checkReciept' | translate }}
      </p>
    </div>

    <div class="new-section-border">
      <app-page-title
        [title]="'bill'"
        [customClass]="['application-details']"
      ></app-page-title>
      <div class="flex">
        <p class="payment-instruction-text">
          طلب إعتماد برنامج {{ invoiceData?.organizationName }}
        </p>
        <p class="payment-instruction-text amount">
          {{ invoiceData?.totalAmount }}

          <span class="green"> SAR </span>
        </p>
      </div>
      <div class="divider"></div>
      <div class="total-amount flex">
        <p>مجموع المبلغ</p>
        <p class="amount">
          {{ invoiceData?.totalAmount }}
          <span class="green"> SAR </span>
        </p>
      </div>
    </div>
    <div class="file-attachments">
      <div>
        <p class="payment-instruction-text">{{ 'theReciept' | translate }}</p>

        <div class="files-container">
          <div
            class="file-item"
            *ngFor="let item of invoiceData?.trainingOrganizationInvoiceFiles"
          >
            <div class="file-item__container">
              <img [src]="file" (click)="openReceipt(item.file)" />
              <div class="file-info" (click)="openReceipt(item.file)">
                <span class="file-name">{{ item?.file['fileName'] }}</span>
                <span class="file-size">{{ item?.file['fileSize'] }}</span>
              </div>
              <file-download [files]="[item?.file]" [hasText]="false" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="general-notes-container">
      <check-box
        label="إضافة ملاحظات"
        [control]="notesCheckBoxControl"
      ></check-box>
      <app-rich-text-editor
        *ngIf="notesCheckBoxControl.value"
        [control]="financialAuditorNoteControl"
        (textValueEmitter)="generalNoteText = $event"
      />
    </div>
    <div class="buttons-container">
      <app-custom-button
        [disabled]="isButtonsDisabled"
        buttonText="اعتماد"
        buttonStyle="greenButton"
        (click)="updateAppStatus(false)"
      />
      <app-custom-button
        [disabled]="isButtonsDisabled"
        buttonText="إعادة إرسال للجهة التدريبية"
        buttonStyle="transperantGreenButton"
        (click)="updateAppStatus(true)"
      />
    </div>
    <div class="success-modal__container">
      <app-modal
        [modalContent]="'submission'"
        [showModal]="showModal"
        (closeModal)="closeModal()"
        [customClass]="'success-modal'"
        [isCloseVisible]="!(isLoading | async)"
      >
        <loading-spinner>{{
          returnedToTrainingOrganization
            ? 'جاري إعادة الطلب...'
            : 'جاري إرسال الطلب...'
        }}</loading-spinner>
        <div *ngIf="submittedSuccessfully" class="modal-body">
          <app-svg-icon class="success-icon" name="success-icon"></app-svg-icon>
          <span class="modal-text">{{
            returnedToTrainingOrganization
              ? 'تم إعادة الطلب إلى الجهة التدريبية'
              : 'تم اعتماد الطلب و إرساله إلى أمين اللجنة'
          }}</span>
        </div>
      </app-modal>
    </div>
    <div class="success-modal__container">
      <app-modal
        [modalContent]="'information'"
        customClass="success-receipt-modal"
        [showModal]="showReceiptPreview"
        (closeModal)="closePreview()"
        [isCloseVisible]="!(isLoading | async)"
      >
        <loading-spinner>{{ 'جاري تحميل الايصال...' }}</loading-spinner>
        <div class="modal-body" *ngIf="receiptPreview">
          <img [src]="receiptPreview" />
        </div>
      </app-modal>
    </div>
  </div>
</div>
