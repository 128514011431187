import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ArabicNumbersService {
  numbers = {
    1: 'الأولى',
    2: 'الثانية',
    3: 'الثالثة',
    4: 'الرابعة',
    5: 'الخامسة',
    6: 'السادسة',
    7: 'السابعة',
    8: 'الثامنة',
    9: 'التاسعة',
    10: 'العاشرة',
  };

  getNumbersInArabicText(number: number) {
    return this.numbers[number];
  }
}
