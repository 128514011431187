import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-thread',
  templateUrl: './thread.component.html',
  styleUrl: './thread.component.scss',
})
export class ThreadComponent implements OnInit {
  @Input() readonly: boolean = false;
  @Input() threadData: any;
  @Input() trainingOrganizationId: number;
  @Input() index: number;
  @Input() onCommentSubmit: (
    isReply: boolean,
    discussionBoardThreadId: number,
    replyText: string,
  ) => Promise<void>;

  @ViewChild('parentComment') parentComment: any;

  replyControl: FormControl;
  openedReplyTextField: boolean = false;
  isSaving: BehaviorSubject<boolean>;

  ngOnInit(): void {
    if (!this.readonly) {
      this.replyControl = new FormControl('', {
        validators: Validators.required,
      });
      this.isSaving = new BehaviorSubject<boolean>(false);
    }
  }

  async submitReply() {
    if (this.replyControl.invalid) return;

    this.isSaving.next(true);
    await this.onCommentSubmit(
      true,
      this.threadData.id,
      this.replyControl.value,
    );
    this.replyControl.reset();
    this.openedReplyTextField = false;
    this.isSaving.next(false);
  }

  handleShowReplies() {
    this.openedReplyTextField = !this.openedReplyTextField;
    if (!this.openedReplyTextField) {
      const commentELement = this.parentComment.nativeElement as HTMLElement;
      const isCommentAboveViewPort =
        commentELement.getBoundingClientRect().top < 0;
      if (isCommentAboveViewPort)
        commentELement.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
    }
  }
}
