import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuditService } from '@app/dashboard/services/audit.service';
import { LookupsTypeEnum } from '@app/dashboard/types/lookUps';
import { AuthService } from 'src/app/services/auth.service';
import { UserRole } from 'src/app/types/roles';

export interface Headers {
  name: string;
  value: string;
}

@Component({
  selector: 'app-my-applications',
  templateUrl: './my-applications.component.html',
  styleUrls: ['./my-applications.component.scss'],
})
export class MyApplicationsComponent implements OnInit {
  headers: Headers[] = [
    { name: 'رقم الطلب', value: 'reportId' },
    { name: 'نوع الطلب', value: 'requestTypeStr' },
    { name: 'تاريخ الطلب', value: 'creationDate' },
    { name: 'حالة الطلب', value: 'trainingOrganizationStatusStr' },
  ];

  applicationTypeOptions: string[] = [];

  status: string[] = [];
  language: string[] = [];
  tableData: any;

  constructor(
    private router: Router,
    private auditService: AuditService,
    private authService: AuthService,
  ) {}
  isUserTrainingOrganization: boolean = this.authService.hasRole(
    UserRole.TrainingOrganization,
  );

  getPageTitle(): string {
    return this.isUserTrainingOrganization
      ? 'myApplications'
      : 'accreditationRequest';
  }

  ngOnInit(): void {
    this.getTableData();
    this.getFilterData(
      LookupsTypeEnum.RequestTypeEnum,
      this.applicationTypeOptions,
    );
    this.getFilterData(
      LookupsTypeEnum.TrainingOrganizationStatusEnum,
      this.status,
    );
    this.getFilterData(LookupsTypeEnum.LanguageEnum, this.language);
  }

  getFilterData(lookup: number, filterValue: string[]) {
    this.auditService.getFiltersData(lookup).subscribe({
      next: (data) => {
        filterValue.length = 0; // Clear the existing array
        Array.prototype.push.apply(filterValue, data.data); // Add new elements to the array
      },
      error: (error) => {
        console.error('Error fetching data:', error);
      },
    });
  }

  getTableData() {
    const body = {
      pageCount: 1,
      pageSize: 10,
      allowSearch: false,
      reportId: '',
      organizationName: '',
      fromDate: '',
      toDate: '',
      languageTypeId: '',
      trainingOrganizationStatusId: 1,
    };

    this.auditService.getOrgFilteredData(body).subscribe({
      next: (data) => {
        this.tableData = data.data;
      },
      error: (error) => {
        console.log('error', error);
      },
    });
  }

  redirectToPage() {
    this.router.navigate(['/dashboard/application-details']);
  }
}
