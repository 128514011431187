<div class="container">
    <div class="header">
        <div class="header flex">
            <div class="flex next-flex">
                <app-page-title [title]="'applicationNumber'" [customClass]="'application-number__title'"><span>{{
                        requestData?.trainingOrganization?.reportId
                        }}</span></app-page-title>
            </div>
        </div>
    </div>
    <app-breadcrumbs [levels]="levels"></app-breadcrumbs>
    <loading-spinner></loading-spinner>
    <certificate *ngIf="!(isLoading$ | async)" [certificateData]="certificateData"></certificate>
</div>