import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Assets } from '@assets/images';
import { AuditService } from '@app/dashboard/services/audit.service';
import { Status } from '@app/dashboard/types/status';
import { LoadingService } from 'src/app/services/loading.service';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { DocumentNoteType } from '@app/dashboard/types/documentNote.type';
import { BodyScrollService } from '@app/dashboard/services/body-scroll.service';

@Component({
  selector: 'app-payment-receipt-details',
  templateUrl: './payment-receipt-details.component.html',
  styleUrl: './payment-receipt-details.component.scss',
})
export class PaymentReceiptDetailsComponent implements OnInit {
  paymentData: any;
  image: string;
  imagesArr = [];
  file = Assets.pdf;
  reportId: string;
  invoiceId: number;
  organizationId: number;
  invoiceData: any;
  showModal: boolean = false;
  submittedSuccessfully: boolean = false;
  fileImageSrc: string = '';
  isSubmitButtonClicked: boolean = false;
  returnedToTrainingOrganization: boolean = false;
  notesCheckBoxControl = new FormControl(false);
  financialAuditorNoteControl = new FormControl('');
  generalNoteText = '';
  receiptPreview = '';
  showReceiptPreview = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private auditService: AuditService,
    private loadingService: LoadingService,
    private bodyScrollService: BodyScrollService,
  ) {
    bodyScrollService.modalScrollEnabled.subscribe((enableScroll) => {
      if (enableScroll) {
        document.body.classList.remove('modal-open');
      } else {
        document.body.classList.add('modal-open');
      }
    });
  }

  isLoading: Observable<boolean> = this.loadingService.isLoading$();

  getImagesArr(arr: any) {
    this.imagesArr = arr;
  }

  removeImage(index: number) {
    this.imagesArr.splice(index, 1);
  }

  levels = [
    {
      name: 'dashboard',
      path: '/dashboard',
    },
    {
      name: 'paymenteRecieptDetails',
      path: '',
    },
  ];

  ngOnInit(): void {
    this.invoiceId = this.route.snapshot.params['id'];
    this.reportId = this.route.snapshot.queryParams['reportId'];
    this.organizationId = this.route.snapshot.queryParams['organizationId'];
    this.getData();
  }

  closeModal() {
    if (this.submittedSuccessfully) {
      this.router.navigate(['/dashboard']);
    }
    this.showModal = false;
    this.submittedSuccessfully = false;
  }

  getData() {
    this.auditService.getInvoiceData(this.invoiceId).subscribe(
      (data) => {
        this.organizationId = data.data?.trainingOrganizationId;
        this.invoiceData = data.data;
      },
      (error) => {
        console.log('error', error);
      },
    );
  }

  get isButtonsDisabled() {
    return (
      this.isSubmitButtonClicked ||
      (this.notesCheckBoxControl.value && !this.generalNoteText)
    );
  }

  updateAppStatus(returnToTrainingOrg: boolean) {
    if (this.isButtonsDisabled) return;
    this.isSubmitButtonClicked = true;
    this.bodyScrollService.toggleBodyScroll(false);

    if (returnToTrainingOrg) this.returnedToTrainingOrganization = true;

    const body = {
      TrainingOrganizationStatusEnum: returnToTrainingOrg
        ? Status.RejectedByFinancialAuditorToTrainingOrganization
        : Status.ApprovedByTheFinancialAuditorWaitingCommitteeSecretary,
      TrainingOrganizationId: this.organizationId,
      HasNotes: this.notesCheckBoxControl.value,
      ...(this.notesCheckBoxControl.value && {
        TrainingProgramDocumentNotes: [
          {
            id: '',
            note: this.financialAuditorNoteControl?.value,
            documentNoteTypeId: DocumentNoteType.Invoice,
          },
        ],
      }),
    };
    this.showModal = true;
    this.loadingService.startLoading();
    this.auditService.updateApplicationStatus(body).subscribe({
      next: () => {
        this.loadingService.stopLoading();
        this.submittedSuccessfully = true;
      },
      error: (error) => {
        console.log('Error sending the data', error);
      },
    });
  }
  openReceipt(file) {
    this.showReceiptPreview = true;
    this.loadingService.startLoading();
    document.body.style.overflow = 'hidden';
    this.auditService.getFile(file.id ?? file.fileId).subscribe({
      next: (res) => {
        console.log(res);
        this.receiptPreview = res;
        this.loadingService.stopLoading();
      },
    });
  }
  closePreview() {
    this.receiptPreview = '';
    this.showReceiptPreview = false;
    document.body.style.overflow = 'auto';
  }
}
