<accordion header="لوحة النقاش">
  <div
    class="board-container"
    *ngFor="let board of previousDiscussionBoards; let last = last"
  >
    <div class="board-note-container">
      <app-page-title
        [title]="boardStageArabicText(board.discussionBoardVersion)"
        customClass="general-note-title"
      />
      <div
        class="board-note"
        *ngIf="board.committeeSecretaryNote?.note"
        [innerHTML]="board.committeeSecretaryNote?.note"
      ></div>
      <div
        class="board-note"
        *ngIf="!board.committeeSecretaryNote?.note"
        [innerHTML]="noNotesHTML"
      ></div>
    </div>
    <div *ngIf="board?.discussionBoardThreads.length; else noThreads">
      <div *ngFor="let comment of board?.discussionBoardThreads">
        <app-thread [threadData]="comment" [readonly]="true" />
      </div>
    </div>
    <ng-template #noThreads>
      <p class="empty-threads">لا توجد رسائل</p>
    </ng-template>

    <hr class="divider" *ngIf="!last" />
  </div>
</accordion>
