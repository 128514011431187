import { Component } from '@angular/core';
import { GetLookupsService } from '@app/get-look-ups.service';
import { LookupsTypeEnum } from '@app/dashboard/types/lookUps';
import { AuditService } from '@app/dashboard/services/audit.service';

@Component({
  selector: 'app-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrl: './profile-page.component.scss',
})
export class ProfilePageComponent {
  userId = localStorage.getItem('userId');
  cities = [];
  breadCrumbsLevels = [];
  isEditMode = false;
  isEditPicOpen: boolean = false;
  isPicChanged: boolean = false;
  profilePicture: ProfilePicture = null;
  profilePictureUrl = '';
  profileData = {
    id: 0,
    coordinatorName: '',
    organizationName: '',
    phoneNumber: '',
    email: '',
    coordinatorEmail: '',
    address: '',
    cityId: 0,
    city: '',
    trainingLicenseNumber: 0,
    licenseDate: '',
    userId: '',
    profilePictureId: null,
    profilePicture: null,
  };

  constructor(
    private dropdownsOptionsService: GetLookupsService,
    private auditService: AuditService,
  ) {}
  ngOnInit() {
    this.loadProfileData();
    this.loadCitiesOptions();
  }

  onSelectCity(event) {
    this.profileData.city = event.value;
    this.profileData.cityId = event.key;
  }

  onSelectLicenseDate(date) {
    this.profileData.licenseDate = date.startDate.toLocaleDateString('en-GB');
  }

  onUploadPicture(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (!input.files[0].type.includes('image')) return;

    const file = input.files[0];
    const reader = new FileReader();

    reader.onload = (e: ProgressEvent<FileReader>) => {
      this.profilePictureUrl = e.target?.result as string;
    };
    this.profileData.profilePicture = {
      file: file,
      profilePictureId: !this.profileData.profilePictureId
        ? 0
        : this.profileData.profilePictureId,
    };
    reader.readAsDataURL(file);
    this.isEditPicOpen = false;
  }

  isValidEmail(email: string): boolean {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  }
  onSubmit() {
    if (this.isSubmitDisabled()) return;
    const formData = new FormData();

    Object.entries(this.profileData).forEach(([key, value]) => {
      if (key === 'profilePicture' && value) {
        if (value.file) {
          formData.set('profilePicture.file', value.file);
          formData.set(
            'profilePicture.profilePictureId',
            String(value.profilePictureId),
          );
        }
      } else if (key === 'profilePictureId') {
        if (this.isPicChanged) {
          formData.set('profilePicture.profilePictureId', String(value));
        }
      } else {
        formData.set(key, String(value));
      }
    });

    this.auditService.createOrUpdateProfilePage(formData).subscribe({
      next: (response) => {
        this.profileData = response.data;
        !response.data.profilePictureId
          ? (this.profilePictureUrl = 'assets/images/human-placeholder.png')
          : this.loadProfilePicture();
        console.log('Response received:', response);
      },
      error: (error) => {
        console.error('Error occurred:', error);
      },
    });

    this.isEditMode = false;
    this.isPicChanged = false;
  }

  onCancel() {
    this.loadProfileData();
    this.isEditMode = false;
    this.profilePictureUrl = '';
    this.isEditPicOpen = false;
  }

  getBreadCrumbsLevels() {
    return [
      {
        name: 'الرئيسية',
        path: '/',
      },
      {
        name: 'الصفحة الشخصية',
        path: '',
      },
    ];
  }

  loadProfileData() {
    this.auditService.getProfilePage().subscribe({
      next: (res: any) => {
        this.profileData = res.data;
        !res.data.profilePictureId
          ? (this.profilePictureUrl = 'assets/images/human-placeholder.png')
          : this.loadProfilePicture();
      },
      error: (err) => {
        console.error('Error loading profile data:', err);
      },
    });
  }

  loadCitiesOptions() {
    this.dropdownsOptionsService
      .getOptions(LookupsTypeEnum.CitiesEnum)
      .subscribe((res) => {
        this.cities = res.data;
      });
  }

  loadProfilePicture() {
    this.auditService.getFile(this.profileData.profilePicture.id).subscribe({
      next: (res) => {
        this.profilePictureUrl = res;
      },
    });
  }

  onClickEditPicture() {
    if (this.isEditPicOpen || this.profilePictureUrl.includes('assets')) {
      document.getElementById('profile-picture-input').click();
    } else {
      this.isEditPicOpen = true;
    }
  }

  onRemoveProfilePicture() {
    this.profilePictureUrl = 'assets/images/human-placeholder.png';
    this.profileData.profilePicture = {
      file: null,
      profilePictureId: this.profileData.profilePictureId,
    };
    this.isEditPicOpen = false;
    this.isPicChanged = true;
  }
  isSubmitDisabled() {
    return (
      !this.profileData.organizationName ||
      !this.profileData.coordinatorName ||
      !this.profileData.phoneNumber ||
      !this.profileData.coordinatorEmail ||
      !this.profileData.address ||
      !this.profileData.cityId ||
      !this.profileData.city ||
      !this.profileData.trainingLicenseNumber ||
      !this.profileData.licenseDate ||
      !this.profileData.email ||
      !this.isValidEmail(this.profileData.email) ||
      !this.isValidEmail(this.profileData.coordinatorEmail)
    );
  }
}
export interface ProfilePicture {
  userProfileId: number;
  profilePictureId: number;
  fileId: string;
  file: File;
}
