import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-training-body-data',
  templateUrl: './training-body-data.component.html',
  styleUrl: './training-body-data.component.scss',
})
export class TrainingBodyDataComponent {
  @Input() trainingOrganization: [];
}
