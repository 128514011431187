<div class="application-details__container">
  <div class="application-details__header">
    <app-page-title
      *ngIf="!isCommitteeSecretaryNoteLayout"
      [title]="'applicationNumber'"
      [customClass]="'application-number__title'"
      ><span>{{
        requestData?.trainingOrganization?.reportId
      }}</span></app-page-title
    >
    <app-page-title
      *ngIf="isCommitteeSecretaryNoteLayout"
      title="ملاحظات الأمين"
      [customClass]="'black-color'"
    />
    <div class="breadcrumbs-container">
      <app-breadcrumbs [levels]="levels" />
      <app-custom-button
        *ngIf="isGeneratedFormButtonVisible"
        customClass="transperantGreenButton"
        buttonText="secretary-form"
        (click)="navigateToSecretaryForm()"
      />
    </div>
  </div>
  <loading-spinner />
  <app-application-details-user-role
    (showCommitteeSecretaryNoteChange)="showCommitteeSecretaryNote($event)"
    [(showCommitteeSecretaryNote)]="isCommitteeSecretaryNoteLayout"
    [requestData]="requestData"
    *ngIf="requestData?.trainingProgramDocuments && isInternalAuditor()"
    [title]="'معلومات الجهة'"
  />
  <order-details *ngIf="showRequestDetails" [requestData]="requestData" />
  <expert-application-view
    (showCommitteeSecretaryNoteChange)="showCommitteeSecretaryNote($event)"
    [(showCommitteeSecretaryNote)]="isCommitteeSecretaryNoteLayout"
    [id]="id"
    *ngIf="requestData?.trainingProgramDocuments && isTechnicalAdvisor()"
    [requestData]="requestData"
  />
</div>
