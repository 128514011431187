import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
  forgotPassword: FormGroup;
  errorMessage: string = '';
  submitButtonIsDisabled: boolean = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
  ) {
    this.forgotPassword = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  onSubmit() {
    if (this.forgotPassword.valid) {
      this.submitButtonIsDisabled = true;
      const email = this.forgotPassword.get('email').value;
      this.authService.sendOtp(email).subscribe({
        next: (response) => {
          if (response.errorCode === 0) {
            localStorage.setItem('otp_email', email);
            this.router.navigate(['/verify-otp']);
          }
          if (response.errorCode === 4) {
            this.errorMessage = 'البريد الإلكتروني غير مستخدم.';
            this.submitButtonIsDisabled = false;
          }
        },
      });
    }
  }
}
