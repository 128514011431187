import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class VotingService {
  private getUrl: string = `${environment.apiUrl}/api/Commissioner/GetTrainingOrgCommissioners?trainingOrganizationId=`;
  private postUrl: string = `${environment.apiUrl}/api/Commissioner/CreateOrUpdateCommissionerVote`;
  constructor(private http: HttpClient) {}

  getVotesData(trainingOrganizationId: number): Observable<any> {
    const response = this.http.get<any>(this.getUrl + trainingOrganizationId);
    return response;
  }

  sendVoteData(
    trainingOrganizationId: number,
    commissionerVoteStatusId: number,
    note: string,
    id?: number,
  ) {
    const requestBody = {
      id,
      commissionerVoteStatusId,
      note,
      trainingOrganizationId,
    };
    const response = this.http.post<any>(this.postUrl, requestBody);
    return response;
  }

  getApplicationDiscussionBoard(id: number | string) {
    return this.http.get<any>(
      `${environment.apiUrl}/api/DiscussionBoard/GetApplicationDiscussionBoards/${id}`,
    );
  }

  submitDiscussionBoard(requestBody: any) {
    return this.http.post<any>(
      `${environment.apiUrl}/api/DiscussionBoard/CreateOrUpdateDiscussionBoardThread`,
      requestBody,
    );
  }

  getExecutiveSummary(id: number | string) {
    return this.http.get<any>(
      `${environment.apiUrl}/api/DiscussionBoard/GetExecutiveSummary/${id}`,
    );
  }

  submitCommitteeSecretaryNote(requestBody: any) {
    return this.http.post<any>(
      `${environment.apiUrl}/api/DiscussionBoard/CreateOrUpdateCommitteeSecretaryNote`,
      requestBody,
    );
  }

  getVotingResult(id: number | string) {
    return this.http.get<any>(
      environment.apiUrl + `/api/Commissioner/GetVotingResult/${id}`,
    );
  }
}
