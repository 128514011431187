<div class="container">
  <div *ngIf="programTitleTextHandler">
    <app-page-title
      [title]="programTitleTextHandler"
      [customClass]="'application-number__title'"
      ><span class="green-color">{{ votingResultData?.reportId }}</span
      >)</app-page-title
    >
    <app-page-title
      [title]="votingResultData?.organizationName"
      [customClass]="'trainingOrgName__title'"
    />
    <div class="breadcrumbs-container">
      <app-breadcrumbs [levels]="levels"></app-breadcrumbs>
    </div>
  </div>
  <loading-spinner />
  <section class="secretary-form-container" *ngIf="votingResultData">
    <div class="title-container">
      <app-page-title
        title="secretary-form"
        [customClass]="'application-details'"
      />
      <app-custom-button
        buttonStyle="greenButton"
        buttonText="تنزيل النموذج"
        (click)="downloadForm()"
        [disabled]="isDownloadButtonDisabled"
      >
        <app-svg-icon name="download-icon" class="download-icon" />
      </app-custom-button>
    </div>

    <article class="secretary-form" #secretaryForm>
      <img class="logo-svg" [src]="logoSvg" alt="SEEC Logo" />
      <div class="horizontal-gradient-line"></div>
      <p class="secretary-form__text">
        إشارةً إلى قرار مجلس إدارة المركز السعودي لكفاءة الطاقة رقم (02-03-2022)
        وتاريخ (01/04/1444 هـ)، الموافق (26/10/2022 م)، باعتماد لائحة اعتماد
        البرامج التدريبية في مجال كفاءة الطاقة وقواعد عمل اللجنة، قررت " لجنة
        اعتماد البرامج التدريبية في مجال كفاءة الطاقة"  الموافقة على منح اعتماد
        للبرنامج التدريبي أدناه: 
      </p>
      <div class="horizontal-gradient-line"></div>
      <table class="info-table">
        <thead>
          <tr>
            <th colspan="3" class="header">تصويت لجنة الإعتماد</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowspan="5" class="vote-result">نتيجة التصويت</td>
            <td style="width: 30%">التاريخ</td>
            <td style="width: 30%">{{ reversedDate }}</td>
          </tr>
          <tr>
            <td colspan="2">
              {{ votingResultData?.reportId }}
            </td>
          </tr>
          <tr>
            <td colspan="2">
              {{ votingResultData?.organizationName }}
            </td>
          </tr>
          <tr>
            <td colspan="2">
              {{ votingResultData?.trainingProgramNameAr }}
            </td>
          </tr>
          <tr>
            <td colspan="2" class="passed-td">معتمد</td>
          </tr>
        </tbody>
      </table>
      <div class="horizontal-gradient-line last"></div>
      <p-table
        [value]="votesTableData"
        *ngIf="votesTableData"
        class="votes-table"
      >
        <ng-template pTemplate="header">
          <tr class="votes-header">
            <th>#</th>
            <th>العضو</th>
            <th>الصفة باللجنة</th>
            <th>الجهة</th>
            <th>نتيجة التصويت</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-vote let-index="rowIndex">
          <tr>
            <td>{{ index + 1 }}</td>
            <td>{{ vote.userName }}</td>
            <td>{{ vote.roleStr }}</td>
            <td>{{ userAgency[index] }}</td>
            <td
              [class]="
                vote.voteStatus === votesEnum.Accepted ? 'accepted' : 'rejected'
              "
            >
              {{ vote.voteStr }}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </article>
    <app-custom-button
      buttonText="العودة إلى تفاصيل الطلب"
      buttonStyle="greenButton"
      (click)="navigateToApplicationDetails()"
    />
  </section>
</div>
