<div class="field-visit-row" [formGroup]="formGroupControl">
  <text-field
    [control]="criteriaControl"
    class="field-visit-criteria"
    [placeholder]="'المعيار'"
  ></text-field>
  <div class="field-visit-evaluation">
    <input
      type="radio"
      [name]="'radio_' + criteriaIndex"
      [id]="'radio_' + criteriaIndex + '_true'"
      [value]="true"
      [formControl]="radioControl"
    />
    <label [for]="'radio_' + criteriaIndex + '_true'">ينطبق</label>
    <input
      type="radio"
      [name]="'radio_' + criteriaIndex"
      [id]="'radio_' + criteriaIndex + '_false'"
      [value]="false"
      [formControl]="radioControl"
    />
    <label [for]="'radio_' + criteriaIndex + '_false'">لا ينطبق</label>
  </div>
  <text-field
    [control]="noteControl"
    class="field-visit-criteria-notes"
    [placeholder]="'الملاحظات'"
  ></text-field>
  <img
    src="assets/images/clear-criteria.svg"
    class="clear-criteria-button"
    (click)="clearCriteria()"
  />
  <small *ngIf="errors?.['criteriaRequired']" class="criteria">{{
    errorMessageTextHandler()
  }}</small>
  <small *ngIf="errors?.['applicableRequired']" class="applicable">{{
    errorMessageTextHandler()
  }}</small>
  <small *ngIf="errors?.['missingNote']" class="note">{{
    errorMessageTextHandler()
  }}</small>

  <div class="failed-criteria-file" *ngIf="radioControl.value === false">
    <div *ngIf="!fileControl.value?.length" class="file-input_placeholder">
      إرفاق ملف
    </div>

    <div class="files-icons-wrapper" *ngIf="fileControl?.value?.length">
      <div class="file-icons-list-container">
        <ng-container *ngFor="let file of fileControl?.value; let i = index">
          <div *ngIf="file.constructor.name === 'File'" class="files-container">
            <div class="file__container">
              <file-icon
                [file]="file"
                [index]="i"
                showIcon="{{ file?.size }}"
                (removeFileEmmiter)="removeFile($event)"
              ></file-icon>
            </div></div
        ></ng-container>
      </div>
    </div>

    <div class="file-input_container">
      <label [for]="'file_' + criteriaIndex" class="file-input-label"
        ><img src="assets/images/attachment.svg"
      /></label>
      <input
        class="file-input"
        type="file"
        name="file"
        [id]="'file_' + criteriaIndex"
        (change)="onFileUpload($event)"
        multiple
      />
    </div>
  </div>
  <small *ngIf="errors?.['missingFile']" class="file">{{
    errorMessageTextHandler()
  }}</small>
</div>
