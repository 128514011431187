<div class="flex container">
  <input
    #fileInput
    type="file"
    (change)="onImageSelected($event)"
    accept="image/*"
    id="customFileInput"
    [disabled]="selectedFile"
  />
  <label
    for="customFileInput"
    [ngClass]="{ disabled: selectedFile, 'custom-file-label': true }"
    >{{ title }}</label
  >
</div>
