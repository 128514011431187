import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/components/shared.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { TabViewModule } from 'primeng/tabview';
import { InputTextModule } from 'primeng/inputtext';
import { KeyFilterModule } from 'primeng/keyfilter';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CheckboxModule } from 'primeng/checkbox';
import { AccordionModule } from 'primeng/accordion';
import { MenuModule } from 'primeng/menu';
import { EditorModule } from 'primeng/editor';

import { UtilityIconsComponent } from './utility-icons/utility-icons.component';
import { ProgramCardComponent } from './program-card/program-card.component';
import { SidebarTabsComponent } from './sidebar-tabs/sidebar-tabs.component';
import { CardsComponent } from './cards/cards.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { CustomDropdownComponent } from './custom-dropdown/custom-dropdown.component';
import { PageTitleComponent } from './page-title/page-title.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { FormTabsComponent } from './form-tabs/form-tabs.component';
import { Accordion } from './accordion/accordion.component';
import { FormInputComponent } from './form-input/form-input.component';
import { ModalComponent } from './modal/modal.component';
import { TabsComponent } from './tabs/tabs.component';
import { NavigationButtonsComponent } from './navigation-buttons/navigation-buttons.component';
import { SearchBarAuditComponent } from './search-bar-audit/search-bar-audit.component';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';
import { DialogModule } from 'primeng/dialog';

import { CountdownTimerComponent } from './countdown-timer/countdown-timer.component';
import { PaginationComponent } from './pagination/pagination.component';
import { DialogComponent } from './dialog/dialog.component';
import { CommentSectionComponent } from './comment-section/comment-section.component';
import { CustomAccordionComponent } from './custom-accordion/custom-accordion.component';
import { DocumentInspectorComponent } from './document-inspector/document-inspector.component';
import { TextField } from './text-field/text-field.component';
import { RichTextEditorComponent } from './rich-text-editor/rich-text-editor.component';
import { Dropdown } from './dropdown/dropdown.component';
import { DatePicker } from './date-picker/date-picker.component';
import { TextArea } from './text-area/text-area.component';
import { Checkbox } from './checkbox/checkbox.component';
import { FileIcon } from './file-icon/file-icon.component';
import { FileUpload } from './file-upload/file-upload.component';
import { TableComponent } from './table/table.component';
import { LoadingSpinner } from 'src/app/components/loading-spinner/loading-spinner.component';
import { TrainingBodyDataComponent } from './training-body-data/training-body-data.component';
import { CertificateComponent } from '../certificate/certificate.component';
import { FileDownloadComponent } from './file-download/file-download.component';
import { GeneralNoteComponent } from '../general-note/general-note.component';
import { QRCodeModule } from 'angularx-qrcode';
import { ArchivedFilesDialogComponent } from './archived-files-dialog/archived-files-dialog.component';
import { FieldVisitCriteriaComponent } from './field-visit-criteria/field-visit-criteria.component';
import { FieldVisitWrapperComponent } from '../field-visit-wrapper/field-visit-wrapper.component';
import { ViewFieldVisitComponent } from '../view-field-visit/view-field-visit.component';
import { DiscussionBoardComponent } from './discussion-board/discussion-board.component';
import { ThreadComponent } from './thread/thread.component';
import { DiscussionBoardAccordionComponent } from './discussion-board-accordion/discussion-board-accordion.component';
import { PaymentDetailsComponent } from '../payment-details/payment-details.component';
import { UploadInvoiceComponent } from '../upload-invoice/upload-invoice.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    Accordion,
    UtilityIconsComponent,
    ProgramCardComponent,
    SidebarTabsComponent,
    CardsComponent,
    SearchBarComponent,
    CustomDropdownComponent,
    PageTitleComponent,
    BreadcrumbsComponent,
    FormTabsComponent,
    FormInputComponent,
    ModalComponent,
    TabsComponent,
    NavigationButtonsComponent,
    SearchBarAuditComponent,
    CountdownTimerComponent,
    PaginationComponent,
    RichTextEditorComponent,
    DialogComponent,
    CommentSectionComponent,
    CustomAccordionComponent,
    DocumentInspectorComponent,
    TextField,
    Dropdown,
    DatePicker,
    TextArea,
    Checkbox,
    FileIcon,
    FileUpload,
    TableComponent,
    LoadingSpinner,
    TrainingBodyDataComponent,
    CertificateComponent,
    FileDownloadComponent,
    GeneralNoteComponent,
    ArchivedFilesDialogComponent,
    FieldVisitCriteriaComponent,
    ViewFieldVisitComponent,
    FieldVisitWrapperComponent,
    DiscussionBoardComponent,
    ThreadComponent,
    DiscussionBoardAccordionComponent,
    PaymentDetailsComponent,
    UploadInvoiceComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule,
    ReactiveFormsModule,
    SharedModule,
    MatDatepickerModule,
    MatTableModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    DialogModule,
    NgSelectModule,
    CommonModule,
    FormsModule,
    MatExpansionModule,
    PdfViewerModule,
    TabViewModule,
    MenuModule,
    EditorModule,
    TableModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    InputTextModule,
    KeyFilterModule,
    DropdownModule,
    CalendarModule,
    InputTextareaModule,
    CheckboxModule,
    BrowserAnimationsModule,
    AccordionModule,
    PaginatorModule,
    QRCodeModule,
  ],
  exports: [
    UtilityIconsComponent,
    HttpClientModule,
    TranslateModule,
    ProgramCardComponent,
    CardsComponent,
    SearchBarComponent,
    SearchBarAuditComponent,
    CountdownTimerComponent,
    PaginationComponent,
    FormsModule,
    PageTitleComponent,
    RichTextEditorComponent,
    RouterModule,
    BreadcrumbsComponent,
    SidebarTabsComponent,
    ReactiveFormsModule,
    Accordion,
    FormTabsComponent,
    FormInputComponent,
    ModalComponent,
    TabsComponent,
    NavigationButtonsComponent,
    CommentSectionComponent,
    CustomAccordionComponent,
    PdfViewerModule,
    DocumentInspectorComponent,
    TableComponent,
    TabViewModule,
    MenuModule,
    EditorModule,
    Checkbox,
    TextField,
    TextArea,
    FileIcon,
    AccordionModule,
    DialogComponent,
    DialogModule,
    ArchivedFilesDialogComponent,
    LoadingSpinner,
    TrainingBodyDataComponent,
    CertificateComponent,
    FileDownloadComponent,
    GeneralNoteComponent,
    FieldVisitCriteriaComponent,
    ViewFieldVisitComponent,
    FieldVisitWrapperComponent,
    DiscussionBoardComponent,
    ThreadComponent,
    DiscussionBoardAccordionComponent,
    CustomDropdownComponent,
    DatePicker,
    PaymentDetailsComponent,
    UploadInvoiceComponent,
  ],
  providers: [],
})
export class DashboardSharedModule {}
