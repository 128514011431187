<div class="modal-overlay" [ngClass]="{ 'show-modal': showModal }">
  <div class="modal" [ngClass]="customClass">
    <div class="modal-content">
      <app-svg-icon
        *ngIf="isCloseVisible"
        (click)="hideModal()"
        class="close-icon"
        name="close-icon"
      ></app-svg-icon>
      <ng-content></ng-content>
    </div>
  </div>
</div>
