<div class="container">
  <div *ngIf="programTitleTextHandler">
    <app-page-title
      [title]="programTitleTextHandler"
      [customClass]="'application-number__title'"
      ><span class="green-color">{{
        requestData?.trainingOrganization?.reportId
      }}</span
      >)</app-page-title
    >
    <app-page-title
      [title]="requestData?.trainingOrganization?.organizationName"
      [customClass]="'trainingOrgName__title'"
    />
    <div class="breadcrumbs-container">
      <app-breadcrumbs [levels]="levels"></app-breadcrumbs>
      <app-custom-button
        customClass="transperantGreenButton"
        (click)="requestDetailsButtonHandler()"
        buttonText="تفاصيل الطلب"
      ></app-custom-button>
    </div>
  </div>
  <loading-spinner></loading-spinner>
  <committee-voting
    *ngIf="requestData?.trainingOrganization"
    [requestData]="requestData"
    [readOnly]="readonly"
    [requestId]="id"
    (activeTabIndex)="updateBreadcrumbs($event)"
  ></committee-voting>
</div>
