<div class="file-item" *ngIf="showIcon && (file?.name || file?.fileName)">
  <div class="file-item__container">
    <div
      *ngIf="
        (!readOnly && !canReplaceFile) ||
        (canReplaceFile && !file?.id && !haveFilesToReplace) ||
        (canReplaceFile &&
          file?.id &&
          updateRequestNumber === fileVersion &&
          haveFilesToReplace === 1)
      "
      class="close-icon"
      (click)="removeFile()"
    >
      <img src="assets/images/close-filled.svg" />
    </div>
    <svg
      class="file-icon"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <use [attr.xlink:href]="showSmallFileIcon ? '#small-file-icon' : '#file-icon'"></use>
    </svg>
    <div class="file-info">
      <span class="file-name">{{ file?.name || file?.fileName }}</span>
      <span class="file-size" *ngIf="showSize">{{
        file?.fileSize || getFileSize(file?.size)
      }}</span>
      <span class="file-date" *ngIf="showDate">{{ creationDate }}</span>
    </div>
    <div
      *ngIf="
        canReplaceFile &&
        haveFilesToReplace &&
        (updateRequestNumber > fileVersion || haveFilesToReplace > 1)
      "
      class="replace-file__container"
    >
      <input
        class="replace-input"
        name="replace"
        [id]="file?.id + '-replace-field'"
        accept=".pdf"
        type="file"
        title=""
        (change)="onFileUpload($event)"
        multiple
      />
      <label [for]="file?.id + '-replace-field'" class="replace-file">
        <img [src]="UploadIconSrc" />
      </label>
    </div>
  </div>
</div>
