<div class="homepage-docs-container">
  <div class="" *ngFor="let doc of docsData">
    <app-homepage-docs-card
      [imgSrc]="doc.imgSrc"
      [content]="doc.content"
      [link]="doc.link"
      [title]="doc.title"
      [content]="doc.content"
    ></app-homepage-docs-card>
  </div>
</div>
