import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Status } from '@app/dashboard/types/status';
import { AuthService } from '@app/services/auth.service';
import { UserRole } from '@app/types/roles';
import { VotingService } from '../committee-voting/voting.service';
import { Formatter } from '@utils/formatters';
import { Votes } from '@app/types/votes';
import * as html2pdf from 'html2pdf.js';

@Component({
  selector: 'app-secretary-form',
  templateUrl: './secretary-form.component.html',
  styleUrl: './secretary-form.component.scss',
})
export class SecretaryFormComponent implements OnInit {
  id: number | string;
  requestStatus: number;
  votingResultData: any;
  userShouldNotSeeForm: boolean;
  logoSvg = 'assets/images/logo.svg';
  reversedDate: string;
  votesTableData: any;
  votesEnum = Votes;
  isDownloadButtonDisabled: boolean = false;

  userAgency = [
    'المركز السعودي لكفاءة الطاقة',
    'المؤسسة العامة للتدريب التقني والمهني',
    'المركز الوطني للتعليم الإلكتروني',
    'هيئة تقويم التعليم والتدريب',
    'عضو مستقل',
  ];

  levels = [
    {
      name: 'dashboard',
      path: '/dashboard',
    },
  ];

  @ViewChild('secretaryForm') secretaryForm: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private votingService: VotingService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.id = params['id'];

      this.levels.push(
        {
          name: 'تفاصيل الطلب',
          path: `/dashboard/application-details/${this.id}`,
        },
        { name: 'secretary-form', path: '' },
      );

      this.votingService.getVotingResult(this.id).subscribe({
        next: ({ data }) => {
          this.votingResultData = data;
          this.requestStatus = data.trainingOrganizationStatus;

          switch (this.requestStatus) {
            case Status.ApprovedByCommitteeHeadWaitingCommitteeSecretary:
              this.userShouldNotSeeForm = !this.authService.hasRole(
                UserRole.CommitteeSecretary,
              );
              break;

            case Status.ApprovedByCommitteeSecretaryWaitingGeneralManager:
            case Status.RenewalRequestHasBeenAcceptedByCommitteeSecretaryToGeneralManager:
              this.userShouldNotSeeForm = !this.authService.hasRole(
                UserRole.GeneralManager,
              );
              break;
            case Status.RejectedByCommitteeSecretaryToTrainingOrganization:
            case Status.RejectedByCommitteeHeadToCommitteeSecretary:
              this.userShouldNotSeeForm =
                !this.authService.hasRole(UserRole.InternalAuditor) &&
                !this.authService.hasRole(UserRole.CommitteeSecretary) &&
                !this.authService.hasRole(UserRole.CommitteeMember) &&
                !this.authService.hasRole(UserRole.CommitteeHead);
              break;
            default:
              this.userShouldNotSeeForm = true;
              break;
          }

          if (this.userShouldNotSeeForm) this.router.navigate(['/error-page']);

          this.reversedDate = Formatter.reverseDateString(
            this.votingResultData?.endVotingDate,
          );
          this.votesTableData = this.votingResultData?.votes.map((vote) => ({
            userName: vote.member.userName,
            roleStr: vote.member.role.split(' ')[0],
            voteStr: vote.commissionerVoteStatusStr,
            voteStatus: vote.commissionerVoteStatusId,
          }));
        },
        error: (error) => {
          console.log(error);
        },
      });
    });
  }

  get programTitleTextHandler() {
    if (!this.votingResultData?.trainingProgramNameAr) return null;
    return `برنامج ${this.votingResultData?.trainingProgramNameAr} (طلب رقم `;
  }

  downloadForm() {
    this.isDownloadButtonDisabled = true;
    const clone = this.secretaryForm.nativeElement.cloneNode(
      true,
    ) as HTMLElement;
    clone.setAttribute(
      'style',
      `border: none; z-index: -100; position: relative;`,
    );
    clone.classList.add('secretary-form');
    document.body.appendChild(clone);
    html2pdf()
      .from(clone)
      .set({
        margin: [1, 0, 0, 0],
        filename: 'نموذج قرار اللجنة',
        image: { type: 'webp', quality: 1 },
        pagebreak: {
          mode: 'avoid-all',
        },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
      })
      .save()
      .then(() => {
        document.body.removeChild(clone);
        this.isDownloadButtonDisabled = false;
        this.cdr.detectChanges();
      });
  }

  navigateToApplicationDetails() {
    this.router.navigate(['/dashboard/application-details', this.id]);
  }
}
