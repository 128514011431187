<div class="cards-container">
  <div class="card" *ngFor="let card of cardsData; let i = index">
    <div class="card-icon" [innerHTML]="sanitizeSvg(card.icon)"></div>
    <div class="card-title">{{ card.title | translate }}</div>
    <div class="card-slider__container">
      <div class="card-text__container">
        <div class="card-title">{{ card.title | translate }}</div>
        <p class="slider-text">{{ card.text | translate }}</p>
      </div>
      <button class="slider-btn">{{ card.btnText | translate }}</button>
    </div>
  </div>
</div>
