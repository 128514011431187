<div
  class="field-visit"
  [ngClass]="{ 'only-accordion': !isNotAccordionView && !isEditView }"
>
  <ng-container *ngFor="let fieldVisits of fieldVisitData; let i = index">
    <div
      *ngIf="
        (i < fieldVisitData?.length - 1 && isNotAccordionView) ||
        !isNotAccordionView
      "
    >
      <accordion
        [customClass]="{
          'field-visit-accordion': true,
          'unClickable-files': unClickable
        }"
        [header]="
          fieldVisitData?.length === 1
            ? 'تقييم الزيارة الميدانية'
            : getHeaderText(i)
        "
      >
        <app-view-field-visit
          [fieldVisitData]="fieldVisitData[i]"
          [archiveNumber]="i + 1"
        />
      </accordion>
    </div>
  </ng-container>
  <div
    class="not-accordion-field-visit"
    [ngClass]="{ 'unClickable-files': unClickable }"
    *ngIf="fieldVisitData?.length && isNotAccordionView"
  >
    <p class="title">
      {{
        fieldVisitData?.length === 1
          ? 'تقييم الزيارة الميدانية'
          : getHeaderText(fieldVisitData?.length - 1)
      }}
    </p>
    <app-view-field-visit
      [fieldVisitData]="fieldVisitData[fieldVisitData?.length - 1]"
      [archiveNumber]="fieldVisitData?.length"
      [filesControlsArray]="filesControlsArray"
      [isEditView]="isEditView"
    />
  </div>
</div>
