import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-upload-invoice',
  templateUrl: './upload-invoice.component.html',
  styleUrl: './upload-invoice.component.scss',
})
export class UploadInvoiceComponent {
  @Input() title: string;

  @Input() selectedFile!: any;
  @Output() selectedFileChange = new EventEmitter<any>();
  @ViewChild('fileInput') fileInput: ElementRef;

  onImageSelected(event: any) {
    const selectedFile = event.target.files[0];
    const allowedImgExtensions = [
      'image/jpeg',
      'image/jpg',
      'image/png',
      'image/gif',
      'image/bmp',
      'image/tiff',
      'image/webp',
      'image/svg+xml',
    ];
    if (selectedFile && !allowedImgExtensions.includes(selectedFile?.type)) {
      this.selectedFileChange.emit(null);
      return;
    }

    this.fileInput.nativeElement.value = null;
    this.selectedFile = selectedFile;
    this.selectedFileChange.emit(this.selectedFile);
  }
}
