import { Component } from '@angular/core';

@Component({
  selector: 'app-utility-icons',
  templateUrl: './utility-icons.component.html',
  styleUrls: ['./utility-icons.component.scss'],
})
export class UtilityIconsComponent {
  showSelect = false;

  toggleSelect() {
    this.showSelect = !this.showSelect;
  }
}
