<app-header includeSubHeader="true"></app-header>
<div class="container">
  <div class="title__container">الأسئلة الشائعة</div>

  <div class="questions_container">
    <div class="logo">
      <img class="logo-svg" [src]="logoSvg" alt="" />
    </div>
    <div
      *ngFor="let item of faqContent; let index = index"
      class="question__item"
    >
        <span class="index">{{ index + 1 }}.</span>
      <div class="answer__container">
        {{ item.question }}
        <p class="answer index">
          {{ item.answer.main ? item.answer.main : item.answer }}
        </p>

        <div class="answer">
          <div *ngFor="let point of item.answer?.points; let i = index">
            <span class="index">{{ i + 1 }}.</span> {{ point }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
