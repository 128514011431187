<ng-container *ngFor="let note of notes">
  <div class="note-wrapper" *ngIf="note?.name">
    <img [src]="images.altImage" class="user-img" />
    <div class="user-with-comment">
      <div class="user">
        <p
          class="user-name"
          *ngIf="
            note.user.roleId === TrainingOrganizationRole || showUserName;
            else NoteRole
          "
        >
          {{ note.user.userName }}
          <span
            class="auditor-role"
            *ngIf="note.user.roleId !== TrainingOrganizationRole"
            >({{ note.user.role }})</span
          >
        </p>
        <ng-template #NoteRole>
          <p class="user-name">
            {{ note.user.role }}
          </p>
        </ng-template>
        <div *ngIf="!isDocumentInspector" class="icon-text">
          <img [src]="images.calendar" />
          <p>{{ note.criteriaNoteDateTime | date: 'd/M/yyyy' }}</p>
        </div>
        <div *ngIf="!isDocumentInspector" class="icon-text">
          <img [src]="images.clock" />
          <p class="time-text">
            {{ note.criteriaNoteDateTime | date: 'shortTime' }}
          </p>
        </div>
      </div>
      <p class="user-comment">
        {{ note.name }}
      </p>
    </div>
  </div>
</ng-container>
