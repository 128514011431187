<div class="container">
  <div class="evaluation-container">
    <app-document-inspector
      #inspectorComponent
      *ngIf="trainingProgram"
      [isCheckbox]="false"
      [openModal]="openModal"
      [fileIndex]="fileIndex"
      (closeModal)="hideModal()"
      [customClass]="'initial-document'"
      (showTrainingDataChange)="onShowTrainingDataChange($event)"
      (showPledgeChange)="onShowPledge($event)"
      [requestStatus]="requestStatus"
      [allNotes]="allNotes"
      (showTabDataIndexValueChanged)="onTabDataIndexValueChange($event)"
    >
      <div
        *ngIf="showTrainingData && !showPledge"
        class="tab-data training-program__data"
      >
        <div class="training-body__title">بيانات البرنامج التدريبي</div>
        <div class="row-data">
          <div class="training-body__info">
            <div class="field-container">
              <app-svg-icon
                class="field-icon"
                name="document-icon"
              ></app-svg-icon>
              <div class="field-name">اسم البرنامج التدريبي</div>
            </div>
            <div class="field-value">
              {{ trainingProgram.nameAr }}
            </div>
          </div>
          <div class="training-body__info">
            <div class="field-container">
              <app-svg-icon
                class="field-icon"
                name="document-icon"
              ></app-svg-icon>
              <div class="field-name">
                اسم البرنامج التدريبي باللغة الإنجليزية
              </div>
            </div>
            <div class="field-value">
              {{ trainingProgram.nameEn }}
            </div>
          </div>
        </div>
        <div class="row-data">
          <div class="training-body__info">
            <div class="field-container">
              <app-svg-icon class="field-icon" name="note-icon"></app-svg-icon>
              <div class="field-name">نوع البرنامج التدريبي</div>
            </div>
            <div class="field-value">
              {{ trainingProgram.trainingProgramType }}
            </div>
          </div>
          <div class="training-body__info">
            <div class="field-container">
              <app-svg-icon class="field-icon" name="badge-icon"></app-svg-icon>
              <div class="field-name">مستوى البرنامج التدريبي</div>
            </div>
            <div class="field-value">
              {{ trainingProgram.programLevelName }}
            </div>
          </div>
        </div>
        <div class="row-data">
          <div class="training-body__info">
            <div class="field-container">
              <app-svg-icon class="field-icon" name="clock-icon"></app-svg-icon>
              <div class="field-name">مدة البرنامج التدريبي (بالأيام)</div>
            </div>
            <div class="field-value">
              {{ trainingProgram.trainingProgramDuration }}
            </div>
          </div>
          <div class="training-body__info">
            <div class="field-container">
              <app-svg-icon class="field-icon" name="clock-icon"></app-svg-icon>
              <div class="field-name">عدد الساعات التدريبية الإجمالية</div>
            </div>
            <div class="field-value">
              {{ trainingProgram.totalTrainingHours }}
            </div>
          </div>
        </div>
        <div class="row-data">
          <div class="training-body__info">
            <div class="field-container">
              <app-svg-icon
                class="field-icon"
                name="target-icon"
              ></app-svg-icon>
              <div class="field-name">الهدف العام من البرنامج التدريبي</div>
            </div>
            <div class="field-value">
              {{ trainingProgram.programObjective }}
            </div>
          </div>
          <div class="training-body__info">
            <div class="field-container">
              <app-svg-icon class="field-icon" name="globe-icon"></app-svg-icon>
              <div class="field-name">لغة البرنامج التدريبي</div>
            </div>
            <div class="field-value">
              {{ trainingProgram.languageLookupName }}
            </div>
          </div>
        </div>
        <div class="row-data">
          <div class="training-body__info">
            <div class="field-container">
              <app-svg-icon class="field-icon" name="users-icon"></app-svg-icon>
              <div class="field-name">الفئة المستهدفة</div>
            </div>
            <div class="field-value">
              {{ trainingProgram.targetCategory }}
            </div>
          </div>
        </div>
        <div>
          <div class="training-body__info">
            <div class="field-container">
              <app-svg-icon class="field-icon" name="book-icon"></app-svg-icon>
              <div class="field-name">وصف البرنامج التدريبي</div>
            </div>
            <div class="field-value training-program-description">
              {{ trainingProgram.description }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="tab-content__container tab-data"
        *ngIf="generalCommitment && showPledge"
      >
        <div class="training-body__title">التعهد العام</div>

        <div class="pledge-container">
          <span class="pledge-intro"
            >السادة المركز السعودي لكفاءة الطاقة حفظهم االله</span
          >
          <p class="pledge-intro">السلام عليكم ورحمة االله وبركاته</p>
          <p class="pledge-para">
            نتعهد نحن {{ trainingOrganization.organizationName }}، بالالتزام
            بلائحة اعتماد البرامج التدريبية في مجال كفاءة الطاقة والدليل
            الإرشادي للائحة وكافة التعاميم والتعليمات الخاصة التي يصدرها المركز
            السعودي لكفاءة الطاقة بهذا الشأن.
          </p>
          <span class="pledge-para salute">وتقبلوا وافر التحية والتقدير</span>
          <div class="applicant-container">
            <span class="applicant-name__text">اسم مقدم الطلب</span>
            <span class="applicant-name">{{
              generalCommitment.applicantsName
            }}</span>
          </div>
          <div *ngIf="imgUrl" class="electronic-signature__container">
            <span>التوقيع الإلكتروني</span>
            <div class="electronic-signature">
              <div class="signature-container">
                <img
                  [src]="imgUrl"
                  alt="Electronic Signature"
                  class="signature-img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-document-inspector>

    <app-training-body-data [trainingOrganization]="trainingOrganization" />

    <app-svg-icon
      name="long-two-sided-arrow"
      class="two-sided-arrow"
    ></app-svg-icon>

    <app-page-title
      [title]="'مراحل التقييم'"
      [customClass]="'application-details'"
    />

    <div class="accordions-container">
      <accordion
        customClass="internal-auditor-evaluation"
        header="التقييم الأولي للمدقق الداخلي"
      >
        <div
          *ngFor="
            let element of documentInspectorStateService.trainingProgramDocumentMapping;
            let isFirst = first;
            let sectionIndex = index
          "
        >
          <div *ngIf="!isFirst" class="divider-custom"></div>
          <div class="application-wrapper">
            <div class="label-container">
              <div class="information">
                {{ element.programDocumentLookup.name }}
              </div>
              <ul
                class="field-subtitles"
                *ngIf="
                  element.programDocumentLookup.programDocumentRequirements
                    .length
                "
              >
                <li
                  *ngFor="
                    let subtitle of element.programDocumentLookup
                      .programDocumentRequirements
                  "
                >
                  {{ subtitle }}
                </li>
              </ul>
            </div>
            <div class="files-container">
              <div class="active-tab">
                <file-icon
                  class="internal-auditor-evaluation-file"
                  *ngFor="
                    let file of element.files;
                    let currentFileIndex = index
                  "
                  (click)="viewFile(sectionIndex, currentFileIndex, 0)"
                  [file]="file?.archivedFiles?.at(-1)?.file"
                  [index]="currentFileIndex"
                  [readOnly]="true"
                ></file-icon>
                <div class="items-container" *ngIf="!element.files.length">
                  <div
                    class="file-item__container"
                    (click)="
                      trainingProgramAndPledgeIconsClickHandler(sectionIndex, 0)
                    "
                  >
                    <app-svg-icon
                      name="file-icon"
                      class="file-icon"
                    ></app-svg-icon>
                    <div class="file-info">
                      <span class="file-name">{{
                        element.programDocumentLookup.name
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <file-download
                [files]="element.files"
                *ngIf="element.files.length && userCanDownloadFiles"
              ></file-download>
            </div>
            <div
              *ngIf="
                filterCriteriaMapping(
                  element.programDocumentLookup.programDocumentCriteriaMappings,
                  internalAuditorRoleId
                ).length
              "
            >
              <div class="flex checked-section">
                <app-view-evaluation
                  [filterAccordionCriteria]="
                    filterCriteriaMapping(
                      element.programDocumentLookup
                        .programDocumentCriteriaMappings,
                      internalAuditorRoleId
                    )
                  "
                  class="evaluation-notes"
                />
              </div>
            </div>
            <div
              *ngIf="
                documentNotesFilter(
                  element.documentNotes,
                  internalAuditorRoleId
                ).length
              "
            >
              <div class="other-notes-title">ملاحظات أخرى</div>

              <div class="user-notes">
                <div
                  *ngFor="
                    let note of documentNotesFilter(
                      element.documentNotes,
                      internalAuditorRoleId
                    )
                  "
                  class="user-note"
                  [class.empty]="!note.note"
                >
                  <app-comment-section [data]="note" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </accordion>

      <accordion
        customClass="technical-advisor-evaluation"
        header="التقييم الأولي للخبير الفني"
        *ngIf="showTechnicalAdvisorSection"
      >
        <div>
          <div
            *ngFor="
              let element of technicalAdvisorTrainingProgramDocuments;
              let isFirst = first
            "
          >
            <div *ngIf="!isFirst" class="divider-custom"></div>
            <div class="application-wrapper">
              <div class="label-container">
                <div class="information">
                  {{ element.programDocumentLookup.name }}
                </div>
                <ul
                  *ngIf="
                    element.programDocumentLookup.programDocumentRequirements
                      .length
                  "
                  class="field-subtitles"
                >
                  <li
                    *ngFor="
                      let subtitle of element.programDocumentLookup
                        .programDocumentRequirements
                    "
                  >
                    {{ subtitle }}
                  </li>
                </ul>
              </div>

              <div class="files-container">
                <div class="active-tab">
                  <file-icon
                    *ngFor="
                      let file of element.files;
                      let currentFileIndex = index
                    "
                    (click)="viewFile(element.index, currentFileIndex, 1)"
                    [file]="file?.archivedFiles?.at(-1)?.file"
                    [index]="currentFileIndex"
                    [readOnly]="true"
                    class="technical-advisor-evaluation-file"
                  ></file-icon>
                  <div class="items-container technical-advisor-evaluation-file" *ngIf="!element.files.length">
                    <div
                      class="file-item__container"
                      (click)="
                        trainingProgramAndPledgeIconsClickHandler(
                          element.index,
                          1
                        )
                      "
                    >
                      <app-svg-icon
                        name="file-icon"
                        class="file-icon"
                      ></app-svg-icon>
                      <div class="file-info">
                        <span class="file-name">{{
                          element.programDocumentLookup.name
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <file-download
                  [files]="element.files"
                  *ngIf="element.files.length && userCanDownloadFiles"
                ></file-download>
              </div>
              <div
                *ngIf="
                  filterCriteriaMapping(
                    element.programDocumentLookup
                      .programDocumentCriteriaMappings,
                    technicalAdvisorRoleId
                  ).length
                "
              >
                <div class="flex checked-section">
                  <app-view-evaluation
                    [filterAccordionCriteria]="
                      filterCriteriaMapping(
                        element.programDocumentLookup
                          .programDocumentCriteriaMappings,
                        technicalAdvisorRoleId
                      )
                    "
                    class="evaluation-notes"
                  />
                </div>
              </div>
              <div
                *ngIf="
                  documentNotesFilter(
                    element.documentNotes,
                    technicalAdvisorRoleId
                  ).length
                "
              >
                <div class="other-notes-title">ملاحظات أخرى</div>
                <div class="user-notes">
                  <div
                    *ngFor="
                      let note of documentNotesFilter(
                        element.documentNotes,
                        technicalAdvisorRoleId
                      )
                    "
                    class="user-note"
                    [class.empty]="!note.note"
                  >
                    <app-comment-section [data]="note"></app-comment-section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </accordion>
      <accordion
        *ngIf="
          applicationIsReviewedByTechnicalAdvisor && !fieldVisitData?.length
        "
        [header]="' (لا يوجد حاجة للزيارة الميدانية) '"
        [disabled]="true"
        customClass="field-visit-accordion"
      />
      <app-field-visit-wrapper
        *ngIf="fieldVisitData.length"
        class="field-visit"
        [requestStatus]="requestStatus"
        [fieldVisitData]="fieldVisitData"
      />
      <div>
        <div class="divider-custom"></div>

        <general-note
          *ngIf="
            applicationIsReviewedByInternalAuditor ||
            applicationIsReviewedByTechnicalAdvisor ||
            showCommitteeSecretaryObservationsNote
          "
          [noteData]="
            generalNotesFilter(
              requestData?.trainingProgramDocumentNote,
              internalAuditorRoleId
            )
          "
          [trainingOrganizationId]="id"
          [requestStatus]="requestStatus"
          role="المدقق الداخلي"
        ></general-note>
        <general-note
          *ngIf="
            applicationIsReviewedByTechnicalAdvisor ||
            showCommitteeSecretaryObservationsNote
          "
          [noteData]="
            generalNotesFilter(
              requestData?.trainingProgramDocumentNote,
              technicalAdvisorRoleId
            )
          "
          [trainingOrganizationId]="id"
          [requestStatus]="requestStatus"
          role="الخبير الفني"
        ></general-note>
        <general-note
          *ngIf="showCommitteeSecretaryObservationsNote"
          [noteData]="
            generalNotesFilter(
              requestData?.trainingProgramDocumentNote,
              committeeSecretaryRoleId
            )
          "
          [trainingOrganizationId]="id"
          [requestStatus]="requestStatus"
          role="أمين اللجنة"
        ></general-note>
      </div>
      <div class="committee-secratary-observations" *ngIf="showObservations">
        <p>مرئيات أمين اللجنة</p>
        <app-rich-text-editor
          [control]="committeeSecrataryObservationsControl"
          (textValueEmitter)="committeeObservationsTextValue = $event"
        />
      </div>
    </div>

    <app-dialog
      [id]="id"
      [generalNotes]="
        committeeObservationsTextValue
          ? committeeSecrataryObservationsControl.value
          : ''
      "
      [noteId]="committeeSecrataryObservations?.id"
      [trainingOrganizationStatusEnum]="
        ApprovedByCommitteeSecrateryAndWaitingTechnicalAdvisor
      "
      [visible]="openAdvisorModal"
      (onHide)="onDialogHide($event)"
      [isChecked]="!!committeeObservationsTextValue"
      [userIsCommitteeSecretary]="true"
      [proposedTechnicalAdvisor]="requestData?.proposedTechnicalAdvisor?.user"
      [proposedWorkingHours]="
        requestData?.proposedTechnicalAdvisor?.workingHours
      "
      (onSuccess)="showSuccessModal = $event"
    />

    <div class="success-modal__container">
      <app-modal
        [modalContent]="'submission'"
        [showModal]="showSuccessModal"
        (closeModal)="closeModel()"
        [customClass]="'success-modal'"
        [isCloseVisible]="!(isLoading | async)"
      >
        <loading-spinner>جاري ارسال الطلب...</loading-spinner>
        <div *ngIf="!(isLoading | async)" class="modal-body">
          <app-svg-icon class="success-icon" name="success-icon"></app-svg-icon>
          <span class="modal-text">{{ modalText }}</span>
        </div>
      </app-modal>
    </div>
    <app-custom-button
      [disabled]="isSubmitButtonDisabled"
      (click)="submitButtonClickHandler(requestStatus)"
      [buttonText]="submitButtonTextHandler(requestStatus)"
      buttonStyle="greenButton"
    />
    <app-custom-button
      *ngIf="isReturnToInternalAuditorButtonVisible"
      [disabled]="isSubmitButtonDisabled"
      [buttonStyle]="'transperantGreenButton'"
      [buttonText]="'إعادة الطلب إلى المدقق الداخلي'"
      (click)="submitButtonClickHandler(rejectedByCommitteeSecratary)"
    />
  </div>
</div>
