<div class="container">
  <div class="main-container">
    <app-side-bar></app-side-bar>
    <div class="profile-page-container">
      <app-dashboard-header></app-dashboard-header>
      <div class="profile-section-Wrapper">
        <header>
          <h1>الصفحة الشخصية</h1>
          <app-breadcrumbs [levels]="getBreadCrumbsLevels()"></app-breadcrumbs>
        </header>
        <main>
          <section class="profile-picture-section">
            <div class="profile-picture">
              <img [src]="profilePictureUrl" alt="" *ngIf="profilePictureUrl" />
              <p-skeleton
                *ngIf="!profilePictureUrl"
                class="skeleton-placeholder"
              />
              <div class="edit-actions" *ngIf="isEditMode && profilePictureUrl">
                <img
                  src="assets/images/close-round-btn.svg"
                  class="close-pic"
                  [class.open]="isEditPicOpen"
                  (click)="isEditPicOpen = false"
                />
                <img
                  src="assets/images/delete-round-btn.svg"
                  class="remove-pic"
                  [class.open]="isEditPicOpen"
                  (click)="onRemoveProfilePicture()"
                />
                <img
                  [src]="
                    !profilePictureUrl.includes('assets')
                      ? 'assets/images/pin.svg'
                      : 'assets/images/upload-round-btn.svg'
                  "
                  class="edit-pic"
                  [class.open]="isEditPicOpen"
                  (click)="onClickEditPicture()"
                />
              </div>
              <input
                type="file"
                name="profile-picture-input"
                id="profile-picture-input"
                style="display: none"
                accept="image/*"
                (change)="onUploadPicture($event)"
              />
            </div>
            <div class="text-group">
              <p class="label">صورة شخصية</p>
              <p class="description">
                يمكنك إضافة صورة شخصية أو شعار الجهة التدريبية
              </p>
            </div>
          </section>
          <div class="profile-data-wrapper" *ngIf="!isEditMode">
            <div class="row-data">
              <div class="user-profile-info">
                <div class="field-container">
                  <img src="assets/images/group.svg" alt="group icon" />
                  <div class="field-name">اسم الجهة التدريبية</div>
                </div>
                <div class="field-value">
                  {{ profileData.organizationName }}
                </div>
              </div>
              <div class="user-profile-info">
                <div class="field-container">
                  <img src="assets/images/human.svg" alt="human icon" />
                  <div class="field-name">اسم مقدم الطلب</div>
                </div>
                <div class="field-value">
                  {{ profileData.coordinatorName }}
                </div>
              </div>
            </div>
            <div class="row-data">
              <div class="user-profile-info">
                <div class="field-container">
                  <img src="assets/images/phone.svg" alt="phone icon" />
                  <div class="field-name">الجوال</div>
                </div>
                <div class="field-value">
                  {{ profileData.phoneNumber }}
                </div>
              </div>
              <div class="user-profile-info">
                <div class="field-container">
                  <img src="assets/images/email-green.svg" alt="email icon" />
                  <div class="field-name">البريد الالكتروني لمقدم الطلب</div>
                </div>
                <div class="field-value">
                  {{ profileData.coordinatorEmail }}
                </div>
              </div>
            </div>
            <div class="row-data">
              <div class="user-profile-info">
                <div class="field-container">
                  <img src="assets/images/location.svg" alt="location icon" />
                  <div class="field-name">العنوان</div>
                </div>
                <div class="field-value">
                  {{ profileData.address }}
                </div>
              </div>
              <div class="user-profile-info">
                <div class="field-container">
                  <img src="assets/images/location.svg" alt="location icon" />
                  <div class="field-name">المدينة</div>
                </div>
                <div class="field-value">
                  {{ profileData.city }}
                </div>
              </div>
            </div>
            <div class="row-data">
              <div class="user-profile-info">
                <div class="field-container">
                  <img src="assets/images/location.svg" alt="location icon" />
                  <div class="field-name">رقم الرخصة التدريبية</div>
                </div>
                <div class="field-value">
                  {{ profileData.trainingLicenseNumber }}
                </div>
              </div>
              <div class="user-profile-info">
                <div class="field-container">
                  <img src="assets/images/location.svg" alt="location icon" />
                  <div class="field-name">تاريخ الرخصة التدريبية</div>
                </div>
                <div class="field-value">
                  {{ profileData.licenseDate }}
                </div>
              </div>
            </div>
            <div class="row-data">
              <div class="user-profile-info">
                <div class="field-container">
                  <img src="assets/images/email-green.svg" alt="email icon" />
                  <div class="field-name">
                    البريد الالكتروني للجهة التدريبية
                  </div>
                </div>
                <div class="field-value">
                  {{ profileData.email }}
                </div>
              </div>
            </div>
          </div>
          <form (submit)="onSubmit()" *ngIf="isEditMode">
            <div class="input-group">
              <label class="label">
                <img src="assets/images/group.svg" alt="group icon" />
                <span>اسم الجهة التدريبية</span>
              </label>
              <input
                type="text"
                name="organizationName"
                [(ngModel)]="profileData.organizationName"
                [ngClass]="{
                  invalid: !profileData.organizationName,
                  editMode: isEditMode,
                }"
                [disabled]="!isEditMode"
              />
              <span
                class="error-msg"
                *ngIf="!profileData.organizationName && isEditMode"
                >هذا الحقل مطلوب</span
              >
            </div>

            <div class="input-group">
              <label class="label">
                <img src="assets/images/human.svg" alt="human icon" />
                <span>اسم مقدم الطلب</span>
              </label>
              <input
                type="text"
                name="coordinatorName"
                [(ngModel)]="profileData.coordinatorName"
                [ngClass]="{
                  invalid: !profileData.coordinatorName,
                  editMode: isEditMode,
                }"
                [disabled]="!isEditMode"
              />
              <span
                class="error-msg"
                *ngIf="!profileData.coordinatorName && isEditMode"
                >هذا الحقل مطلوب</span
              >
            </div>

            <div class="input-group">
              <label class="label">
                <img src="assets/images/phone.svg" alt="phone icon" />
                <span>الجوال</span>
              </label>
              <input
                type="text"
                name="phoneNumber"
                [(ngModel)]="profileData.phoneNumber"
                [ngClass]="{
                  invalid: !profileData.phoneNumber,
                  editMode: isEditMode,
                }"
                [disabled]="!isEditMode"
              />
              <span
                class="error-msg"
                *ngIf="!profileData.phoneNumber && isEditMode"
                >هذا الحقل مطلوب</span
              >
            </div>

            <div class="input-group">
              <label class="label">
                <img src="assets/images/email-green.svg" alt="email icon" />
                <span>البريد الالكتروني لمقدم الطلب</span>
              </label>
              <input
                type="email"
                name="coordinatorEmail"
                [(ngModel)]="profileData.coordinatorEmail"
                required
                [ngClass]="{
                  invalid: isEditMode && (!profileData.coordinatorEmail || !isValidEmail(profileData.coordinatorEmail)),
                  editMode: isEditMode
                }"
                [disabled]="!isEditMode"
              />
            
              <!-- Error message for required field -->
              <span class="error-msg" *ngIf="!profileData.coordinatorEmail && isEditMode">
                هذا الحقل مطلوب
              </span>
            
              <!-- Error message for invalid email format -->
              <span class="error-msg" *ngIf="profileData.coordinatorEmail && !isValidEmail(profileData.coordinatorEmail) && isEditMode">
                يرجى إدخال بريد إلكتروني 
              </span>
            </div>
            
            <div class="input-group">
              <label class="label">
                <img src="assets/images/location.svg" alt="location icon" />
                <span>العنوان</span>
              </label>
              <input
                type="text"
                name="address"
                [(ngModel)]="profileData.address"
                [ngClass]="{
                  invalid: !profileData.address,
                  editMode: isEditMode,
                }"
                [disabled]="!isEditMode"
              />
              <span class="error-msg" *ngIf="!profileData.address && isEditMode"
                >هذا الحقل مطلوب</span
              >
            </div>
            <div class="input-group">
              <label class="label">
                <img src="assets/images/location.svg" alt="location icon" />
                <span>المدينة</span>
              </label>
              <app-custom-dropdown
                [selectorName]="profileData.city ?? 'المدينة'"
                [dropdownOptions]="cities"
                (onSelectOption)="onSelectCity($event)"
                *ngIf="isEditMode"
              ></app-custom-dropdown>
              <input
                type="text"
                name="city"
                [(ngModel)]="profileData.city"
                disabled
                *ngIf="!isEditMode"
              />
              <span class="error-msg" *ngIf="!profileData.cityId && isEditMode"
                >هذا الحقل مطلوب</span
              >
            </div>

            <div class="input-group">
              <label class="label">
                <img src="assets/images/licenseNumber.svg" alt="id card icon" />
                <span>رقم الرخصة التدريبية</span>
              </label>
              <input
                type="text"
                name="trainingLicenseNumber"
                [(ngModel)]="profileData.trainingLicenseNumber"
                [ngClass]="{
                  invalid: !profileData.trainingLicenseNumber,
                  editMode: isEditMode,
                }"
                [disabled]="!isEditMode"
              />
              <span
                class="error-msg"
                *ngIf="!profileData.trainingLicenseNumber && isEditMode"
                >هذا الحقل مطلوب</span
              >
            </div>

            <div class="input-group">
              <label class="label">
                <img src="assets/images/calendar.svg" alt="calendaricon" />
                <span>تاريخ الرخصة التدريبية</span>
              </label>
              <date-picker
                (dateSelected)="onSelectLicenseDate($event)"
                [placeholder]="
                  this.profileData?.licenseDate?.length
                    ? this.profileData?.licenseDate
                    : ''
                "
                [customErrorMessage]="
                  isEditMode && !this.profileData.licenseDate
                    ? 'هذا الحقل مطلوب'
                    : ''
                "
                [class.editMode]="isEditMode"
              />
              <span
                class="error-msg"
                *ngIf="!profileData.licenseDate && isEditMode"
                >هذا الحقل مطلوب</span
              >
            </div>

            <div class="input-group">
              <label class="label">
                <img src="assets/images/email-green.svg" alt="email icon" />
                <span>البريد الالكتروني للجهة التدريبية</span>
              </label>
              <input
                type="email"
                name="email"
                [(ngModel)]="profileData.email"
                required
                [ngClass]="{
                  invalid: isEditMode && (!profileData.email || !isValidEmail(profileData.email)),
                  editMode: isEditMode
                }"
                [disabled]="!isEditMode"
              />
            
              <!-- Error messages -->
              <span class="error-msg" *ngIf="isEditMode">
                <span *ngIf="!profileData.email">هذا الحقل مطلوب</span>
                <span *ngIf="profileData.email && !isValidEmail(profileData.email)">يرجى إدخال بريد إلكتروني </span>
              </span>
            </div>

          </form>
          <div class="action">
            <app-custom-button
              [buttonStyle]="'greenButton'"
              [buttonText]="'تعديل الصفحة الشخصية'"
              (click)="isEditMode = true"
              *ngIf="!isEditMode"
            ></app-custom-button>
            <app-custom-button
              [buttonStyle]="'greenButton'"
              [buttonText]="'حفظ'"
              (click)="onSubmit()"
              *ngIf="isEditMode"
              [disabled]="isSubmitDisabled()"
            ></app-custom-button>
            <app-custom-button
              [buttonStyle]="'transperantGreenButton'"
              [buttonText]="'إلغاء'"
              (click)="onCancel()"
              *ngIf="isEditMode"
            ></app-custom-button>
          </div>
        </main>
      </div>
    </div>
  </div>
</div>
