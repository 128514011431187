import { Injectable } from '@angular/core';
import { Formatter } from '@utils/formatters';

@Injectable({
  providedIn: 'root',
})
export class ObjectToFormDataService {
  constructor() {}
  convert(obj: any, isAccreditationForm: boolean = false) {
    const formData: FormData = new FormData();

    const appendFormData = (data: any, path: string = ''): void => {
      if ([null, undefined].includes(data)) {
        return;
      }

      if (Array.isArray(data)) {
        data.forEach((value: any, index: number) => {
          if (
            (path.includes('files') || path.includes('file')) &&
            !isAccreditationForm
          ) {
            const newPath: string = `${path}`;
            appendFormData(value, newPath);
          } else {
            const newPath: string = `${path}[${index}]`;
            appendFormData(value, newPath);
          }
        });
      } else if (data instanceof Date) {
        formData.append(path, Formatter.dateToString(data));
      } else if (data instanceof File) {
        formData.append(path, data);
      } else if (typeof data === 'object') {
        Object.entries(data).forEach(([key, value]: [string, any]) => {
          const newPath: string = path ? `${path}.${key}` : key;
          appendFormData(value, newPath);
        });
      } else {
        formData.append(path, String(data));
      }
    };
    appendFormData(obj);

    return formData;
  }
}
