<div class="card-container">
  <div class="cards-inner__container">
    <img class="layer layer-1" src="{{ imgSrc }}" alt="" />

    <div class="layer-2-3-container">
      <svg
        class="layer layer-2"
        width="351"
        height="271"
        viewBox="0 0 351 271"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.8"
          d="M0.874631 194.795L103.389 1.71427C103.909 0.733934 104.929 0.121094 106.039 0.121094H348C349.657 0.121094 351 1.46424 351 3.12109V267.782C351 269.685 349.252 271.107 347.39 270.72L2.91397 199.139C0.95651 198.732 -0.0629106 196.561 0.874631 194.795Z"
          fill="#2C6E59"
        />
      </svg>

      <svg
        class="layer layer-3"
        xmlns="http://www.w3.org/2000/svg"
        width="319"
        height="247"
        viewBox="0 0 319 247"
        fill="none"
      >
        <path
          d="M0.878422 177.289L93.5228 3.04669C94.0435 2.06722 95.0623 1.45508 96.1716 1.45508H315C316.657 1.45508 318 2.79822 318 4.45507V243.328C318 245.23 316.253 246.652 314.39 246.265L2.91777 181.635C0.959122 181.229 -0.0606765 179.056 0.878422 177.289Z"
          stroke="white"
        />
      </svg>
    </div>

    <div class="layer card__content">
      <div class="card__content-text">
        <p class="card__title">{{ title | translate }}</p>
        <p class="card__sub-title">
          {{ content | translate }}
        </p>
      </div>
      <button class="card-btn">{{ 'download' | translate }}</button>
    </div>
  </div>
</div>
