<div class="card-container" [ngClass]="customClass">
  <div class="progress-text__container">
    <div class="card-name">{{ data.name | translate }}</div>
    <div class="card-icons" *ngIf="!isAccreditation">
      <span class="course-duration"
        ><app-svg-icon name="clock-icon" class="clock-icon"></app-svg-icon
        >{{ data.duration | translate }}</span
      >
      <span class="course-language"
        ><app-svg-icon name="language-icon" class="language-icon"></app-svg-icon
        >{{ data.language | translate }}</span
      >
    </div>
    <div class="card-tracking__container" *ngIf="isAccreditation">
      <app-svg-icon name="copy-icon" class="copy-icon"></app-svg-icon>
      <div class="tracking-id">#13456789876544</div>
    </div>
    <div class="progress-bar__container">
      <div class="progress-container">
        <div class="progress-bar" [style.width]="data.progress"></div>
      </div>
      <div class="progress-status__container">
        <div class="progress-status">{{ data.status | translate }}</div>
        <span>{{ data.progress }}</span>
      </div>
      <span></span>
    </div>
  </div>
  <div class="program-image__container">
    <img class="program-img" src="{{ data.image }}" alt="" />
    <button class="level-btn">{{ buttonText | translate }}</button>
  </div>
</div>
