import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Assets } from '@assets/images';
import { VotingService } from '../../committee-voting/voting.service';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '@app/services/auth.service';
import { UserRole } from '@app/types/roles';

@Component({
  selector: 'app-discussion-board',
  templateUrl: './discussion-board.component.html',
  styleUrl: './discussion-board.component.scss',
})
export class DiscussionBoardComponent implements OnInit {
  @Input() trainingOrganizationId: number;

  discussionBoardData: any;
  discussionForms: FormGroup;
  isSecretaryNotesDialogOpen: boolean = false;
  isCommitteSecretary = this.authService.hasRole(UserRole.CommitteeSecretary);
  committeeNoteTextValue: string = '';
  noNotesHTML: string =
    '<p class="ql-direction-rtl ql-align-right">لا توجد ملاحظات</p>';

  isSaving = new BehaviorSubject<boolean>(false);
  isSavingNotes = new BehaviorSubject<boolean>(false);
  images = {
    altImage: Assets.altImage,
  };

  constructor(
    private submitService: VotingService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.getDiscussionBoardData();

    this.discussionForms = this.formBuilder.group({
      committeeSecretaryNote: '',
      discussionBoardCommentForm: ['', Validators.required],
    });
  }

  getDiscussionBoardData() {
    this.submitService
      .getApplicationDiscussionBoard(this.trainingOrganizationId)
      .subscribe((res) => {
        this.discussionBoardData = res.data;
        this.discussionForms
          .get('committeeSecretaryNote')
          .setValue(
            this.currentDiscussionBoardData?.committeeSecretaryNote?.note,
          );
        this.committeeNoteTextValue =
          this.currentDiscussionBoardData?.committeeSecretaryNote?.note;
      });
  }

  get currentDiscussionBoardData() {
    return this.discussionBoardData?.at(-1);
  }

  get committeeSecretaryNoteControl() {
    return this.discussionForms.get('committeeSecretaryNote');
  }

  get commentControl() {
    return this.discussionForms.get('discussionBoardCommentForm');
  }

  commentReplyCount(index: number) {
    return this.currentDiscussionBoardData?.discussionBoardThreads[index]
      ?.discussionBoardReplies.length;
  }

  async onCommentSubmit(
    isReply?: boolean,
    discussionBoardThreadId?: number,
    reply?: string,
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (!isReply) {
        if (this.commentControl.invalid) return reject();
        this.isSaving.next(true);
      }
      const requestBody = {
        discussionBoardId: this.currentDiscussionBoardData?.id,
        trainingOrganizationId: this.trainingOrganizationId,
        note: !isReply ? this.commentControl?.value : reply,
        ...(isReply && {
          discussionBoardThreadId: discussionBoardThreadId,
        }),
      };

      this.submitService.submitDiscussionBoard(requestBody).subscribe({
        next: (res) => {
          if (!isReply) {
            this.commentControl.reset();
            this.isSaving.next(false);
          }
          this.discussionBoardData = res.data;
          resolve();
        },
        error: (res) => {
          console.log(res);
          reject();
        },
      });
    });
  }

  saveCommitteeSecretaryNotes() {
    if (!this.committeeNoteTextValue) return;
    this.isSavingNotes.next(true);

    const requestBody = {
      id: this.currentDiscussionBoardData?.committeeSecretaryNote?.id || null,
      discussionBoardId: this.currentDiscussionBoardData?.id,
      trainingOrganizationId: this.trainingOrganizationId,
      note: this.committeeSecretaryNoteControl.value,
    };
    this.submitService.submitCommitteeSecretaryNote(requestBody).subscribe({
      next: (res) => {
        this.discussionBoardData = res.data;
        this.committeeSecretaryNoteControl.setValue(
          this.currentDiscussionBoardData?.committeeSecretaryNote?.note,
        );
        this.committeeNoteTextValue =
          this.currentDiscussionBoardData?.committeeSecretaryNote?.note;
        this.isSecretaryNotesDialogOpen = false;
      },
      error: (error) => {
        console.log('Error sending the data', error);
      },
    });
  }

  hideSecretaryNotesDialog() {
    this.isSecretaryNotesDialogOpen = false;
    this.committeeSecretaryNoteControl.setValue(
      this.currentDiscussionBoardData?.committeeSecretaryNote?.note,
    );
    this.committeeNoteTextValue =
      this.currentDiscussionBoardData?.committeeSecretaryNote?.note;
  }
}
