import { Component, OnInit } from '@angular/core';
import { AuditService } from '@app/dashboard/services/audit.service';
import { ActivatedRoute } from '@angular/router';
import { LoadingService } from '@app/services/loading.service';
import { Formatter } from '@utils/formatters';
import { Observable } from 'rxjs';

@Component({
  selector: 'certificate-page',
  templateUrl: './certificate-page.component.html',
  styleUrl: './certificate-page.component.scss',
})
export class CertificatePageComponent implements OnInit {
  id!: number;
  requestData: any;
  certificateData: any;

  levels: any[];

  constructor(
    private route: ActivatedRoute,
    private auditService: AuditService,
    private loadingService: LoadingService,
  ) {}

  isLoading$: Observable<boolean> = this.loadingService.isLoading$();

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
      this.levels = [
        {
          name: 'dashboard',
          path: '/dashboard',
        },
        {
          name: 'committeVotingForm',
          path: `/dashboard/voting/${this.id}`,
        },
        {
          name: 'certificateIssuing',
          path: '',
        },
      ];
      this.loadingService.startLoading();
      this.auditService.getApplicationData(this.id).subscribe((response) => {
        this.loadingService.stopLoading();
        this.requestData = response.data;
        this.certificateData = {
          programName: this.requestData?.trainingProgram?.nameAr,
          startDate: Formatter.dateToString(new Date()),
          // set date to 3 years from now
          endDate: Formatter.dateToString(
            new Date(new Date().setFullYear(new Date().getFullYear() + 3)),
          ),
          referenceId: this.requestData?.trainingOrganization?.reportId,
          trainingOrganizationId: this.id,
          organizationName:
            this.requestData?.trainingOrganization?.organizationName,
        };
      });
    });
  }
}
