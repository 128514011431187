<div class="text-field-container">
  <label *ngIf="label" [for]="id"
    >{{ label }} <small *ngIf="isRequired">*</small>
  </label>
  <input
    [ngClass]="{ 'full-height': !label }"
    [value]="control?.value"
    pInputText
    [id]="id"
    [pKeyFilter]="keyFilter"
    [placeholder]="placeholder"
    [formControl]="control"
  />
  <small
    *ngIf="control?.touched && !control?.valid"
    [id]="id + '-textfield-error-message'"
    >{{ errorMessage }}</small
  >
</div>
