import { TranslateService } from '@ngx-translate/core';
/**
 * Used to set the language and the page direction
 *
 * @param language
 * @param translate
 */
export function setLanguage(language: string, translate: TranslateService) {
  const appRoot = document.getElementsByTagName('app-root')[0];
  const html = document.getElementsByTagName('html')[0];

  appRoot.setAttribute('class', language !== 'en' ? 'rtl' : 'ltr');

  html.lang = language;
  html.dir = language !== 'en' ? 'rtl' : 'ltr';
  language === 'en' || language === 'ar'
    ? translate.use(language)
    : translate.use('ar');
}
