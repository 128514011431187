import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'check-box',
  templateUrl: './checkbox.component.html',
  styleUrl: './checkbox.component.scss',
})
export class Checkbox {
  @Input() id: string;
  @Input() label: string;
  @Input() control: FormControl;
  @Input() errorMessage: string = 'هذا الحقل مطلوب';
}
