import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Assets } from 'src/assets/images';

@Component({
  selector: 'file-icon',
  templateUrl: './file-icon.component.html',
  styleUrl: './file-icon.component.scss',
})
export class FileIcon {
  @Input() file!: File | any;
  @Input() index: number;
  @Input() readOnly: boolean = false;
  @Input() canReplaceFile: boolean = false;
  @Input() showIcon: boolean = true;
  @Input() multipleSelect: boolean = true;
  @Input() haveFilesToReplace: boolean = false;
  @Input() updateRequestNumber: number;
  @Input() fileVersion: number;
  @Input() showSize: boolean = true;
  @Input() showDate: boolean = false;
  @Input() creationDate: string;
  @Input() showSmallFileIcon: boolean = false;

  @Output() removeFileEmmiter = new EventEmitter<any>();
  @Output() uploadFileEmitter = new EventEmitter<any>();

  UploadIconSrc: string = Assets.upload;

  constructor() {}
  getFileSize(sizeInBytes: number): string {
    const kbSize = sizeInBytes / 1024;

    return kbSize < 1024
      ? kbSize.toFixed(2) + ' KB'
      : (kbSize / 1024).toFixed(2) + ' MB';
  }
  removeFile() {
    this.removeFileEmmiter.emit(this.index);
  }

  onFileUpload(file: any): void {
    this.uploadFileEmitter.emit(file);
  }
}
