import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../components/shared/dialog/dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private dialog: MatDialog) {}

  openDialog(data?: any): void {
    this.dialog.open(DialogComponent, {
      width: '400px',
      data: {
        onlySecond: data?.onlySecond,
        text: data?.message,
      },
    });
  }
}
