import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ObjectToFormDataService } from 'src/app/services/object-to-form-data.service';
import { environment } from 'src/environments/environment';
import { Formatter } from 'src/utils/formatters';

export type NewAccreditationForm = {
  trainingOrganization: {
    organizationName: string;
    coordinatorName: string;
    trainingLicenseNumber: string | number;
    licenseDate: Date;
    licenseExpirationDate: Date;
    address: string;
    phoneNumber: string | number;
    email: string;
    isSubmitted: boolean;
    requestTypeId: number | string;
    cityId: number | string;
  };
  trainingProgram: {
    nameAr: string;
    nameEn: string;
    programLevelLookupId: number;
    trainingProgramTypeId: number;
    trainingProgramType?: number;
    trainingProgramDuration: number;
    totalTrainingHours: number;
    programObjective: string;
    languageLookupId: number;
    targetCategory: string;
    description: string;
    isSubmitted: boolean;
  };
  trainingProgramDocuments: {
    isSubmitted: boolean;
    programDocuments: {
      programDocumentLookupId: number;
      files: {
        trainingProgramDocumentMappingFileId: number;
        file: File[];
      }[];
    }[];
  };
  generalCommitment: {
    organizationName: string;
    applicantsName: string;
    file: File;
    generalCommitmentFileId: number;
    fileId: number;
    isSubmitted: boolean;
  };
};

export type UpdateAccreditationForm = {
  trainingOrganization: {
    id: number;
    organizationName: string;
    coordinatorName: string;
    trainingLicenseNumber: string | number;
    licenseDate: Date;
    licenseExpirationDate: Date;
    address: string;
    phoneNumber: string | number;
    email: string;
    isSubmitted: boolean;
    requestTypeId: number | string;
    cityId: number | string;
  };
  trainingProgram: {
    trainingOrganizationId: number;
    id: number;
    nameAr: string;
    nameEn: string;
    programLevelLookupId: number;
    trainingProgramTypeId: number;
    trainingProgramType?: number;
    trainingProgramDuration: number;
    totalTrainingHours: number;
    programObjective: string;
    languageLookupId: number;
    targetCategory: string;
    description: string;
    isSubmitted: boolean;
  };
  trainingProgramDocuments: {
    trainingOrganizationId: number;
    isSubmitted: boolean;
    id: number;
    programDocuments: {
      programDocumentLookupId: number;
      files: {
        trainingProgramDocumentMappingFileId: number;
        file: File[];
      }[];
    }[];
  };
  generalCommitment: {
    trainingOrganizationId: number;
    id: number;
    organizationName: string;
    applicantsName: string;
    file: File;
    generalCommitmentFileId: number;
    fileId: number;
    isSubmitted: boolean;
  };
  trainingOrganizationFieldVisitForm: { Id?: number | string };
  fieldVisitForms: any[];
};

@Injectable({
  providedIn: 'root',
})
export class AccreditationFormService {
  private url: string = `${environment.apiUrl}/api/TrainingProgramRequest`;

  constructor(
    private http: HttpClient,
    private objectToFormDataConverter: ObjectToFormDataService,
  ) {}

  sendNewApplicationForm(
    accreditationFormBody: NewAccreditationForm,
  ): Observable<any> {
    const response = this.http.post<any>(
      this.url + '/CreateOrUpdateTrainingOrganization',
      this.objectToFormDataConverter.convert(accreditationFormBody, true),
    );
    return response;
  }

  updateTrainingOrganizationSection(
    accreditationFormBody: UpdateAccreditationForm['trainingOrganization'],
  ): Observable<any> {
    const trainingOrganizationFormBody = {
      ...accreditationFormBody,
      licenseDate:
        accreditationFormBody?.licenseDate instanceof Date
          ? Formatter.dateToString(accreditationFormBody?.licenseDate)
          : accreditationFormBody?.licenseDate,
      licenseExpirationDate:
        accreditationFormBody?.licenseExpirationDate instanceof Date
          ? Formatter.dateToString(accreditationFormBody?.licenseExpirationDate)
          : accreditationFormBody?.licenseExpirationDate,
    };

    const response = this.http.post<any>(
      this.url + '/CreateOrUpdateTrainingOrganizationSection',
      trainingOrganizationFormBody,
    );
    return response;
  }

  updateTrainingProgramSection(
    accreditationFormBody: UpdateAccreditationForm['trainingProgram'],
  ): Observable<any> {
    const response = this.http.post<any>(
      this.url + '/CreateOrUpdateTrainingProgramSection',
      accreditationFormBody,
    );
    return response;
  }

  updateGeneralCommitmentSection(
    accreditationFormBody: UpdateAccreditationForm['generalCommitment'],
  ): Observable<any> {
    const response = this.http.post<any>(
      this.url + '/CreateOrUpdateGeneralCommitmentSection',
      this.objectToFormDataConverter.convert(accreditationFormBody, true),
    );
    return response;
  }

  updateDocumentFilesSection(
    accreditationFormBody: UpdateAccreditationForm['trainingProgramDocuments'],
  ): Observable<any> {
    const response = this.http.post<any>(
      this.url + '/CreateOrUpdateDocumentFilesSection',
      this.objectToFormDataConverter.convert(accreditationFormBody, true),
    );

    return response;
  }
}
