import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DocumentInspectorStateService {
  tabDataIndex = 0;
  trainingProgramDocumentMapping: any[] = [];

  setTrainingProgramDocumentMapping(newValue: any) {
    this.trainingProgramDocumentMapping = newValue;
  }

  setTabDataIndex(idx: number) {
    this.tabDataIndex = idx;
  }
}
