<div class="main-container" *ngIf="paymentData">
  <app-page-title [title]="'تفاصيل الدفع'"></app-page-title>
  <p class="payment-instruction-text small-font">
    ملاحظة: يجب السداد خلال مدة لا تتجاوز (30) يوم من قبول اللجنة.
  </p>

  <div class="new-section-border">
    <app-page-title
      [title]="'الفاتورة'"
      [customClass]="['application-details']"
    ></app-page-title>
    <div class="flex">
      <p class="payment-instruction-text">
        طلب إعتماد برنامج {{ data?.trainingOrganization?.organizationName }}
      </p>
      <p class="payment-instruction-text amount">
        {{ paymentData.totalAmount }}

        <span class="green"> SAR </span>
      </p>
    </div>
    <div class="divider"></div>
    <div class="total-amount flex">
      <p>مجموع المبلغ</p>
      <p class="amount">
        {{ paymentData.totalAmount }}
        <span class="green"> SAR </span>
        <span class="nothing-else">فقط لا غير</span>
      </p>
    </div>
  </div>
  <div class="flex file-attachments">
    <div style="width: 91%">
      <p class="payment-instruction-text">رفع إيصال السداد بعد الدفع</p>

      <p class="invoices" *ngIf="imagesArr?.length">الإيصالات السابقة</p>
      <div class="files-container" *ngIf="imagesArr?.length">
        <div
          class="file-item"
          [ngClass]="{ border: !isLast }"
          *ngFor="let file of imagesArr; let isLast = last; let index = index"
        >
          <file-icon
            [file]="file"
            [multipleSelect]="false"
            [readOnly]="true"
            (click)="openReceipt(file)"
          />
        </div>
      </div>

      <p class="invoices" *ngIf="imagesArr?.length && fileData">
        الإيصال الحالي
      </p>
      <div class="files-container current-invoice" *ngIf="fileData">
        <div class="file-item">
          <file-icon
            [file]="fileData"
            [multipleSelect]="false"
            (removeFileEmmiter)="removeImage()"
          />
        </div>
      </div>
      <div class="error-msg" *ngIf="fileData === null">يجب تحميل ملف من نوع "صورة"</div>
    </div>

    <app-upload-invoice
      *ngIf="showUploadFile"
      class="upload-button"
      title="رفع الإيصال"
      [(selectedFile)]="fileData"
      (selectedFileChange)="isSendInvoiceButtonDisabledEmitter.emit(false)"
    ></app-upload-invoice>
  </div>

  <div class="financial-auditor-notes-container">
    <accordion
      [customClass]="'notes-accordion'"
      *ngIf="paymentData?.financialAuditorNotes?.length"
      [header]="'ملاحظات المدقق المالي'"
    >
      <div class="financial-auditor-notes">
        <div
          *ngFor="let note of paymentData?.financialAuditorNotes"
          class="note"
          [class.empty]="!note.note"
        >
          <app-comment-section
            [isHTML]="true"
            [data]="note"
            [showUserName]="false"
          />
        </div>
      </div>
    </accordion>
  </div>

  <div class="success-modal__container">
    <app-modal
      [modalContent]="'submission'"
      [showModal]="showSubmitModal"
      (closeModal)="closeSubmitModal()"
      [customClass]="'success-modal'"
      [isCloseVisible]="submittedSuccessfully"
    >
      <loading-spinner [isLoading$]="isLoading"
        >جاري إرسال الإيصال...</loading-spinner
      >
      <div *ngIf="submittedSuccessfully" class="modal-body">
        <app-svg-icon class="success-icon" name="success-icon"></app-svg-icon>
        <span class="modal-text">{{ 'تم إرسال الإيصال  بنجاح' }}</span>
      </div>
    </app-modal>
  </div>
  <div class="success-modal__container">
    <app-modal
      [modalContent]="'information'"
      customClass="success-receipt-modal"
      [showModal]="showReceiptPreview"
      (closeModal)="closePreview()"
      [isCloseVisible]="!(isPaymentLoading$ | async)"
    >
      <loading-spinner [isLoading$]="isPaymentLoading$">{{
        'جاري تحميل الايصال...'
      }}</loading-spinner>
      <div class="modal-body" *ngIf="receiptPreview">
        <img [src]="receiptPreview" />
      </div>
    </app-modal>
  </div>
</div>
