import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { UserRole } from 'src/app/types/roles';
import { AuditService } from '@app/dashboard/services/audit.service';
import { BodyScrollService } from '@app/dashboard/services/body-scroll.service';
import { DocumentInspectorComponent } from '../shared/document-inspector/document-inspector.component';
import { Status } from '../../types/status';
import { FormControl } from '@angular/forms';
import { DocumentNoteType } from '../../types/documentNote.type';
import { DocumentInspectorStateService } from 'src/app/services/document-inspector-state.service';
import { LoadingService } from '@app/services/loading.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-application-details-user-role',
  templateUrl: './application-details-user-role.component.html',
  styleUrl: './application-details-user-role.component.scss',
})
export class ApplicationDetailsUserRoleComponent implements OnInit {
  @Input() title: string = '';
  @Input() requestData: any;
  allNotes: any;
  rowData: any;
  isClicked: boolean = false;
  openModal: boolean = false;
  fileURL: string = '';
  fileIndex: number = 0;
  isChecked: boolean = false;
  fieldVisit: boolean = false;
  trainingOrganization: any;
  trainingProgram: any;
  generalCommitment: any;
  test: any;
  pdfUrl: any;
  base64String: string | ArrayBuffer;
  showTrainingData: boolean = true;
  showPledge: boolean = false;
  imgUrl: string = '';
  criteriaLookup: any;
  id: number;
  openAdvisorModal: boolean = false;
  generalNotes = new FormControl();
  generalNotesText: string = '';
  internalAuditorLastNote: any;
  @ViewChild('inspectorComponent')
  inspectorComponent: DocumentInspectorComponent;
  criteria: any;
  showSubmitModal: boolean = false;
  requestStatus: number;
  requestIsReviewedByInternalAuditor: boolean = false;
  requestIsAddressedByTrainingOrganization: boolean = false;
  requestIsReturnedByCommitteeSecratary: boolean = false;
  requestIsReturnedByTechnicalAdvisor: boolean = false;
  submittedSuccessfully: boolean = false;
  sendToTrainingOrgButtonIsClicked: boolean = false;
  updateRequestNumber: number;
  applicationVersion: number;
  isLoading: Observable<boolean> = this.loadingService.isLoading$();
  archivedFilesDialogOpen: boolean = false;
  fieldVisitData: Array<any>;
  committeeSecretaryDiscussionNote: any;
  showCommitteeSecretaryNoteButton: boolean = false;
  noNotesHTML: string =
    '<h2 class="ql-direction-rtl ql-align-right">لا توجد ملاحظات</h2>';

  @Input() showCommitteeSecretaryNote!: boolean;
  @Output() showCommitteeSecretaryNoteChange = new EventEmitter<boolean>();

  constructor(
    private bodyScrollService: BodyScrollService,
    private auditService: AuditService,
    private router: Router,
    private documentInspectorStateService: DocumentInspectorStateService,
    private loadingService: LoadingService,
  ) {
    bodyScrollService.modalScrollEnabled.subscribe((enableScroll) => {
      if (enableScroll) {
        document.body.classList.remove('modal-open');
      } else {
        document.body.classList.add('modal-open');
      }
    });
  }

  getInternalAuditorLastGeneralNote(
    notes: Array<any>,
    applicationVersion: number,
  ) {
    return notes.find((note) => {
      return (
        note?.user?.roleId === UserRole.InternalAuditor &&
        note?.version === applicationVersion
      );
    });
  }

  ngOnInit() {
    this.trainingOrganization = this.requestData.trainingOrganization;
    this.id = this.trainingOrganization.id;
    this.trainingProgram = this.requestData.trainingProgram;
    this.generalCommitment = this.requestData.generalCommitment;
    this.requestStatus =
      this.requestData.workFlowTrainingOrganization.trainingOrganizationStatusLookupId;
    this.fieldVisitData = this.requestData?.trainingOrganizationFieldVisitForms;

    this.updateRequestNumber =
      this.requestData?.trainingProgramDocuments?.updateRequestNumber;

    this.applicationVersion =
      this.requestData?.trainingOrganization?.applicationVersion;

    this.internalAuditorLastNote = this.getInternalAuditorLastGeneralNote(
      this.requestData?.trainingProgramDocumentNote,
      this.applicationVersion,
    );

    this.committeeSecretaryDiscussionNote =
      this.requestData.committeeSecretaryNote;

    if (
      this.requestStatus ===
      Status.ReturnedByCommitteeSecretaryToInternalAuditorAfterFormulationNote
    )
      this.showCommitteeSecretaryNoteButton = true;

    this.generalNotesText = this.internalAuditorLastNote?.note;
    this.generalNotes.setValue(this.internalAuditorLastNote?.note);
    if (this.generalNotes.value) this.handleCheckboxChange();

    if (
      this.requestStatus > Status.NewOrderWaitingInternalAuditor &&
      this.requestStatus !=
        Status.RenewalRequestFromTrainingOrganizationToInternalAuditor &&
      this.requestStatus != Status.AddressedByTrainingOrganization
    )
      this.requestIsReviewedByInternalAuditor = true;
    if (this.requestStatus == Status.AddressedByTrainingOrganization)
      this.requestIsAddressedByTrainingOrganization = true;
    if (
      this.requestStatus ===
        Status.ReturnedByCommitteeSecrataryToInternalAuditor ||
      this.requestStatus ===
        Status.ReturnedByCommitteeSecrataryAfterTechnicalAdvisor ||
      this.requestStatus ===
        Status.ReturnedByCommitteeSecretaryToInternalAuditorAfterFormulationNote
    )
      this.requestIsReturnedByCommitteeSecratary = true;
    if (
      this.requestStatus === Status.ReturnedByTechnicalAdvisorToInternalAuditor
    )
      this.requestIsReturnedByTechnicalAdvisor = true;

    this.documentInspectorStateService.setTrainingProgramDocumentMapping(
      this.requestData.trainingProgramDocuments
        ?.trainingProgramDocumentMapping || [],
    );
    this.getElecSignature();
    this.fetchCriteriaLookup();

    this.allNotes =
      this.requestData?.trainingProgramDocuments
        ?.trainingProgramDocumentMapping?.[
        this.documentInspectorStateService?.tabDataIndex
      ]?.programDocumentLookup?.programDocumentCriteriaMappings;
  }

  getElecSignature() {
    if (this.generalCommitment?.generalCommitmentFile?.file.id)
      this.auditService
        .getFile(this.generalCommitment.generalCommitmentFile.file.id)
        .subscribe({
          next: (data) => {
            this.imgUrl = data;
          },
          error: (error) => {
            console.log('error', error);
          },
        });
  }

  onShowTrainingDataChange(showTrainingData: boolean) {
    this.showTrainingData = showTrainingData;
  }

  onShowPledge(showPledge: boolean) {
    this.showPledge = showPledge;
  }

  onTabDataIndexValueChange() {
    this.handleSaveEvaluationData();
  }

  fetchCriteriaLookup() {
    this.auditService.getCriteriaLookup(UserRole.InternalAuditor).subscribe({
      next: (data) => {
        this.criteriaLookup = data.data;
      },
      error: (error) => {
        console.log('Error Retrieving Criteria', error);
      },
    });
  }

  showData() {
    this.isClicked = true;
    this.openModal = true;
    this.bodyScrollService.toggleBodyScroll(false);
  }

  get allArchivedFiles() {
    const allArchivedFiles = [];
    const trainingProgramDocuments =
      this.documentInspectorStateService.trainingProgramDocumentMapping[4]
        .files;

    for (let i = 0; i < trainingProgramDocuments.length; i++) {
      const archivedFiles = trainingProgramDocuments[i].archivedFiles;
      for (let j = 0; j < archivedFiles.length - 2; j++) {
        allArchivedFiles.push(archivedFiles[j]);
      }
    }
    return allArchivedFiles;
  }

  viewFile(activeTabIndex: number, index: number): void {
    this.documentInspectorStateService.setTabDataIndex(activeTabIndex);
    this.bodyScrollService.toggleBodyScroll(false);
    this.fileIndex = index;
    this.showTrainingData = activeTabIndex == 0;
    this.inspectorComponent.showTrainingData = activeTabIndex == 0;
    this.inspectorComponent.fileIndex = index; // TODO: Add to the service
    this.inspectorComponent.showPledge =
      activeTabIndex ==
      this.documentInspectorStateService.trainingProgramDocumentMapping.length -
        1;
    this.showPledge =
      activeTabIndex ==
      this.documentInspectorStateService.trainingProgramDocumentMapping.length -
        1;
    this.openModal = true;
    this.inspectorComponent.fileURL = '';
    this.inspectorComponent.oldFileUrl = '';

    const filesArray =
      this.documentInspectorStateService.trainingProgramDocumentMapping[
        activeTabIndex
      ].files[index]?.archivedFiles;
    let waitForFilesLoading = false;
    if (filesArray && filesArray.length > 1) waitForFilesLoading = true;

    this.inspectorComponent.getFileUrl(
      activeTabIndex,
      index,
      true,
      waitForFilesLoading,
    );
    this.inspectorComponent.getOldFileUrl(
      activeTabIndex,
      index,
      waitForFilesLoading,
    );
    this.handleSaveEvaluationData();
  }

  hideModal(): void {
    this.openModal = false;
    this.fileURL = '';
    this.bodyScrollService.toggleBodyScroll(true);
  }

  hideCommitteeSecretaryNote() {
    this.showCommitteeSecretaryNote = false;
    this.showCommitteeSecretaryNoteChange.emit(false);
    document.body.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  handleCheckboxChange() {
    this.isChecked = !this.isChecked;
  }

  handleSaveEvaluationData() {
    this.allNotes =
      this.documentInspectorStateService.trainingProgramDocumentMapping[
        this.documentInspectorStateService.tabDataIndex
      ].programDocumentLookup?.programDocumentCriteriaMappings;
  }

  showFielVisit() {
    this.fieldVisit = !this.fieldVisit;
  }

  technicalAdvisorModal() {
    this.openAdvisorModal = true;
  }

  onDialogHide(status: boolean) {
    // Handle the variable status when the modal is closed
    this.openAdvisorModal = status;
  }

  handleSuccess(status: boolean): void {
    // Do something with the success status
    this.showSubmitModal = status;
    this.submittedSuccessfully = true;
    this.bodyScrollService.toggleBodyScroll(false);
  }

  accordionVisibilityHandler(
    criteriasArray: any[],
    technicalAdvisorCriteria?: boolean,
  ) {
    return (
      criteriasArray?.length &&
      this.filterAccordionCriterias(criteriasArray, technicalAdvisorCriteria)
        .length
    );
  }

  closeModel() {
    this.showSubmitModal = false;
    this.router.navigate(['/dashboard']);
  }

  sendApplicationToTrainingOrg() {
    if (
      !this.isAllCriteriaEvaluated() ||
      (this.isChecked && !this.generalNotes)
    )
      return;

    this.loadingService.startLoading();

    const body = {
      TrainingOrganizationStatusEnum:
        Status.ReturnedByInternalAuditorToTrainingOrganization,
      TrainingOrganizationId: this.id,
      ...(this.isChecked && this.generalNotesText
        ? {
            HasNotes: true,
            TrainingProgramDocumentNotes: [
              {
                id: this.internalAuditorLastNote?.id || 0,
                note: this.generalNotes.value,
                documentNoteTypeId: DocumentNoteType.GeneralNotes,
              },
            ],
          }
        : {
            HasNotes: false,
            TrainingProgramDocumentNotes: [
              {
                id: this.internalAuditorLastNote?.id || 0,
              },
            ],
          }),
    };

    this.auditService.updateApplicationStatus(body).subscribe({
      next: () => {
        this.submittedSuccessfully = true;
        this.loadingService.stopLoading();
      },
      error: (error) => {
        console.log('Error sending the data', error);
      },
    });
  }

  showConfirmationModal() {
    this.sendToTrainingOrgButtonIsClicked = true;
    this.showSubmitModal = true;
    this.bodyScrollService.toggleBodyScroll(false);
  }

  cancelReturnToTrainingOrg() {
    this.sendToTrainingOrgButtonIsClicked = false;
    this.showSubmitModal = false;
    this.bodyScrollService.toggleBodyScroll(true);
  }

  filterAccordionCriterias(
    criteriaArray: any[],
    technicalAdvisorCriteria?: boolean,
  ) {
    return criteriaArray?.filter(
      (item) =>
        item.documentCriteriaLookup.roleId ===
          (technicalAdvisorCriteria
            ? UserRole.TechnicalAdvisor
            : UserRole.InternalAuditor) && item.isPassed !== null,
    );
  }

  getDocumentNote(document: any) {
    if (!document.length) return;
    return document.filter(
      (note: any) => note?.user?.role === 'المدقق الداخلي',
    )[0].note;
  }

  isAllCriteriaEvaluated() {
    let isAllCriteriaEvaluated: boolean = true;
    let hasInternalAuditorReviewedAllTabs: boolean = true;

    const tabsArray =
      this.documentInspectorStateService.trainingProgramDocumentMapping;

    loop: for (let i = 0; i < tabsArray.length; i++) {
      const internalAuditorReview = tabsArray[
        i
      ]?.documentFileHasBeenReviewes.filter(
        (review) => review.user?.roleId === UserRole.InternalAuditor,
      );

      if (
        !internalAuditorReview.length ||
        !internalAuditorReview[0].fileHasBeenReviewed
      ) {
        hasInternalAuditorReviewedAllTabs = false;
        break;
      }

      const criteriaArray =
        tabsArray[i]?.programDocumentLookup?.programDocumentCriteriaMappings;

      for (let i = 0; i < criteriaArray.length; i++) {
        if (
          criteriaArray[i].isPassed === null &&
          criteriaArray[i].documentCriteriaLookup.roleId ===
            UserRole.InternalAuditor
        ) {
          isAllCriteriaEvaluated = false;
          break loop;
        }
      }
    }

    return isAllCriteriaEvaluated && hasInternalAuditorReviewedAllTabs;
  }
}
