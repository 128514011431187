import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-view-evaluation',
  templateUrl: './view-evaluation.component.html',
  styleUrl: './view-evaluation.component.scss',
})
export class ViewEvaluationComponent {
  @Input() filterAccordionCriteria: any;
  @Input() showNoteUserName: boolean = true;
}
