import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-custom-button',
  templateUrl: './custom-button.component.html',
  styleUrls: ['./custom-button.component.scss'],
})
export class CustomButtonComponent {
  @Input() buttonStyle: string = 'default'; // Default style
  @Input() buttonText: string = 'Click me';
  @Input() hasText: boolean = true;
  @Input() type: string = 'button';
  @Input() disabled: boolean = false;
  @Input() customClass: string = '';
  @Output() buttonClick: EventEmitter<void> = new EventEmitter<void>();

  onClick() {
    if (!this.disabled) {
      this.buttonClick.emit();
    }
  }
}
