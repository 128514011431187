import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-program-card',
  templateUrl: './program-card.component.html',
  styleUrls: ['./program-card.component.scss'],
})
export class ProgramCardComponent {
  @Input() data;
  @Input() customClass: string;
  @Input() isAccreditation: boolean = false;
  @Input() buttonText: string = '';
}
