<div
  class="wrapper"
  [ngClass]="'form' + archiveNumber"
  [id]="'wrapper-container' + archiveNumber"
>
  <div class="hidden-logo pdf-header">
    <div class="logo">
      <img class="logo-svg" [src]="logoSvg" alt="KLD Logo" />
    </div>
    <div>
      <p>تقييم الزيارة الميدانية</p>
    </div>
  </div>
  <app-custom-button
    [buttonStyle]="'greenButton'"
    [buttonText]="'تنزيل'"
    (click)="generateEvaluationPDF()"
    [disabled]="isDownloadDisabled"
    class="hidden"
    ><app-svg-icon class="download-icon" name="download-icon"></app-svg-icon
  ></app-custom-button>
  <p-table [value]="fieldVisitData?.fieldVisitForms">
    <ng-template pTemplate="header">
      <tr>
        <th>المعيار</th>
        <th>التقييم</th>
        <th>الملاحظات</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-element let-rowIndex="rowIndex">
      <tr class="standard-row">
        <td class="standard">
          <div>
            {{ element.standard }}
          </div>
        </td>
        <td class="applied">{{ element.isApplied ? 'ينطبق' : 'لا ينطبق' }}</td>
        <td class="note">{{ element.notes ?? '-' }}</td>
      </tr>
      <tr class="files-row">
        <td colspan="3" *ngIf="element.fieldVisitFormMappingFiles?.length">
          <ul
            class="associated-files hidden"
            [id]="'associated-files' + archiveNumber"
          >
            <li
              *ngIf="
                getTechnicalFiles(element.fieldVisitFormMappingFiles)?.length
              "
            >
              <file-download
                [files]="getTechnicalFiles(element.fieldVisitFormMappingFiles)"
                *ngIf="userCanDownload"
              />
              الملفات المرفقة
              <div class="files-wrapper">
                <div
                  *ngFor="
                    let file of getTechnicalFiles(
                      element.fieldVisitFormMappingFiles
                    )
                  "
                >
                  <file-icon
                    [file]="file?.file"
                    [readOnly]="true"
                    [showSmallFileIcon]="true"
                    (click)="unClickable ? null : downloadFile(file?.file)"
                    [ngClass]="{ 'readonly-files': !userCanDownload }"
                  />
                </div>
              </div>
            </li>
            <li
              *ngIf="
                getTrainingOrganizationFiles(element.fieldVisitFormMappingFiles)
                  .length && !isEditView
              "
            >
              <file-download
                [files]="
                  getTrainingOrganizationFiles(
                    element.fieldVisitFormMappingFiles
                  )
                "
                *ngIf="userCanDownload"
              />
              مرفقات الجهة التدريبية
              <div class="files-wrapper">
                <div
                  *ngFor="
                    let file of getTrainingOrganizationFiles(
                      element.fieldVisitFormMappingFiles
                    )
                  "
                >
                  <file-icon
                    [file]="file?.file"
                    [readOnly]="true"
                    [showSmallFileIcon]="true"
                    (click)="unClickable ? null : downloadFile(file?.file)"
                    [ngClass]="{ 'readonly-files': !userCanDownload }"
                  />
                </div>
              </div>
            </li>
            <li class="input-list" *ngIf="isEditView">
              مرفقات الجهة التدريبية
              <div class="files-wrapper">
                <div class="failed-criteria-file">
                  <div
                    *ngIf="!filesControlsArray.controls[rowIndex].value?.length"
                    class="file-input_placeholder"
                  >
                    إرفاق&nbsp;ملف
                  </div>
                  <div
                    class="files-icons-wrapper"
                    *ngIf="filesControlsArray.controls[rowIndex].value?.length"
                  >
                    <div class="file-icons-list-container">
                      <ng-container
                        *ngFor="
                          let file of filesControlsArray.controls[rowIndex]
                            .value;
                          let idx = index
                        "
                      >
                        <div
                          *ngIf="file.constructor.name === 'File'"
                          class="files-container"
                        >
                          <div class="file__container">
                            <file-icon
                              [file]="file"
                              [index]="idx"
                              showIcon="{{ file?.size }}"
                              [showSmallFileIcon]="true"
                              (removeFileEmmiter)="removeFile($event, rowIndex)"
                            ></file-icon>
                          </div></div
                      ></ng-container>
                    </div>
                  </div>
                  <div class="file-input_container">
                    <label [for]="'file_' + rowIndex" class="file-input-label"
                      ><img src="assets/images/attachment.svg"
                    /></label>
                    <input
                      class="file-input"
                      type="file"
                      name="file"
                      [id]="'file_' + rowIndex"
                      (change)="onFileUpload($event, rowIndex)"
                      multiple
                    />
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <div
    class="additional-attachmnets_container hidden"
    [id]="'additional-attachments_' + archiveNumber"
    *ngIf="fieldVisitData?.generalFieldVisitFormFiles?.length"
  >
    <p class="header">مرفقات اخرى</p>
    <file-download
      [files]="fieldVisitData?.generalFieldVisitFormFiles"
      *ngIf="userCanDownload"
    />
    <div class="files-wrapper">
      <div *ngFor="let file of fieldVisitData?.generalFieldVisitFormFiles">
        <file-icon
          [file]="file?.file"
          [readOnly]="true"
          [showSmallFileIcon]="true"
          [ngClass]="{ 'readonly-files': !userCanDownload }"
          (click)="unClickable ? null : downloadFile(file?.file)"
        />
      </div>
    </div>
  </div>
  <div *ngIf="getGeneralNotes()?.length" class="container hidden">
    <p class="header">ملاحظات الزيارة الميدانية</p>
    <div class="user-notes">
      <div
        *ngFor="let note of getGeneralNotes()"
        class="user-note"
        [class.empty]="!note.note"
      >
        <app-comment-section [data]="note" />
      </div>
    </div>
  </div>
</div>
