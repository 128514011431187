<ngx-extended-pdf-viewer
  [(src)]="src"
  [language]="language"
  [customToolbar]="toolbarButtons"
  (zoomChange)="onZoomChange($event)"
  [showToolbar]="showToolbar"
  [height]="height"
>
</ngx-extended-pdf-viewer>

<ng-template #toolbarButtons>
  <div class="toolbar-viewer">
    <div class="toolbar-viewer-right">
      <pdf-toggle-sidebar></pdf-toggle-sidebar>
      <pdf-paging-area></pdf-paging-area>
    </div>
    <div class="toolbar-viewer-center">
      <pdf-zoom-toolbar></pdf-zoom-toolbar>
    </div>
    <div class="toolbar-viewer-left">
      <pdf-draw-editor></pdf-draw-editor>
      <pdf-text-editor></pdf-text-editor>
      <button class="save-changes-btn" (click)="handleSaveChanges()">
        حفظ التغييرات
      </button>
      <div class="verticalToolbarSeparator"></div>
      <pdf-toggle-secondary-toolbar></pdf-toggle-secondary-toolbar>
    </div>
  </div>
</ng-template>
