<div class="container">
  <div class="label-container">
    <p-checkbox [id]="id" [binary]="true" [formControl]="control"></p-checkbox>
    <label>{{ label }}</label>
  </div>
  <small
    *ngIf="control.dirty && !control.valid"
    [id]="id + '-checkbox-error-message'"
    >{{ errorMessage }}</small
  >
</div>
