<div class="criteria-items-wrapper">
  <ng-container *ngFor="let item of filterAccordionCriteria; let index = index">
    <div
      class="criteria-name"
      [ngClass]="{ passed: item.isPassed, rejected: !item.isPassed }"
    >
      <div class="criteria-item">
        {{ item.documentCriteriaLookup.name }}
        <img
          *ngIf="item.isPassed"
          class="check-mark"
          src="assets/images/checked.svg"
        />
        <img
          *ngIf="!item.isPassed"
          class="reject-icon"
          src="assets/images/reject-icon.svg"
        />
      </div>
      <div class="prev-notes">
        <app-prev-note
          [showUserName]="showNoteUserName"
          [notes]="item?.documentCriteriaLookup?.criteriaNotes"
          [isDocumentInspector]="false"
        ></app-prev-note>
      </div>
    </div>
  </ng-container>
</div>
