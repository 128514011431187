import { Component, Input } from '@angular/core';
import { ArabicNumbersService } from '@app/services/arabic-numbers.service';

@Component({
  selector: 'app-discussion-board-accordion',
  templateUrl: './discussion-board-accordion.component.html',
  styleUrl: './discussion-board-accordion.component.scss',
})
export class DiscussionBoardAccordionComponent {
  @Input() previousDiscussionBoards: Array<any>;

  noNotesHTML: string =
    '<p class="ql-direction-rtl ql-align-right">لا توجد ملاحظات</p>';

  constructor(private numbersToArabicService: ArabicNumbersService) {}

  boardStageArabicText(stage: number) {
    return `ملاحظات الأمين (المرحلة ${this.numbersToArabicService.getNumbersInArabicText(
      stage,
    )})`;
  }
}
