import { Component } from '@angular/core';
import { Assets } from '@assets/images';

@Component({
  selector: 'app-homepage-subheader',
  templateUrl: './homepage-subheader.component.html',
  styleUrls: ['./homepage-subheader.component.scss'],
})
export class HomepageSubheaderComponent {
  subHeaderSrc = Assets.subheader;
}
