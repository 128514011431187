import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CardData } from 'src/app/dashboard/types/cardData.type';
@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss'],
})
export class CardsComponent {
  @Input() data!: CardData;

  public logoSvg!: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) {
    this.logoSvg = this.sanitizer.bypassSecurityTrustResourceUrl(
      'assets/images/languageLogo.svg',
    );
  }
}
