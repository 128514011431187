export const Formatter = {
  dateToTimestampString(date: string | Date): string {
    return new Date(date).toLocaleString();
  },

  dateToString(
    date: Date,
    locale: string = 'en-UK',
    options: any = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    },
  ): string {
    return date.toLocaleDateString(locale, options);
  },

  reverseDateString(inputString: string): string {
    const [month, day, year] = inputString.split('/');
    return `${year}/${day}/${month}`;
  },

  /**
   * Converts a date string(ISO format) or Date object to a Date object with hours, minutes, seconds, and milliseconds set to 0.
   *
   * Watchout if you use this method with Date string as this format: 00[day]/00[month]/0000[year]
   * It will flip day and month.
   * @param {string | Date} date - The date string(ISO format) or Date object to be converted.
   * @return {Date} A new Date object with hours, minutes, seconds, and milliseconds set to 0.
   */
  dateFormatConverter(date: string | Date) {
    const dateVariable = new Date(date);
    dateVariable.setHours(0, 0, 0, 0);
    return dateVariable;
  },

  /**
   * Converts a date string in the format "00[day]/00[month]/0000[year]" to a Date object.
   *
   * @param {string} date - The date string to be converted.
   * @return {Date} A new Date object representing the given date with hours, minutes, seconds, and milliseconds set to 0.
   */
  dateFromString(date: string) {
    const parts = date.split('/');
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;
    const year = parseInt(parts[2], 10);
    return new Date(year, month, day, 0, 0, 0, 0);
  },
};
