import {
  Component,
  Input,
  ChangeDetectorRef,
  AfterViewInit,
} from '@angular/core';
import { UserRole } from 'src/app/types/roles';
import * as html2pdf from 'html2pdf.js';
import { AuthService } from '@app/services/auth.service';
import { FormArray } from '@angular/forms';
import { FileDownloadService } from '@app/dashboard/services/file-download.service';

@Component({
  selector: 'app-view-field-visit',
  templateUrl: './view-field-visit.component.html',
  styleUrl: './view-field-visit.component.scss',
})
export class ViewFieldVisitComponent implements AfterViewInit {
  @Input() fieldVisitData: any;
  @Input() archiveNumber: number = 0;
  @Input() filesControlsArray: FormArray;
  @Input() isEditView: boolean;

  public logoSvg = 'assets/images/logo.svg';
  isDownloadDisabled: boolean = false;
  userCanDownload: boolean =
    this.authService.hasRole(UserRole.TrainingOrganization) ||
    this.authService.hasRole(UserRole.TechnicalAdvisor);
  unClickable: boolean = false;
  evaluationPDFConfig: any = {
    filename: 'تقييم الزيارة الميدانية',
    margin: [0.5, 0.5],
    image: { type: 'webp', quality: 1 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
  };

  constructor(
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private fileDownloadService: FileDownloadService,
  ) {}

  ngAfterViewInit(): void {
    const wrapper = document.getElementById(
      'wrapper-container' + this.archiveNumber,
    );

    const wrapperWidth = wrapper.offsetWidth;

    const listElement = wrapper.querySelectorAll(
      `#associated-files${this.archiveNumber}`,
    );
    const additionalAttachments = document.getElementById(
      'additional-attachments_' + this.archiveNumber,
    );

    if (listElement.length)
      listElement.forEach((ul) => {
        ul.setAttribute(
          'style',
          `width: ${
            wrapperWidth ? wrapperWidth - 60 + 'px' : 'calc(100vw - 470px)'
          };`,
        );
      });
    if (additionalAttachments)
      additionalAttachments.setAttribute(
        'style',
        `width: ${
          wrapperWidth ? wrapperWidth - 60 + 'px' : 'calc(100vw - 470px)'
        };`,
      );
  }

  getTechnicalFiles(files) {
    return files.filter(
      (files) => files.user?.roleId == UserRole.TechnicalAdvisor,
    );
  }

  getTrainingOrganizationFiles(files) {
    return files.filter(
      (files) => files.user?.roleId == UserRole.TrainingOrganization,
    );
  }

  getGeneralNotes() {
    return this.fieldVisitData?.fieldVisitFormNotes;
  }

  generateEvaluationPDF() {
    this.isDownloadDisabled = true;
    const element = document.querySelector(`.form${this.archiveNumber}`);
    const clone = element.cloneNode(true) as HTMLElement;
    clone.setAttribute(
      'style',
      `border: solid 1px black; padding: 20px; z-index: -100; position: relative;`,
    );
    clone.classList.add('evaluation-pdf');
    document.body.appendChild(clone);
    html2pdf()
      .from(clone)
      .set(this.evaluationPDFConfig)
      .save()
      .then(() => {
        // Show divs again
        document.body.removeChild(clone);
        this.isDownloadDisabled = false;
        this.cdr.detectChanges();
      });
  }

  onFileUpload(event, standardIndex) {
    const fieldVisitFiles =
      this.filesControlsArray.controls[standardIndex].value || [];
    if (event.target.files.length) fieldVisitFiles.push(...event.target.files);
    this.filesControlsArray.controls[standardIndex].setValue(fieldVisitFiles);
  }

  removeFile(index: number, standardIndex) {
    const fieldVisitFiles =
      this.filesControlsArray.controls[standardIndex]?.value;
    fieldVisitFiles.splice(index, 1);
    this.filesControlsArray.controls[standardIndex].setValue(fieldVisitFiles);
  }

  async downloadFile(file) {
    this.unClickable = true;
    if (this.userCanDownload)
      await this.fileDownloadService.downloadFile(file.id, file.fileName);
    this.unClickable = false;
  }
}
