import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { BodyScrollService } from '@app/dashboard/services/body-scroll.service';

@Component({
  selector: 'file-upload',
  templateUrl: './file-upload.component.html',
  styleUrl: './file-upload.component.scss',
})
export class FileUpload {
  @Input() id: string;
  @Input() title: string;
  @Input() popupSubtitle: string;
  @Input() subtitles: string[] = [];
  @Input() descriptionLines: any[] = [];
  @Input() control: FormControl;
  @Input() files: any[] = [];
  @Input() readonly: boolean = false;
  @Input() isRequired: boolean = true;
  @Input() errorMessage: string = 'هذا الحقل مطلوب';
  @Input() acceptPdfFilesOnly: boolean = true;
  @Input() modifyRemovedFiles: boolean = false;
  @Input() canReplaceFile: boolean = false;
  @Input() onFileClick: (id: string, index: number) => void = null;
  @Input() documentLookup: number;
  @Input() updateRequestNumber: number = 1;

  @Output() validationErrorEmitter: EventEmitter<any> = new EventEmitter();

  viewErrorMessage: boolean = false;

  @ViewChild('fileInput') fileInput: ElementRef;

  modalContent: 'information' | 'submission' = 'information'; // Set the default content to 'information'
  showModal = false;

  constructor(private bodyScrollService: BodyScrollService) {}

  onFileSelect(event: any): void {
    const selectedFile = event.target.files[0];

    if (selectedFile.type !== 'application/pdf' && this.acceptPdfFilesOnly) {
      this.errorMessage = 'يرجى تحميل ملفات بصيغة PDF فقط.';
      this.viewErrorMessage = true;
      return;
    }

    this.files = [...this.files, ...event.target.files];
    this.control.patchValue(this.files);
    this.fileInput.nativeElement.value = null;
    this.viewErrorMessage = false;
    this.validationErrorEmitter.emit({ [this.id]: false });
  }

  infoIconClickHandler() {
    this.bodyScrollService.toggleBodyScroll(false);
    this.showModal = true;
  }

  closeModal() {
    this.showModal = false;
    this.bodyScrollService.toggleBodyScroll(true);
  }

  replaceFile(event: any, fileIndex: number) {
    const selectedFile = event.target.files[0];

    if (this.acceptPdfFilesOnly && selectedFile.type !== 'application/pdf') {
      this.errorMessage = 'يرجى تحميل ملفات بصيغة PDF فقط.';
      this.viewErrorMessage = true;
      return;
    }

    const filesCloneArray = this.files.reduce((acc, file) => {
      if (
        (!file.trainingProgramDocumentMappingFileId &&
          !file.archivedFileId &&
          !(file instanceof File)) ||
        (!file.trainingProgramDocumentMappingFileId &&
          !file.archivedFiles?.length &&
          !(file instanceof File))
      )
        return acc;
      let fileObj: Record<any, any> = {};
      if (file instanceof File) {
        acc.push(file);
        return acc;
      }
      if (file.archivedFiles?.length) {
        fileObj = { ...file };
        fileObj['archivedFiles'] = [...file.archivedFiles];
      }
      if (file.file === '') {
        fileObj = { ...file };
      }
      acc.push(fileObj);
      return acc;
    }, []);

    const archivedFiles = filesCloneArray[fileIndex].archivedFiles;
    const lastArchivedFile = archivedFiles.at(-1);

    const newArchivedFile = {
      trainingProgramDocumentMappingFileId:
        filesCloneArray[fileIndex].trainingProgramDocumentMappingFileId,
      archivedFileId: 0,
      file: selectedFile,
    };

    if (!(lastArchivedFile?.archivedFileId > 0)) {
      // replace file that is NOT saved yet
      archivedFiles.pop();
      archivedFiles.push(newArchivedFile);
    } else if (this.updateRequestNumber == lastArchivedFile.fileVersion) {
      // replace already existing file
      archivedFiles[archivedFiles.length - 1] = {
        ...lastArchivedFile,
        fileId: '',
        file: selectedFile,
      };
    } else if (this.updateRequestNumber > lastArchivedFile.fileVersion) {
      archivedFiles.push(newArchivedFile);
    }

    this.control.patchValue(filesCloneArray);
    this.viewErrorMessage = false;
    this.validationErrorEmitter.emit({ [this.id]: false });
  }

  removeFile(fileIndex: number): void {
    const filesArray = [...this.files];
    if (this.modifyRemovedFiles && !(filesArray[fileIndex] instanceof File)) {
      filesArray[fileIndex] = {
        trainingProgramDocumentMappingFileId:
          filesArray[fileIndex]?.trainingProgramDocumentMappingFileId,
        archivedFileId:
          filesArray[fileIndex]?.archivedFiles?.at(-1).archivedFileId,
        file: '',
      };
    } else filesArray.splice(fileIndex, 1);

    this.control.patchValue(filesArray.length ? filesArray : []);
    if (!filesArray?.length || this.control.errors?.['requiredFiles']) {
      this.errorMessage = 'هذا الحقل مطلوب';
      this.viewErrorMessage = true;
      this.validationErrorEmitter.emit({ [this.id]: this.viewErrorMessage });
    }
  }
}
