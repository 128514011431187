import { Component } from '@angular/core';
import { ScrollToTopService } from '@app/dashboard/services/scroll-to-top.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrl: './privacy-policy.component.scss',
})
export class PrivacyPolicyComponent {
  public logoSvg = 'assets/images/logo.svg';

  constructor(private scrollService: ScrollToTopService) {}

  ngOnInit(): void {
    this.scrollService.scrollToTop();
  }
}
