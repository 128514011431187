import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-application-cards',
  templateUrl: './application-cards.component.html',
  styleUrls: ['./application-cards.component.scss'],
})
export class ApplicationCardsComponent {
  @Input() title: string = '';
  @Input() number: number = 0;
  @Input() link: string = '';

  constructor() {}
}
