import { Component, Input } from '@angular/core';
import { Breadcrumbs } from 'src/app/dashboard/types/breadcrumbs.type';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent {
  @Input() levels: Breadcrumbs[];
}
