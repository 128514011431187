<div *ngIf="noteData?.note; else fallbackNote" class="container">
  <div class="general-note-title">
    {{
      noteData.user.roleId == committeeSecretaryRoleId
        ? 'مرئيات الأمين'
        : 'ملاحظات فنية' + ' ' + '(' + noteData.user.role + ')'
    }}
    <img
      (click)="clickHandler()"
      class="edit-general-notes"
      *ngIf="showEditButton"
      [src]="editNotesSrc"
    />
  </div>
  <div
    [ngClass]="{ 'general-note-text': true, direction: noteControl.value }"
    [innerHTML]="noteControl.value || 'لا يوجد ملاحظات'"
    *ngIf="!editMode"
  ></div>
  <div class="edit-container" *ngIf="editMode">
    <app-rich-text-editor
      [control]="noteControl"
      (textValueEmitter)="textValue = $event"
    />
    <div class="buttons-container">
      <app-custom-button
        buttonText="حفظ"
        buttonStyle="greenButton"
        (click)="saveHandler()"
      />
      <app-custom-button
        buttonText="إلغاء"
        buttonStyle="transperantGreenButton"
        (click)="
          editMode = false;
          noteControl.setValue(noteData.note);
          textValue = noteData.note
        "
      />
    </div>
  </div>
  <div class="success-modal__container">
    <app-modal
      [modalContent]="'submission'"
      [showModal]="showModal"
      (closeModal)="showModal = false"
      [customClass]="'success-modal'"
      [isCloseVisible]="!(isLoading | async)"
    >
      <loading-spinner>جاري تحديث الملاحظة...</loading-spinner>
      <div class="modal-body" *ngIf="!(isLoading | async)">
        <app-svg-icon class="success-icon" name="success-icon"></app-svg-icon>
        <span class="modal-text">تم تعديل الملاحظة بنجاح</span>
      </div>
    </app-modal>
  </div>
</div>
<ng-template #fallbackNote>
  <div class="container">
    <div class="general-note-title">
      {{
        role == 'أمين اللجنة'
          ? 'مرئيات الأمين'
          : 'ملاحظات فنية' + ' ' + '(' + role + ')'
      }}
    </div>
    <div
      [ngClass]="{ 'general-note-text': true, direction: noteControl.value }"
      [innerHTML]="'لا يوجد ملاحظات'"
      *ngIf="!editMode"
    ></div>
  </div>
</ng-template>
