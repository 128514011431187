<div class="container">
  <loading-spinner></loading-spinner>
  <div *ngIf="tableData?.data" class="table-container">
    <app-table
      *ngIf="!(isLoading$ | async)"
      [isDateFilter]="true"
      [title]="'allOrders'"
      [headers]="headers"
      [data]="tableData?.data"
      [totalRecords]="tableData?.totalRecords"
      [options]="status"
    ></app-table>
  </div>
</div>
