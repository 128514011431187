import {
  Component,
  ChangeDetectionStrategy,
  Input,
  AfterViewInit,
} from '@angular/core';
import { NgxExtendedPdfViewerService } from 'ngx-extended-pdf-viewer';
import { PdfViewerSyncService } from '../pdf-viewer-sync.service';
import { Subscription } from 'rxjs';
import { AuditService } from '../dashboard/services/audit.service';
import { DocumentInspectorStateService } from '../services/document-inspector-state.service';

@Component({
  selector: 'extended-pdf-viewer',
  templateUrl: './extended-pdf-viewer.component.html',
  styleUrls: ['./extended-pdf-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExtendedPdfViewerComponent implements AfterViewInit {
  @Input() src: string;
  @Input() showToolbar: boolean = true;
  @Input() language: string = 'ar';
  @Input() trainingOrganizationId: number;
  @Input() trainingProgramDocumentsId: number;
  @Input() criteriaLookup: any[];
  @Input() fileIndex: number;
  @Input() height: string = 'auto';

  scrollSubscription: Subscription;
  highlightedDocument: any;

  constructor(
    private ngxService: NgxExtendedPdfViewerService,
    private pdfViewerSyncService: PdfViewerSyncService,
    private auditService: AuditService,
    private documentInspectorStateService: DocumentInspectorStateService,
  ) {}

  async handleSaveChanges() {
    const blob = await this.ngxService.getCurrentDocumentAsBlob();
    const fileName =
      this.documentInspectorStateService.trainingProgramDocumentMapping[
        this.documentInspectorStateService.tabDataIndex
      ].files[this.fileIndex].archivedFiles.at(-1).file.fileName;

    this.highlightedDocument = new File([blob], fileName, {
      type: blob.type,
    });

    const requestBody = this.getUpdatedSection();
    this.auditService
      .saveDocumentFileSection(requestBody)
      .subscribe((response) => {
        console.log(response);
      });
  }

  getUpdatedSection() {
    const currentTabIndex = this.documentInspectorStateService.tabDataIndex;
    const section =
      this.documentInspectorStateService.trainingProgramDocumentMapping[
        currentTabIndex
      ];

    const updatedFiles = section.files?.map((file, fileIndex) => {
      if (fileIndex === this.fileIndex) {
        const newArchivedFiles = [...file.archivedFiles];
        const lastArchivedFile = newArchivedFiles[newArchivedFiles.length - 1];

        newArchivedFiles[newArchivedFiles.length - 1] = {
          archivedFileId: lastArchivedFile?.archivedFileId || 0,
          file: this.highlightedDocument,
        };

        return {
          trainingProgramDocumentMappingFileId:
            file?.trainingProgramDocumentMappingFileId || 0,
          archivedFileId:
            newArchivedFiles[newArchivedFiles.length - 1].archivedFileId || 0,
          fileId: '',
          file: this.highlightedDocument,
        };
      } else {
        return {
          trainingProgramDocumentMappingFileId:
            file?.trainingProgramDocumentMappingFileId,
          archivedFileId: file?.archivedFileId,
          fileId: file?.fileId,
          file: file?.file,
        };
      }
    });

    return {
      id: this.trainingProgramDocumentsId,
      trainingOrganizationId: this.trainingOrganizationId,
      isSubmitted: true,
      programDocuments: [
        {
          programDocumentLookupId: section.programDocumentLookup.id,
          files: updatedFiles,
        },
      ],
    };
  }

  onZoomChange(zoomLevel: number | string) {
    this.pdfViewerSyncService.updateZoomLevel(zoomLevel);
  }

  onScroll = (event) => {
    const target = event?.target as HTMLElement;
    if (!target) return;

    const scrollPosition = {
      top: target?.scrollTop,
      left: target?.scrollLeft,
    };

    this.pdfViewerSyncService.updateScrollPosition(scrollPosition);
  };

  ngAfterViewInit() {
    const viewerElement = document.querySelector('#viewerContainer');
    if (!viewerElement) return;

    viewerElement.addEventListener('scroll', this.onScroll);

    this.scrollSubscription =
      this.pdfViewerSyncService.scrollPosition$.subscribe(({ top, left }) => {
        viewerElement.scrollTop = top;
        viewerElement.scrollLeft = left;
      });
  }

  ngOnDestroy() {
    const viewerElement = document.querySelector('#viewerContainer');

    if (viewerElement) {
      viewerElement.removeEventListener('scroll', this.onScroll);
    }

    if (this.scrollSubscription) {
      this.scrollSubscription.unsubscribe();
    }
  }
}
