import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-custom-accordion',
  templateUrl: './custom-accordion.component.html',
  styleUrls: ['./custom-accordion.component.scss'],
})
export class CustomAccordionComponent {
  @Input() title: string;
  @Input() data: Criteria[];
  @Input() customClass: string;
}

export interface Criteria {
  passed: boolean;
  note: string;
  title: string;
}
