export enum UserRole {
  TrainingOrganization = 1,
  SystemAdministrator = 2,
  InternalAuditor = 3,
  CommitteeSecretary = 4,
  CommitteeMember = 5,
  CommitteeHead = 6,
  TechnicalAdvisor = 7,
  FinancialAuditor = 8,
  GeneralManager = 9,
}
