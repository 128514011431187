export enum Status {
  Draft = 1,
  NewOrderWaitingInternalAuditor = 2,
  ApprovedByTheInternalAuditorWaitingCommitteeSecretary = 3,
  ApprovedByCommitteeSecretaryWaitingTechnicalExpert = 4,
  ApprovedByTechnicalExpertWaitingCommitteeSecretary = 5,
  ApprovedByCommitteeHeadWaitingCommitteeSecretary = 8,
  ApprovedByCommitteeSecretaryWaitingPayment = 9,
  ThePaymentWasMadeWaitingFinancialAuditor = 10,
  ApprovedByTheFinancialAuditorWaitingCommitteeSecretary = 11,
  ApprovedByCommitteeSecretaryWaitingGeneralManager = 12,
  ApprovedByTheGeneralManagerAndTheCertificateHasBeenIssued = 13,
  ReturnedByInternalAuditorToTrainingOrganization = 14,
  ReturnedByCommitteeSecrataryToInternalAuditor = 15,
  ReturnedByTechnicalAdvisorToInternalAuditor = 16,
  AddressedByTrainingOrganization = 17,
  DiscussionAndVoting = 18,
  ReturnedByCommitteeSecretaryToInternalAuditorAfterFormulationNote = 19,
  ReturnedByCommitteeSecretaryToTechnicalAdvisorAfterFormulationNote = 20,
  RejectedByCommitteeHeadToCommitteeSecretary = 21,
  RejectedByCommitteeSecretaryToTrainingOrganization = 22,
  RejectedByFinancialAuditorToTrainingOrganization = 23,
  ReturnedByCommitteeSecrataryAfterTechnicalAdvisor = 24,
  RenewalRequestFromTrainingOrganizationToInternalAuditor = 25,
  RenewalRequestHasBeenAcceptedByCommitteeSecretaryToGeneralManager = 26,
  CertificateHasBeenRenewedByGeneralManagerToTrainingOrganization = 27,
  RenewalRequestHasBeenRejectedByCommitteeHeadToCommitteeSecretary = 28,
}
