import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from '../components/shared.module';
import { PublicPageRoutingModule } from './public-page-routing.module';

import { HomepageComponent } from './components/homepage/homepage.component';
import { HomepageSubheaderComponent } from './components/homepage/homepage-subheader/homepage-subheader.component';
import { HomepageDocsComponent } from './components/homepage/homepage-docs/homepage-docs.component';
import { HomepageDocsCardComponent } from './components/homepage/homepage-docs/homepage-docs-card/homepage-docs-card.component';
import { CardsComponent } from './components/homepage/cards/cards.component';
import { PublicPagecomponent } from './public-page.component';

@NgModule({
  declarations: [
    CardsComponent,
    HomepageComponent,
    HomepageSubheaderComponent,
    HomepageDocsComponent,
    HomepageDocsCardComponent,
    PublicPagecomponent,
  ],
  imports: [BrowserModule, SharedModule, PublicPageRoutingModule],
  exports: [],
  providers: [],
})
export class PublicPageModule {}
