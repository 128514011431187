import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BodyScrollService {
  public modalScrollEnabled: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  private scrollingEnabled = true;

  toggleBodyScroll(enableScroll: boolean) {
    this.scrollingEnabled = enableScroll;
    this.modalScrollEnabled.emit(enableScroll);
  }

  isScrollingEnabled(): boolean {
    return this.scrollingEnabled;
  }
}
