<mat-accordion>
  <mat-expansion-panel [ngClass]="customClass">
    <mat-expansion-panel-header>
      <mat-panel-title> {{ title }}</mat-panel-title>
    </mat-expansion-panel-header>
    <div class="accordion-content">
      <div class="criteria-container" *ngFor="let item of data">
        <div class="criteria-title">
          {{ item.title
          }}<app-svg-icon
            *ngIf="!item.passed"
            name="close-icon"
            class="close-icon"
          ></app-svg-icon>
          <app-svg-icon
            *ngIf="item.passed"
            name="check-mark"
            class="check-mark"
          ></app-svg-icon>
        </div>
        <div class="criteria-note">
          {{ item.note }}
        </div>
        <app-form-input
          [placeholderText]="'الملاحظات'"
          [valueToCheck]="'textInput'"
          [customClass]="'criteria-input'"
        ></app-form-input>
      </div>
      <ng-content></ng-content>
    </div>
  </mat-expansion-panel>
</mat-accordion>
