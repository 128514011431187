<!-- <div class="sidebar-items__container">
    <a
      class="sidebar-item"
      *ngFor="let item of userItems"
      routerLink="{{ item.route }}"
      [ngClass]="{ active: activeRoute === item.route }"
      routerLinkActive="active"
    >
      <span
        class="sidebar-item__icon"
        [innerHTML]="sanitizeSvg(item.svg)"
      ></span>
      <span class="sidebar-item__text">{{ item.text | translate }}</span>
    </a>
    <div class="navigation-items">
      <button class="btn help-btn">
        <app-svg-icon class="help-icon" name="help-icon"></app-svg-icon>
      </button>
      <div class="pipe"></div>
      <button class="btn notification-btn">
        <app-svg-icon
          class="notification-icon"
          name="notification-icon-with-circle"
        ></app-svg-icon>
      </button>
      <div class="pipe"></div>
      <div class="custom-dropdown" (click)="toggleSelect()">
        <span>
          <app-svg-icon class="arrow-down" name="arrow-down"></app-svg-icon>
        </span>
        <div
          class="user-guide"
          *ngIf="showSelect"
          (click)="$event.stopPropagation()"
        >
          {{ "userGuide" | translate }}
        </div>
      </div>
    </div>
  </div> -->
