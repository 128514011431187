<app-header></app-header>
<div class="restore-password__container">
  <div class="form-container restore-password-container__form">
    <h1 class="restore-password-form__title">استعادة كلمة المرور</h1>
    <form [formGroup]="forgotPassword" (ngSubmit)="onSubmit()">
      <label class="form-label" for="email">اسم المستخدم</label>
      <input
        type="email"
        id="email"
        class="form-input"
        formControlName="email"
        required
        placeholder="example@example.com"
      />
      <div
        class="error"
        *ngIf="
          (forgotPassword.get('email')?.hasError('email') &&
            forgotPassword.get('email')?.touched) ||
          (!forgotPassword.get('email').value.length &&
            forgotPassword.get('email')?.touched)
        "
      >
        الرجاء ادخال بريد الكتروني صحيح
      </div>
      <div *ngIf="errorMessage" class="error">{{ errorMessage }}</div>

      <button
        [disabled]="submitButtonIsDisabled"
        class="submit-btn"
        type="submit"
      >
        إرسال
      </button>
    </form>
  </div>
  <div class="image-container">
    <img src="assets/images/login.png" alt="Image" />
  </div>
</div>
