<app-header></app-header>
<div class="signIn-container">
  <div class="form-container signIn-container__form">
    <h1 class="signIn-form__title">{{ 'signin' | translate }}</h1>
    <form [formGroup]="signInForm" (ngSubmit)="onSubmit()">
      <label class="form-label" for="email">اسم المستخدم</label>
      <input
        type="email"
        id="email"
        class="form-input"
        formControlName="email"
        required
        placeholder="example@example.com"
      />
      <div
        class="error"
        *ngIf="
          signInForm.get('email')?.hasError('email') &&
          signInForm.get('email')?.touched
        "
      >
        الرجاء ادخال بريد الكتروني صحيح
      </div>
      <div class="password-container">
        <label class="form-label" for="password">{{
          'password' | translate
        }}</label>
        <input
          type="{{ showPassword ? 'text' : 'password' }}"
          id="password"
          class="form-input"
          formControlName="password"
          required
          placeholder="{{ 'password' | translate }}"
        />
        <app-svg-icon
          *ngIf="showPassword"
          name="hide-eye"
          class="hide-eye"
          (click)="togglePassword()"
        />
        <app-svg-icon
          *ngIf="!showPassword"
          name="eye-icon"
          class="eye-icon"
          (click)="togglePassword()"
        />
      </div>
      <a routerLink="/forgot-password" class="forgot-password__text">{{
        'didYouForgetYourPassword' | translate
      }}</a>
      <span class="error" *ngIf="errorLoginText"> {{ errorLoginText }} </span>
      <button
        [disabled]="signInButtonIsDisabled"
        type="submit"
        class="submit-btn"
      >
        {{ 'signin' | translate }}
      </button>
      <div class="create-account__text">
        {{ 'dontHaveAccount' | translate }}
        <a class="create-account" routerLink="/signup">{{
          'createNewAccount' | translate
        }}</a>
      </div>
    </form>
  </div>
  <div class="image-container">
    <img src="assets/images/login.png" alt="Image" />
  </div>
</div>
