<div class="intro-card__container">
  <div class="inner-container">
    <h2 class="intro-card__welcome">{{ 'welcome' | translate }}</h2>
    <span class="intro-card__text">{{ 'introText' | translate }}</span>
    <div class="btns-container">
      <app-custom-button
        [buttonStyle]="'transperantButton'"
        [buttonText]="'trainingProgramBtn'"
      ></app-custom-button>
      <app-custom-button
        [buttonStyle]="'transperantButton'"
        [buttonText]="'createAnApplication'"
      ></app-custom-button>
    </div>
  </div>
</div>
