import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { NotificationsService } from '@app/dashboard/components/shared/notifications-drop-down/notifications.service';
import { HeaderService } from '@app/services/header.service';
import { UserRole } from '@app/types/roles';
import { filesAssets } from '@assets/files';
import { MenuItem } from 'primeng/api';
import { Menu } from 'primeng/menu';
import { Subscription } from 'rxjs';
import { ModalService } from 'src/app/dashboard/services/modal.service';
import { AuthGuard } from 'src/app/guards/auth.guard';
import { AuthService } from 'src/app/services/auth.service';
@Component({
  selector: 'app-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrl: './sub-header.component.scss',
})
export class SubHeaderComponent implements OnInit, OnDestroy {
  @Output() resetIsUserLogin = new EventEmitter<boolean>();

  items: MenuItem[] | undefined;
  isLoggedIn: boolean;
  isMenuOpen = false;
  isModalOpen = false;
  isTrainingOrganizationRole: boolean;
  pdfURL: string;
  title: string = '';
  showSelect = false;
  urlPathname = window.location.pathname;
  pathnameSegments = this.urlPathname.split('/');
  lastSegment = '/' + this.pathnameSegments[this.pathnameSegments.length - 1];
  isNotificationOpen: boolean = false;
  @ViewChild('menu') menu: Menu;
  private subscription: Subscription;

  links = [
    {
      title: 'home',
      routerLink: '/home',
    },
    {
      title: 'عن الاعتماد',
      routerLink: '/our-services',
    },
    {
      title: 'اللائحة',
      onClick: 'regulationsTab',
    },
    {
      title: 'الدليل الارشادي',
      onClick: 'guideTab',
    },
  ];

  toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }

  constructor(
    private authService: AuthService,
    public modalService: ModalService,
    private renderer: Renderer2,
    private authGuard: AuthGuard,
    private headerService: HeaderService,
    public notificationService: NotificationsService,
  ) {
    this.modalService.showModal$.subscribe((isOpen) => {
      if (isOpen) {
        this.isModalOpen = isOpen;

        this.renderer.addClass(document.body, 'modal-open'); // Add the class
      } else {
        this.isModalOpen = isOpen;

        this.renderer.removeClass(document.body, 'modal-open'); // Remove the class
      }
    });
    this.isLoggedIn = this.authService.isUserAuthenticated();
  }

  handleTabClick(value: string) {
    this.pdfURL =
      value === 'regulationsTab'
        ? filesAssets.trainingPrograms
        : filesAssets.userGuide;
    this.title =
      value === 'regulationsTab'
        ? 'لائحة اعتماد البرامج التدريبية'
        : 'الدليل الإرشادي';
    this.modalService.openModal();
  }

  ngOnInit() {
    this.isTrainingOrganizationRole = this.authService.hasRole(
      UserRole.TrainingOrganization,
    );
    this.items = [
      {
        label: 'لوحة المعلومات',
        icon: 'pi pi-table',
        command: () => {
          this.headerService.redirectToDashboard();
        },
      },
      {
        label: 'تسجيل الخروج',
        icon: 'pi pi-sign-out',
        command: () => {
          this.handleLogout(event);
        },
      },
    ];

    this.isTrainingOrganizationRole &&
      this.items.splice(1, 0, {
        label: 'الصفحة الشخصية',
        icon: 'pi pi-user',
        command: () => {
          this.headerService.redirectToProfilePage();
        },
      });

    this.subscription = this.notificationService.isNotificationOpen.subscribe(
      (state) => {
        this.isNotificationOpen = state;
      },
    );
  }

  handleLogout(event: Event) {
    this.authGuard.setRedirectionUrl('');
    event.stopPropagation();
    this.authService.logOut().subscribe({
      next: (response) => {
        if (response.errorCode === 0) {
          this.resetIsUserLogin.emit(!this.isLoggedIn);
          this.isLoggedIn = !this.isLoggedIn;
          this.headerService.clearLocalStorage();
        }
      },
    });
  }

  handleUserMenuClick(event: Event) {
    this.isNotificationOpen = false;
    this.menu.toggle(event);
  }

  handleDivClick(event: Event) {
    event.stopPropagation();
    this.pdfURL = filesAssets.userGuide;
    this.title = 'الدليل الارشادي';
    this.showSelect = false;
    this.modalService.openModal();
  }

  handleNotificationClick(event: Event) {
    event.stopPropagation();
    this.notificationService.toggleNotificationDropdown(event);
    if (this.menu && this.menu.overlayVisible) {
      this.menu.hide();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
