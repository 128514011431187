import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { AuthService } from 'src/app/services/auth.service';
import { ModalService } from '@app/dashboard/services/modal.service';
import { AuthGuard } from 'src/app/guards/auth.guard';
import { filesAssets } from '@assets/files';
import { UserRole } from '@app/types/roles';
import { HeaderService } from '@app/services/header.service';
import { Menu } from 'primeng/menu';
import { Subscription } from 'rxjs';
import { NotificationsService } from '../shared/notifications-drop-down/notifications.service';

@Component({
  selector: 'app-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.scss'],
})
export class DashboardHeaderComponent implements OnInit {
  items: MenuItem[] | undefined;
  isMenuOpen = false;
  showSelect = false;
  searchQuery: string = '';
  isModalOpen = false; // Add this variable
  pdfURL = filesAssets.userGuide;
  isNotificationOpen: boolean = false;
  isTrainingOrganizationRole: boolean;
  hasNotifications = false;
  @ViewChild('menu') menu: Menu;
  private subscription: Subscription;

  constructor(
    private authService: AuthService,
    private router: Router,
    public modalService: ModalService,
    private renderer: Renderer2,
    private authGuard: AuthGuard,
    private headerService: HeaderService,
    public notificationService: NotificationsService,
  ) {
    this.modalService.showModal$.subscribe((isOpen) => {
      if (isOpen) {
        this.isModalOpen = isOpen;

        this.renderer.addClass(document.body, 'modal-open'); // Add the class
      } else {
        this.isModalOpen = isOpen;

        this.renderer.removeClass(document.body, 'modal-open'); // Remove the class
      }
    });
  }

  ngOnInit() {
    this.isTrainingOrganizationRole = this.authService.hasRole(
      UserRole.TrainingOrganization,
    );
    this.items = [
      {
        label: 'لوحة المعلومات',
        icon: 'pi pi-table',
        command: () => {
          this.headerService.redirectToDashboard();
        },
      },
      {
        label: 'تسجيل الخروج',
        icon: 'pi pi-sign-out',
        command: () => {
          this.handleLogout(event);
        },
      },
    ];
    this.isTrainingOrganizationRole &&
      this.items.splice(1, 0, {
        label: 'الصفحة الشخصية',
        icon: 'pi pi-user',
        command: () => {
          this.headerService.redirectToProfilePage();
        },
      });

    this.subscription = this.notificationService.isNotificationOpen.subscribe(
      (state) => {
        this.isNotificationOpen = state;
      },
    );
  }

  handleLogout(event: Event) {
    this.authGuard.setRedirectionUrl('');
    event.stopPropagation();
    this.authService.logOut().subscribe({
      next: (response) => {
        if (response.errorCode === 0) {
          this.router.navigate(['/home']);
          this.headerService.clearLocalStorage();
        }
      },
    });
  }

  onUserGuideClick() {
    this.showSelect = false;
    this.modalService.openModal();
    this.isNotificationOpen = false;
  }

  onSearch() {
    console.log('search');
  }

  toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
    this.isNotificationOpen = false;
  }

  handleNotificationClick(event: Event) {
    event.stopPropagation();
    this.notificationService.toggleNotificationDropdown(event);
    if (this.menu && this.menu.overlayVisible) {
      this.menu.hide();
    }
  }

  handleUserMenuClick(event: Event) {
    this.isNotificationOpen = false;
    this.menu.toggle(event);
  }

  handleDivClick(event: Event) {
    event.stopPropagation();
    this.isNotificationOpen = false;
    this.onUserGuideClick();
  }
}
