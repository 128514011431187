import {
  Component,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'app-navigation-buttons',
  templateUrl: './navigation-buttons.component.html',
  styleUrls: ['./navigation-buttons.component.scss'],
})
export class NavigationButtonsComponent {
  @ViewChild('tabsContainer') tabsContainer: ElementRef;
  @Input() activeTabIndex: number = 0;
  @Input() documentArrow: boolean;
  @Input() tabs: any[] = [];
  @Output() nextTab: EventEmitter<void> = new EventEmitter<void>();
  @Output() previousTab: EventEmitter<void> = new EventEmitter<void>();
  @Output() nextFile: EventEmitter<void> = new EventEmitter<void>();
  @Output() previousFile: EventEmitter<void> = new EventEmitter<void>();
}
