import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomepageComponent } from './components/homepage/homepage.component';

const publicPageRoutes: Routes = [
  {
    path: 'home',
    component: HomepageComponent,
  },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', redirectTo: '/home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forChild(publicPageRoutes)],
  exports: [RouterModule],
})
export class PublicPageRoutingModule {}
