<div class="card-container" [ngClass]="{ 'has-link': link }">
  <div class="card-text__container">
    <span class="card-text__title">{{ title }}</span>
  </div>
  <span *ngIf="number" class="card-text__number">{{ number }}</span>
  <a
    class="card-link"
    routerLink="/dashboard/accreditation-form"
    *ngIf="link"
    >{{ link }}</a
  >
</div>
