<div
  class="modal-overlay"
  [class.show-modal]="openModal"
  [ngClass]="customClass"
>
  <div class="modal">
    <div class="modal-content">
      <div class="pdf-container">
        <div class="pdf-container__content">
          <div class="pdf-title__container">
            <div class="title-container">
              <app-svg-icon
                (click)="closeInspector()"
                class="close-icon"
                name="close-icon"
              ></app-svg-icon>
              <div class="pdf-title">
                {{
                  documentInspectorStateService
                    .trainingProgramDocumentMapping?.[
                    documentInspectorStateService?.tabDataIndex
                  ]?.programDocumentLookup.name
                }}
                <svg
                  *ngIf="
                    !!documentInspectorStateService
                      .trainingProgramDocumentMapping?.[
                      documentInspectorStateService?.tabDataIndex
                    ]?.programDocumentLookup?.programDocumentRequirements
                      ?.length
                  "
                  (click)="infoIconClickHandler()"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <use [attr.xlink:href]="'#info-icon'"></use>
                </svg>
              </div>
            </div>
            <div class="section-navigation">
              <div class="section-navigation__container">
                <app-svg-icon
                  *ngIf="!!startIndex"
                  name="circle-arrow"
                  class="arrow-prev arrow"
                  (click)="previousPage()"
                ></app-svg-icon>
                <div
                  class="section-index"
                  *ngFor="let item of getVisibleItems(); let i = index"
                  (click)="onSectionIndexClick(startIndex + i)"
                  [ngClass]="{
                    activeBtn:
                      startIndex + i ===
                      documentInspectorStateService.tabDataIndex
                  }"
                >
                  <div class="index">{{ startIndex + i + 1 }}</div>
                </div>
                <app-svg-icon
                  *ngIf="hasNextPage()"
                  name="circle-arrow"
                  class="arrow-next arrow"
                  (click)="nextSection()"
                ></app-svg-icon>
              </div>
            </div>
            <app-modal
              [showModal]="showModal"
              (closeModal)="closeInfoModal()"
              [customClass]="'documents-modal'"
            >
              <div class="modal-header">
                <h2>
                  {{
                    documentInspectorStateService
                      .trainingProgramDocumentMapping?.[
                      documentInspectorStateService?.tabDataIndex
                    ]?.programDocumentLookup.name
                  }}
                </h2>
              </div>
              <div class="requirements-modal-body">
                <ul>
                  <li
                    *ngFor="
                      let subtitle of documentInspectorStateService
                        .trainingProgramDocumentMapping?.[
                        documentInspectorStateService?.tabDataIndex
                      ]?.programDocumentLookup?.programDocumentRequirements
                    "
                  >
                    {{ subtitle }}
                  </li>
                </ul>
              </div>
            </app-modal>
          </div>
          <app-svg-icon
            name="long-two-sided-arrow"
            class="two-sided-arrow__desktop"
            [desiredWidth]="'100%'"
            [desiredHeight]="'100%'"
          ></app-svg-icon>
          <div
            class="pdf-viewer__container"
            [class.pdf-viewer__container-no-padding]="
              currentSectionHasPdfFiles()
            "
            [ngClass]="{
              'pdf-viewer__container-no-padding': currentSectionHasPdfFiles(),
              'is-comparison-mode':
                currentSectionHasPDFComparison() ||
                documentInspectorStateService.tabDataIndex === 0
            }"
          >
            <ng-content *ngIf="!currentSectionHasPdfFiles()"></ng-content>
            <loading-spinner
              *ngIf="!saveEvaluationIsClicked && !showPledge"
            ></loading-spinner>
            <extended-pdf-viewer
              *ngIf="
                currentSectionHasPdfFiles() &&
                (!(isLoading | async) || saveEvaluationIsClicked) &&
                fileURL
              "
              [src]="fileURL"
              class="extended-pdf-viewer"
              [trainingOrganizationId]="trainingOrganizationId"
              [trainingProgramDocumentsId]="trainingProgramDocumentsId"
              [criteriaLookup]="criteriaLookup"
              [fileIndex]="fileIndex"
              [showToolbar]="canUserHighlight()"
              [height]="currentSectionHasPDFComparison() ? '48%' : '100%'"
            >
            </extended-pdf-viewer>
            <pdf-viewer
              *ngIf="
                currentSectionHasPdfFiles() &&
                (!(isLoading | async) || saveEvaluationIsClicked) &&
                currentSectionHasPDFComparison()
              "
              [src]="oldFileUrl"
              [show-all]="true"
              [zoom]="zoomLevel || 1"
              [zoom-scale]="'page-width'"
              style="height: 48%"
            >
            </pdf-viewer>
          </div>

          <div class="documents-navigation__container">
            <div class="section-navigation__container">
              <app-svg-icon
                *ngIf="
                  documentInspectorStateService
                    .trainingProgramDocumentMapping?.[
                    documentInspectorStateService?.tabDataIndex
                  ]?.files?.length > 1
                "
                name="circle-arrow"
                class="arrow-prev arrow"
                (click)="prevFile()"
              ></app-svg-icon>
              <div
                class="section-index"
                *ngFor="let item of getFilesPaginationItems(); let i = index"
                (click)="viewFile(i)"
                [ngClass]="{ activeBtn: fileStartIndex + i === fileIndex }"
              >
                <div class="index">{{ fileStartIndex + i + 1 }}</div>
              </div>
              <app-svg-icon
                *ngIf="
                  documentInspectorStateService
                    .trainingProgramDocumentMapping?.[
                    documentInspectorStateService?.tabDataIndex
                  ]?.files?.length > 1
                "
                name="circle-arrow"
                class="arrow-next arrow"
                (click)="nextFile()"
              ></app-svg-icon>
            </div>
            <app-custom-button
              *ngIf="
                documentInspectorStateService.trainingProgramDocumentMapping?.[
                  documentInspectorStateService?.tabDataIndex
                ]?.files?.length
              "
              [buttonText]="
                ' الملف ' +
                (fileIndex + 1) +
                ' من ' +
                documentInspectorStateService.trainingProgramDocumentMapping?.[
                  documentInspectorStateService?.tabDataIndex
                ]?.files?.length
              "
              [buttonStyle]="'fadedGreenBtn'"
            ></app-custom-button>
          </div>
        </div>
      </div>

      <!-- criteria -->
      <div
        class="criteria-col"
        [class.extended-pdf-comparison]="
          currentSectionHasPDFComparison() ||
          documentInspectorStateService.tabDataIndex === 0
        "
      >
        <div class="criteria-col-title">
          {{
            documentInspectorStateService.trainingProgramDocumentMapping?.[
              documentInspectorStateService?.tabDataIndex
            ]?.programDocumentLookup.name
          }}
        </div>
        <p-tabView
          *ngIf="showCriteriasInTabs()"
          [(activeIndex)]="activeTabIndex"
        >
          <p-tabPanel header="معايير المدقق الداخلي">
            <form
              [formGroup]="
                evaluationForms[documentInspectorStateService?.tabDataIndex]
              "
              (ngSubmit)="saveEvaluation()"
            >
              <div class="criteria-content">
                <div
                  *ngIf="
                    !filterCriteriaMapping(
                      criteriaList?.[
                        documentInspectorStateService?.tabDataIndex
                      ],
                      internalAuditorRoleId
                    )?.length
                  "
                  class="criteria-item file-is-reviewed"
                >
                  <input
                    *ngIf="isCheckbox"
                    class="checkbox"
                    type="checkbox"
                    [formControl]="
                      evaluationForms[
                        documentInspectorStateService?.tabDataIndex
                      ].get('fileHasBeenReviewed')
                    "
                  />
                  <span
                    *ngIf="
                      isCheckbox || roleHasReviewedTab(internalAuditorRoleId)
                    "
                    >تم مراجعة الملف</span
                  >
                  <img
                    *ngIf="
                      !isCheckbox && roleHasReviewedTab(internalAuditorRoleId)
                    "
                    src="assets/images/checked.svg"
                  />
                </div>
                <div
                  class="criteria-items"
                  *ngIf="
                    criteriaList?.[documentInspectorStateService?.tabDataIndex]
                      ?.length
                  "
                >
                  <div
                    class="criteria-item__container"
                    *ngFor="
                      let item of filterCriteriaMapping(
                        criteriaList?.[
                          documentInspectorStateService?.tabDataIndex
                        ],
                        internalAuditorRoleId
                      );
                      let i = index
                    "
                  >
                    <div class="criteria-item">
                      <input
                        *ngIf="isCheckbox"
                        class="checkbox"
                        type="checkbox"
                        [checked]="item.isPassed"
                        [value]="item.documentCriteriaLookup.name"
                        [formControl]="
                          evaluationForms[
                            documentInspectorStateService?.tabDataIndex
                          ].get(
                            'criteriaItems.' +
                              item.programDocumentCriteriaMappingsId +
                              '.checkbox'
                          )
                        "
                      />

                      <span
                        [ngClass]="{
                          passed: item.isPassed,
                          rejected: !item.isPassed
                        }"
                        >{{ item.documentCriteriaLookup.name }}</span
                      >
                      <img
                        *ngIf="!isCheckbox && item.isPassed"
                        src="assets/images/checked.svg"
                      />
                      <img
                        *ngIf="!isCheckbox && !item.isPassed"
                        src="assets/images/reject-icon.svg"
                        class="reject-icon"
                      />
                    </div>
                    <div
                      class="notes-wrapper"
                      *ngIf="!(isLoading | async) || saveEvaluationIsClicked"
                    >
                      <app-prev-note
                        [showUserName]="!userIsTrainingOrg"
                        [notes]="
                          filterCriteriaById(
                            allNotes,
                            item.programDocumentCriteriaMappingsId
                          )[0]?.documentCriteriaLookup?.criteriaNotes
                        "
                        [isDocumentInspector]="true"
                      ></app-prev-note>
                    </div>
                    <input
                      *ngIf="isCheckbox || showCriteriaNotesField"
                      type="text"
                      placeholder="الملاحظات"
                      [formControl]="
                        evaluationForms[
                          documentInspectorStateService?.tabDataIndex
                        ].get(
                          'criteriaItems.' +
                            item.programDocumentCriteriaMappingsId +
                            '.note'
                        )
                      "
                      class="criteria-input"
                      [ngClass]="{
                        'invalid-criteria-input': isInputInvalid(
                          item?.programDocumentCriteriaMappingsId
                        )
                      }"
                    />
                    <span
                      *ngIf="
                        isInputInvalid(item?.programDocumentCriteriaMappingsId)
                      "
                      class="error-msg"
                    >
                      * هذا الحقل مطلوب
                    </span>
                  </div>
                </div>

                <!-- other notes -->
                <app-custom-button
                  *ngIf="isCheckbox"
                  [hasText]="false"
                  [buttonStyle]="'greenButton'"
                  [customClass]="'add-criteria__btn'"
                  (click)="criteriaDropdown($event)"
                >
                  <app-svg-icon name="plus-icon" class="plus-icon" />إضافة معيار
                </app-custom-button>
                <div class="add-criteria__dropdown" *ngIf="criteriaListIsOpen">
                  <p-accordion
                    *ngFor="let item of filterCriteriasDropDownData()"
                  >
                    <p-accordionTab
                      [ngClass]="customClass"
                      [header]="item.programDocuments.value"
                    >
                      <div class="accordion-content">
                        <div
                          class="accordion-item"
                          (click)="selectItem(listItem)"
                          *ngFor="let listItem of item.programDocumentCriteria"
                        >
                          {{ listItem.value }}
                        </div>
                      </div>
                    </p-accordionTab>
                  </p-accordion>
                </div>
                <app-svg-icon
                  *ngIf="showCriteriaNotesField"
                  name="two-sided-arrow"
                  class="two-sided-arrow"
                ></app-svg-icon>
                <div
                  class="other-notes__container"
                  *ngIf="showCriteriaNotesField"
                >
                  <label class="other-notes__title">ملاحظات أخرى</label>
                  <textarea
                    [rows]="10"
                    [cols]="30"
                    placeholder="الملاحظات"
                    class="criteria-input"
                    [formControl]="
                      evaluationForms[
                        documentInspectorStateService?.tabDataIndex
                      ]?.get('otherNotes')
                    "
                  ></textarea>
                </div>
                <app-custom-button
                  *ngIf="showCriteriaNotesField"
                  [disabled]="
                    evaluationForms[documentInspectorStateService?.tabDataIndex]
                      ?.pristine || savingEvaluation
                  "
                  [buttonText]="'حفظ'"
                  [buttonStyle]="'greenButton'"
                  [type]="'submit'"
                ></app-custom-button>
              </div>
            </form>
          </p-tabPanel>
          <p-tabPanel header="معايير الخبير الفني">
            <form
              [formGroup]="
                evaluationForms[documentInspectorStateService?.tabDataIndex]
              "
              (ngSubmit)="saveEvaluation()"
            >
              <div class="criteria-content">
                <div
                  *ngIf="
                    !filterCriteriaMapping(
                      criteriaList?.[
                        documentInspectorStateService?.tabDataIndex
                      ],
                      technicalAdvisorRoleId
                    )?.length
                  "
                  class="criteria-item file-is-reviewed"
                >
                  <input
                    *ngIf="isCheckbox"
                    class="checkbox"
                    type="checkbox"
                    [formControl]="
                      evaluationForms[
                        documentInspectorStateService?.tabDataIndex
                      ].get('fileHasBeenReviewed')
                    "
                  />
                  <span
                    *ngIf="
                      isCheckbox || roleHasReviewedTab(technicalAdvisorRoleId)
                    "
                    >تم مراجعة الملف</span
                  >
                  <img
                    *ngIf="
                      !isCheckbox && roleHasReviewedTab(technicalAdvisorRoleId)
                    "
                    src="assets/images/checked.svg"
                  />
                </div>
                <div
                  class="criteria-items"
                  *ngIf="
                    criteriaList?.[documentInspectorStateService?.tabDataIndex]
                      ?.length
                  "
                >
                  <div
                    class="criteria-item__container"
                    *ngFor="
                      let item of filterCriteriaMapping(
                        criteriaList?.[
                          documentInspectorStateService?.tabDataIndex
                        ],
                        technicalAdvisorRoleId
                      );
                      let i = index
                    "
                  >
                    <div class="criteria-item">
                      <input
                        *ngIf="isCheckbox"
                        class="checkbox"
                        type="checkbox"
                        [checked]="item.isPassed"
                        [value]="item.documentCriteriaLookup.name"
                        [formControl]="
                          evaluationForms[
                            documentInspectorStateService?.tabDataIndex
                          ].get(
                            'criteriaItems.' +
                              item.programDocumentCriteriaMappingsId +
                              '.checkbox'
                          )
                        "
                      />

                      <span
                        [ngClass]="{
                          passed: item.isPassed,
                          rejected: !item.isPassed
                        }"
                        >{{ item.documentCriteriaLookup.name }}</span
                      >
                      <img
                        *ngIf="!isCheckbox && item.isPassed"
                        src="assets/images/checked.svg"
                      />
                      <img
                        *ngIf="!isCheckbox && !item.isPassed"
                        src="assets/images/reject-icon.svg"
                        class="reject-icon"
                      />
                    </div>
                    <div
                      class="notes-wrapper"
                      *ngIf="!(isLoading | async) || saveEvaluationIsClicked"
                    >
                      <app-prev-note
                        [showUserName]="!userIsTrainingOrg"
                        [notes]="
                          filterCriteriaById(
                            allNotes,
                            item.programDocumentCriteriaMappingsId
                          )[0]?.documentCriteriaLookup?.criteriaNotes
                        "
                        [isDocumentInspector]="true"
                      ></app-prev-note>
                    </div>
                    <input
                      *ngIf="isCheckbox || showCriteriaNotesField"
                      type="text"
                      placeholder="الملاحظات"
                      [formControl]="
                        evaluationForms[
                          documentInspectorStateService?.tabDataIndex
                        ].get(
                          'criteriaItems.' +
                            item.programDocumentCriteriaMappingsId +
                            '.note'
                        )
                      "
                      class="criteria-input"
                      [ngClass]="{
                        'invalid-criteria-input': isInputInvalid(
                          item?.programDocumentCriteriaMappingsId
                        )
                      }"
                    />
                    <span
                      *ngIf="
                        isInputInvalid(item?.programDocumentCriteriaMappingsId)
                      "
                      class="error-msg"
                    >
                      * هذا الحقل مطلوب
                    </span>
                  </div>
                </div>
                <app-custom-button
                  *ngIf="isCheckbox"
                  [hasText]="false"
                  [buttonStyle]="'greenButton'"
                  [customClass]="'add-criteria__btn'"
                  (click)="criteriaDropdown($event)"
                >
                  <app-svg-icon
                    name="plus-icon"
                    class="plus-icon"
                  ></app-svg-icon
                  >إضافة معيار
                </app-custom-button>
                <div class="add-criteria__dropdown" *ngIf="criteriaListIsOpen">
                  <p-accordion
                    *ngFor="let item of filterCriteriasDropDownData()"
                  >
                    <p-accordionTab
                      [ngClass]="customClass"
                      [header]="item.programDocuments.value"
                    >
                      <div class="accordion-content">
                        <div
                          class="accordion-item"
                          (click)="selectItem(listItem)"
                          *ngFor="let listItem of item.programDocumentCriteria"
                        >
                          {{ listItem.value }}
                        </div>
                      </div>
                    </p-accordionTab>
                  </p-accordion>
                </div>
                <app-svg-icon
                  *ngIf="showCriteriaNotesField"
                  name="two-sided-arrow"
                  class="two-sided-arrow"
                />
                <div
                  class="other-notes__container"
                  *ngIf="showCriteriaNotesField"
                >
                  <label class="other-notes__title">ملاحظات أخرى</label>
                  <textarea
                    [rows]="10"
                    [cols]="30"
                    placeholder="الملاحظات"
                    class="criteria-input"
                    [formControl]="
                      evaluationForms[
                        documentInspectorStateService?.tabDataIndex
                      ]?.get('otherNotes')
                    "
                  ></textarea>
                </div>
                <app-custom-button
                  *ngIf="showCriteriaNotesField"
                  [disabled]="
                    evaluationForms[documentInspectorStateService?.tabDataIndex]
                      ?.pristine || savingEvaluation
                  "
                  [buttonText]="'حفظ'"
                  [buttonStyle]="'greenButton'"
                  [type]="'submit'"
                />
              </div>
            </form>
          </p-tabPanel>
        </p-tabView>
        <form
          *ngIf="!showCriteriasInTabs()"
          [formGroup]="
            evaluationForms[documentInspectorStateService.tabDataIndex]
          "
          (ngSubmit)="saveEvaluation()"
        >
          <div
            class="internal-auditor-criteria-title"
            *ngIf="
              !isCheckbox &&
              ((!userIsTrainingOrg && !userIsCommitteeSecretary) ||
                (userIsCommitteeSecretary &&
                  roleHasReviewedTab(technicalAdvisorRoleId, 0)))
            "
          >
            معايير المدقق الداخلي
          </div>
          <div class="criteria-content">
            <div
              *ngIf="
                !criteriaList?.[documentInspectorStateService?.tabDataIndex]
                  ?.length
              "
              class="criteria-item file-is-reviewed"
            >
              <div class="control">
                <input
                  *ngIf="isCheckbox"
                  class="checkbox"
                  type="checkbox"
                  required
                  [formControl]="
                    evaluationForms[
                      documentInspectorStateService?.tabDataIndex
                    ].get('fileHasBeenReviewed')
                  "
                />
                <span
                  *ngIf="
                    isCheckbox || roleHasReviewedTab(internalAuditorRoleId)
                  "
                  >تم مراجعة الملف</span
                >
                <img
                  *ngIf="
                    !isCheckbox && roleHasReviewedTab(internalAuditorRoleId)
                  "
                  src="assets/images/checked.svg"
                />
              </div>

              <span
                *ngIf="
                  showValidationMessage &&
                  !evaluationForms[
                    documentInspectorStateService?.tabDataIndex
                  ].get('fileHasBeenReviewed')?.value
                "
                class="error-msg"
              >
                * هذا الحقل مطلوب
              </span>
            </div>

            <div
              class="criteria-items"
              *ngIf="
                criteriaList?.[documentInspectorStateService?.tabDataIndex]
                  ?.length
              "
            >
              <div
                class="criteria-item__container"
                *ngFor="let item of filterFailedCriteria(); let i = index"
              >
                <div class="criteria-item">
                  <input
                    *ngIf="isCheckbox"
                    class="checkbox"
                    type="checkbox"
                    [formControl]="
                      evaluationForms[
                        documentInspectorStateService?.tabDataIndex
                      ].get([
                        'criteriaItems',
                        item.programDocumentCriteriaMappingsId,
                        'checkbox'
                      ])
                    "
                    (change)="
                      evaluationForms[
                        documentInspectorStateService?.tabDataIndex
                      ]
                        .get([
                          'criteriaItems',
                          item.programDocumentCriteriaMappingsId,
                          'note'
                        ])
                        .markAsTouched()
                    "
                  />
                  <span
                    [ngClass]="{
                      passed: item.isPassed,
                      rejected: !item.isPassed
                    }"
                  >
                    {{ item.documentCriteriaLookup.name }}
                  </span>
                  <img
                    *ngIf="!isCheckbox && item.isPassed"
                    src="assets/images/checked.svg"
                  />
                  <img
                    *ngIf="!isCheckbox && !item.isPassed"
                    src="assets/images/reject-icon.svg"
                    class="reject-icon"
                  />
                </div>
                <div
                  class="notes-wrapper"
                  *ngIf="!(isLoading | async) || saveEvaluationIsClicked"
                >
                  <app-prev-note
                    [showUserName]="!userIsTrainingOrg"
                    [notes]="
                      filterCriteriaById(
                        allNotes,
                        item.programDocumentCriteriaMappingsId
                      )[0]?.documentCriteriaLookup?.criteriaNotes
                    "
                    [isDocumentInspector]="true"
                  >
                  </app-prev-note>
                </div>
                <input
                  *ngIf="
                    isCheckbox || showCriteriaNotesField || userIsTrainingOrg
                  "
                  type="text"
                  placeholder="الملاحظات"
                  [formControl]="
                    evaluationForms[
                      documentInspectorStateService?.tabDataIndex
                    ].get([
                      'criteriaItems',
                      item.programDocumentCriteriaMappingsId,
                      'note'
                    ])
                  "
                  class="criteria-input"
                  [ngClass]="{
                    'invalid-criteria-input': isInputInvalid(
                      item?.programDocumentCriteriaMappingsId
                    )
                  }"
                />
                <span
                  *ngIf="
                    isInputInvalid(item?.programDocumentCriteriaMappingsId)
                  "
                  class="error-msg"
                >
                  * هذا الحقل مطلوب
                </span>
              </div>
            </div>

            <app-custom-button
              *ngIf="isCheckbox"
              [hasText]="false"
              [buttonStyle]="'greenButton'"
              [customClass]="'add-criteria__btn'"
              (click)="criteriaDropdown($event)"
            >
              <app-svg-icon name="plus-icon" class="plus-icon" />إضافة معيار
            </app-custom-button>
            <div
              class="add-criteria__dropdown"
              *ngIf="criteriaListIsOpen"
              clickOutSide
              (clickOutside)="HideAdditionalCriteriasList()"
            >
              <p-accordion *ngFor="let item of filterCriteriasDropDownData()">
                <p-accordionTab
                  [ngClass]="customClass"
                  [header]="item.programDocuments.value"
                >
                  <div class="accordion-content">
                    <div
                      class="accordion-item"
                      (click)="selectItem(listItem)"
                      *ngFor="let listItem of item.programDocumentCriteria"
                    >
                      {{ listItem.value }}
                    </div>
                  </div>
                </p-accordionTab>
              </p-accordion>
            </div>
            <app-svg-icon
              *ngIf="
                showCriteriaNotesField ||
                (userIsTrainingOrg &&
                  (filterFailedCriteria()?.length ||
                    !criteriaList?.[documentInspectorStateService?.tabDataIndex]
                      ?.length))
              "
              name="two-sided-arrow"
              class="two-sided-arrow"
            />
            <div
              class="other-notes__container"
              *ngIf="showCriteriaNotesField || userIsTrainingOrg"
            >
              <label class="other-notes__title">ملاحظات أخرى</label>
              <textarea
                [rows]="10"
                [cols]="30"
                placeholder="الملاحظات"
                class="criteria-input"
                [formControl]="
                  evaluationForms[
                    documentInspectorStateService?.tabDataIndex
                  ]?.get('otherNotes')
                "
              ></textarea>
            </div>
            <app-custom-button
              *ngIf="showCriteriaNotesField || userIsTrainingOrg"
              [disabled]="
                evaluationForms[documentInspectorStateService?.tabDataIndex]
                  ?.pristine || savingEvaluation
              "
              [buttonText]="'حفظ'"
              [buttonStyle]="'greenButton'"
              [type]="'submit'"
            />
          </div>
        </form>
      </div>
    </div>
  </div>
  <app-modal
    [modalContent]="'submission'"
    [showModal]="savingEvaluation"
    (closeModal)="hideModal()"
    [customClass]="'success-modal'"
    [isCloseVisible]="!(isLoading | async)"
  >
    <loading-spinner>جاري حفظ المعايير...</loading-spinner>
    <div class="modal-body" *ngIf="!(isLoading | async)">
      <app-svg-icon class="success-icon" name="success-icon" />
      <span class="modal-text">تم حفظ المعايير</span>
    </div>
  </app-modal>

  <app-modal
    [modalContent]="'submission'"
    [showModal]="criteriaAddedSuccessfully"
    (closeModal)="hideModal()"
    [customClass]="'success-modal'"
  >
    <div class="modal-body">
      <app-svg-icon class="success-icon" name="success-icon"></app-svg-icon>
      <span class="modal-text">تم إضافة معيار</span>
    </div>
  </app-modal>

  <app-modal
    [modalContent]="'submission'"
    [showModal]="showAutoSaveModal"
    (closeModal)="
      showAutoSaveModal = false; bodyScrollService.toggleBodyScroll(false)
    "
    [customClass]="'success-modal'"
    [isCloseVisible]="!(isSavingAllCriteria | async)"
  >
    <loading-spinner [isLoading$]="isSavingAllCriteria"
      >جاري حفظ التقييمات...</loading-spinner
    >
    <div
      *ngIf="!(isSavingAllCriteria | async)"
      class="modal-body modal-only-text"
    >
      <span class="modal-text">{{ 'هل ترغب في حفظ التقييمات؟' }}</span>
      <div class="modal-buttons-container">
        <app-custom-button
          (click)="saveAllEvaluations()"
          [buttonText]="'نعم، تأكيد'"
          [buttonStyle]="'greenButton'"
        ></app-custom-button>
        <app-custom-button
          (click)="closeAutoSaveModal()"
          [buttonText]="'إلغاء'"
          [buttonStyle]="'transperantGreenButton'"
        ></app-custom-button>
      </div>
    </div>
  </app-modal>
</div>
