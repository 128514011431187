import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.scss'],
})
export class VerifyOTPComponent {
  verifyOTPForm: FormGroup;
  userId: string;
  errorMessage: string | null = null;
  email: string = localStorage.getItem('otp_email');

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute,
  ) {
    this.verifyOTPForm = this.fb.group({
      otp0: ['', Validators.required],
      otp1: ['', Validators.required],
      otp2: ['', Validators.required],
      otp3: ['', Validators.required],
      otp4: ['', Validators.required],
      otp5: ['', Validators.required],
    });
  }

  ngOnInit() {
    if (this.authService.isUserAuthenticated())
      this.router.navigate(['/dashboard']);
    this.email ??= this.route.snapshot.queryParamMap.get('email');
    if (!this.email) this.router.navigate(['/login']);
  }

  onInput(event: Event, index: number): void {
    const input = event.target as HTMLInputElement;
    const value = input.value;

    if (value) {
      this.setControlValue(index, value);
      this.focusNext(index);
    }
  }

  onKeyDown(event: KeyboardEvent, index: number): void {
    if (event.code === 'Backspace') {
      event.preventDefault();
      this.setControlValue(index, '');
      this.focusPrevious(index);
    }
  }

  setControlValue(index: number, value: string): void {
    const controlName = 'otp' + index;
    this.verifyOTPForm.get(controlName)?.setValue(value);
  }

  focusNext(index: number): void {
    const nextIndex = index + 1;
    if (nextIndex < 6) {
      const nextInput = document.getElementById(`otp-input-${nextIndex}`);
      if (nextInput) {
        nextInput.focus();
      }
    }
  }

  focusPrevious(index: number): void {
    const previousIndex = index - 1;
    if (previousIndex >= 0) {
      const previousInput = document.getElementById(
        `otp-input-${previousIndex}`,
      );
      if (previousInput) {
        previousInput.focus();
      }
    }
  }

  onPaste(event: ClipboardEvent): void {
    const clipboardData = event.clipboardData;
    if (clipboardData) {
      const pastedText = clipboardData.getData('text');

      if (pastedText && pastedText.length <= 6) {
        const digits = pastedText.split('');

        digits.forEach((digit, index) => {
          this.setControlValue(index, digit);
        });

        this.focusNext(digits.length - 1);
      }

      event.preventDefault();
    }
  }

  verifyOtp(): void {
    const otp = Object.values(this.verifyOTPForm.value).join('');

    this.authService.verifyOtp(this.email, otp).subscribe({
      next: (response) => {
        if (response && response.errorCode === 0) {
          localStorage.removeItem('otp_email');
          if (response.data?.isNewUser) {
            this.router.navigate(['/login']);
          } else {
            localStorage.setItem('otp_token', response.data.token);
            this.router.navigate(['/change-password']);
          }
        } else {
          this.errorMessage = 'الرمز غير صحيح. الرجاء المحاولة مرة أخرى.'; // Set error message
        }
      },
    });
  }

  resendOtp(): void {
    this.authService.sendOtp(this.email).subscribe();
    this.verifyOTPForm = this.fb.group({
      otp0: ['', Validators.required],
      otp1: ['', Validators.required],
      otp2: ['', Validators.required],
      otp3: ['', Validators.required],
      otp4: ['', Validators.required],
      otp5: ['', Validators.required],
    });
  }
}
