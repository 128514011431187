<app-header></app-header>
<div class="signup-container">
  <div class="signup-container__form">
    <h1 class="signup-form__title">{{ 'createNewAccount' | translate }}</h1>
    <form [formGroup]="signupForm" (ngSubmit)="onSubmit()">
      <!-- Name Input -->
      <label class="form-label" for="name">اسم الجهة التدريبية</label>
      <input
        class="form-input"
        formControlName="organizationName"
        id="name"
        placeholder="اسم الجهة التدريبية"
        required
        type="text"
      />

      <!-- Phone Number Input -->
      <label class="form-label" for="phone">رقم جوال ممثل الجهة</label>
      <div class="country-code-input">
        <input
          class="form-input"
          formControlName="phoneNumber"
          id="phone"
          placeholder="XXXXXXXXX"
          required
          type="number"
        />
        <span class="country-code">966+</span>
      </div>

      <!-- Email Input -->
      <label class="form-label" for="email">{{ 'email' | translate }}</label>
      <input
        class="form-input"
        formControlName="email"
        id="email"
        placeholder="example@example.com"
        required
        type="email"
      />
      <div
        class="error"
        *ngIf="
          signupForm.get('email')?.hasError('email') &&
          signupForm.get('email')?.touched
        "
      >
        الرجاء ادخال بريد الكتروني صحيح
      </div>
      <div class="error" *ngIf="errorSignup">البريد الإلكتروني مستخدم</div>
      <div class="password-container">
        <label class="form-label" for="password">{{
          'password' | translate
        }}</label>
        <input
          class="form-input"
          formControlName="password"
          id="password"
          placeholder="{{ 'password' | translate }}"
          required
          type="{{ showPassword ? 'text' : 'password' }}"
        />
        <app-svg-icon
          *ngIf="showPassword"
          name="hide-eye"
          [ngClass]="
            signupForm.get('password')?.hasError('pattern') &&
            signupForm.get('password')?.touched
              ? 'hide-error-icon'
              : 'hide-eye'
          "
          (click)="togglePassword('password')"
        />
        <app-svg-icon
          *ngIf="!showPassword"
          (click)="togglePassword('password')"
          name="eye-icon"
          [ngClass]="
            signupForm.get('password')?.hasError('pattern') &&
            signupForm.get('password')?.touched
              ? 'error-eye'
              : 'eye-icon'
          "
        ></app-svg-icon>
        <div
          class="error"
          *ngIf="
            signupForm.get('password')?.hasError('pattern') &&
            signupForm.get('password')?.touched
          "
        >
          الرجاء ادخال كلمة مرور مطابقة للشروط:
          <ul>
            <li>8-25 أحرف على الأقل</li>
            <li>حرف كبير واحد على الأقل</li>
            <li>حرف صغير واحد على الأقل</li>
            <li>رمز من الرموز الخاصة مثل * أو #</li>
            <li>رقم واحد على الأقل</li>
          </ul>
        </div>
      </div>
      <div
        *ngIf="
          signupForm.get('password')?.hasError('required') &&
          signupForm.get('password')?.touched
        "
        class="error"
      >
        الرجاء ادخال كلمة سر
      </div>
      <div class="password-container">
        <label class="form-label" for="confirmPassword">{{
          'confirmPassword' | translate
        }}</label>
        <input
          class="form-input"
          formControlName="confirmPassword"
          id="confirmPassword"
          placeholder="{{ 'confirmPassword' | translate }}"
          required
          type="{{ showConfirmPassword ? 'text' : 'password' }}"
        />
        <app-svg-icon
          *ngIf="showConfirmPassword"
          name="hide-eye"
          class="hide-eye"
          (click)="togglePassword('confirmPassword')"
        />
        <app-svg-icon
          *ngIf="!showConfirmPassword"
          name="eye-icon"
          class="eye-icon"
          (click)="togglePassword('confirmPassword')"
        />
      </div>
      <div
        *ngIf="
          signupForm.hasError('passwordMismatch') &&
          signupForm.get('confirmPassword')?.touched
        "
        class="error"
      >
        كلمة السر غير متطابقة
      </div>
      <button
        [disabled]="signupButtonIsDisabled"
        class="submit-btn"
        type="submit"
      >
        {{ 'createNewAccount' | translate }}
      </button>
    </form>
    <div class="create-account__text">
      {{ 'alreadyHaveAccount' | translate }}
      <a class="create-account" routerLink="/login">{{
        'signin' | translate
      }}</a>
    </div>
  </div>

  <div class="image-container">
    <img src="assets/images/login.png" alt="Image" />
  </div>
</div>
