<p-editor
  dir="ltr"
  (onTextChange)="onTextChange($event)"
  [formControl]="control"
  (onInit)="onEditorInit($event)"
  [style]="{ height: '320px' }"
>
  <ng-template pTemplate="header">
    <span class="ql-formats">
      <button class="ql-direction" value="rtl"></button>
    </span>
    <span class="ql-formats">
      <button class="ql-header" value="2"></button>
    </span>
    <span class="ql-formats">
      <button type="button" class="ql-bold" aria-label="Bold"></button>
      <button type="button" class="ql-italic" aria-label="Italic"></button>
      <button
        type="button"
        class="ql-underline"
        aria-label="Underline"
      ></button>
    </span>
    <span class="ql-formats">
      <select class="ql-align">
        <option value=""></option>
        <option value="center"></option>
        <option value="right"></option>
        <option value="justify"></option>
      </select>
    </span>
    <span class="ql-formats">
      <button class="ql-list" value="ordered"></button>
      <button class="ql-list" value="bullet"></button>
    </span>
    <span class="ql-formats">
      <button class="ql-link"></button>
    </span>
  </ng-template>
</p-editor>
