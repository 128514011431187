<div class="programs-card__container">
  <div class="program-card">
    <img
      class="pragram-card__img"
      src="{{ data.imageSrc | translate }}"
      alt="Image Description"
    />
    <div class="card-body">
      <div class="card-body__text">
        <div class="translation-container">
          <app-svg-icon
            name="language-icon"
            class="language-icon"
          ></app-svg-icon>
          <span class="language-text">{{ 'langageText' | translate }}</span>
        </div>

        <h3 class="card-title">{{ data.title | translate }}</h3>
        <p class="card-text">
          {{ data.description | translate }}
        </p>
      </div>
      <a class="card-body__btn">{{ 'applyBtn' | translate }}</a>
    </div>
  </div>
</div>
