import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ScrollToTopService } from '@app/dashboard/services/scroll-to-top.service';
import { UserRole } from '@app/types/roles';
import { AuthGuard } from 'src/app/guards/auth.guard';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-signin-form',
  templateUrl: './signin-form.component.html',
  styleUrls: ['./signin-form.component.scss'],
})
export class SigninFormComponent implements OnInit {
  signInForm: FormGroup;
  errorLoginText: string;
  showPassword: boolean = false;
  signInButtonIsDisabled: boolean = false;
  isFirstLogin: boolean;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private authGuard: AuthGuard,
    private scrollService: ScrollToTopService,
  ) {
    this.signInForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.scrollService.scrollToTop();
  }

  onSubmit() {
    if (this.signInForm.valid) {
      this.signInButtonIsDisabled = true;
      const email = this.signInForm.get('email')!.value;
      const password = this.signInForm.get('password')!.value;

      this.authService.signIn(email, password).subscribe({
        next: (response) => {
          if (response.errorCode === 0) {
            this.isFirstLogin = localStorage.getItem('isFirstLogin') == 'true';

            if (this.authGuard.redirectionUrl) {
              this.router.navigateByUrl(this.authGuard.redirectionUrl);
              this.authGuard.setRedirectionUrl('');
            } else if (
              this.isFirstLogin &&
              this.authService.hasRole(UserRole.TrainingOrganization)
            ) {
              this.router.navigate(['/profile']);
            } else this.router.navigate(['/dashboard']);
          } else if (response.errorCode === 54) {
            this.errorLoginText = 'الرجاء تفعيل الحساب';
            this.signInButtonIsDisabled = false;
          } else {
            this.errorLoginText = 'البريد الالكتروني او كلمة السر غير صحيحين';

            this.signInButtonIsDisabled = false;
          }
        },
      });
    }
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }
}
