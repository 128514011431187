import { Component, Input } from '@angular/core';
import { FileDownloadService } from 'src/app/dashboard/services/file-download.service';
import { LoadingService } from 'src/app/services/loading.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'file-download',
  templateUrl: './file-download.component.html',
  styleUrl: './file-download.component.scss',
  providers: [LoadingService],
})
export class FileDownloadComponent {
  @Input() files: any[] = [];
  @Input() buttonText: string = '';
  @Input() hasText: boolean = false;

  isLoading: Observable<boolean> = this.loadingService.isLoading$();

  constructor(
    private fileDownloadService: FileDownloadService,
    private loadingService: LoadingService,
  ) {}

  async downloadFiles() {
    this.loadingService.startLoading();
    for (const file of this.files) {
      await this.fileDownloadService.downloadFile(
        file?.id || file?.file?.id || file?.archivedFiles?.at(-1)?.file?.id,
        file?.fileName ||
          file?.file?.fileName ||
          file?.archivedFiles?.at(-1)?.file?.fileName,
      );
    }
    this.loadingService.stopLoading();
  }
}
