import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuditService } from '@app/dashboard/services/audit.service';
import { LoadingService } from 'src/app/services/loading.service';
import { Observable } from 'rxjs';
import { LookupsTypeEnum } from '@app/dashboard/types/lookUps';
import { Status } from '@app/dashboard/types/status';

@Component({
  selector: 'general-manager-table',
  templateUrl: './general-manager-table.component.html',
  styleUrl: './general-manager-table.component.scss',
})
export class GeneralManagerTableComponent {
  headers: any[] = [
    { name: 'رقم الطلب', value: 'reportId' },
    { name: 'اسم الجهة', value: 'organizationName' },
    { name: 'تاريخ الطلب', value: 'creationDate' },
    { name: 'حالة الإيصال', value: 'trainingOrganizationStatusStr' },
  ];

  status: string[] = [];
  tableData: any;
  showModal: boolean = false;

  constructor(
    private router: Router,
    private auditService: AuditService,
    private loadingService: LoadingService,
  ) {}

  isLoading: Observable<boolean> = this.loadingService.isLoading$();

  ngOnInit(): void {
    this.loadingService.startLoading();
    this.getTableData();
    this.getFilterData(
      LookupsTypeEnum.TrainingOrganizationStatusEnum,
      this.status,
    );
  }

  getFilterData(lookup: number, filterValue: string[]) {
    this.auditService.getFiltersData(lookup).subscribe({
      next: (data) => {
        filterValue.length = 0;
        Array.prototype.push.apply(filterValue, data.data);
      },
      error: (error) => {
        console.error('Error fetching data:', error);
      },
    });
  }

  getTableData() {
    const body = {
      pageCount: 1,
      pageSize: 10,
      reportId: '',
      allowSearch: false,
      organizationName: '',
      fromDate: '',
      toDate: '',
      languageTypeId: '',
      trainingOrganizationStatusId:
        Status.ApprovedByCommitteeSecretaryWaitingGeneralManager,
    };

    this.auditService.getOrgFilteredData(body).subscribe({
      next: (data) => {
        this.loadingService.stopLoading();
        this.tableData = data.data;
      },
      error: (error) => {
        console.log('error', error);
      },
    });
  }

  sendToGeneralManagerHandler() {
    this.showModal = true;
  }
}
