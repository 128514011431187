import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Tab } from 'src/app/dashboard/types/tabs.type';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent {
  @Input() tabs: Tab[];
  @Output() tabSelected = new EventEmitter<number>();
  activeTabIndex: number = 0;

  setActiveTab(index: number) {
    this.activeTabIndex = index;
    this.tabSelected.emit(this.activeTabIndex);
  }
}
