import { Component } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
  showSelect = false;

  userItems = [
    {
      svg: '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" fill="none"><path d="M1 8L10 1L19 8V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21H3C2.46957 21 1.96086 20.7893 1.58579 20.4142C1.21071 20.0391 1 19.5304 1 19V8Z" stroke="#808080" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M7 21V11H13V21" stroke="#808080" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>',
      text: 'home',
      route: '/home',
    },
    {
      svg: '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M8 1H1V8H8V1Z" fill="#white" stroke="#808080" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M19 1H12V8H19V1Z" fill="#white" stroke="#808080" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M19 12H12V19H19V12Z" fill="#white" stroke="#808080" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M8 12H1V19H8V12Z" fill="#white" stroke="#808080" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>',
      text: 'dashboard',
      route: '/dashboard',
    },
    {
      svg: '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M17 1H3C1.89543 1 1 1.89543 1 3V17C1 18.1046 1.89543 19 3 19H17C18.1046 19 19 18.1046 19 17V3C19 1.89543 18.1046 1 17 1Z" stroke="#808080" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M8 5H5V14H8V5Z" stroke="#808080" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M15 5H12V10H15V5Z" stroke="#808080" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>',
      text: 'theTrainedPrograms',
      route: '/test',
    },
    {
      svg: '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 18 22" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M1.05546 1.05546C1.57118 0.539731 2.27065 0.25 3 0.25H11C11.1989 0.25 11.3897 0.329018 11.5303 0.46967L17.5303 6.46967C17.671 6.61032 17.75 6.80109 17.75 7V8V19C17.75 19.7293 17.4603 20.4288 16.9445 20.9445C16.4288 21.4603 15.7293 21.75 15 21.75H3C2.27065 21.75 1.57118 21.4603 1.05546 20.9445C0.539731 20.4288 0.25 19.7293 0.25 19V3C0.25 2.27065 0.539731 1.57118 1.05546 1.05546ZM11.75 2.81066L16.1893 7.25H11.75V2.81066ZM10.25 2C10.25 1.91234 10.265 1.82819 10.2927 1.75H3C2.66848 1.75 2.35054 1.8817 2.11612 2.11612C1.8817 2.35054 1.75 2.66848 1.75 3V19C1.75 19.3315 1.8817 19.6495 2.11612 19.8839C2.35054 20.1183 2.66848 20.25 3 20.25H15C15.3315 20.25 15.6495 20.1183 15.8839 19.8839C16.1183 19.6495 16.25 19.3315 16.25 19V8.75H11C10.5858 8.75 10.25 8.41421 10.25 8V2ZM5 11.25C4.58579 11.25 4.25 11.5858 4.25 12C4.25 12.4142 4.58579 12.75 5 12.75H13C13.4142 12.75 13.75 12.4142 13.75 12C13.75 11.5858 13.4142 11.25 13 11.25H5ZM4.25 16C4.25 15.5858 4.58579 15.25 5 15.25H13C13.4142 15.25 13.75 15.5858 13.75 16C13.75 16.4142 13.4142 16.75 13 16.75H5C4.58579 16.75 4.25 16.4142 4.25 16ZM5 7.25C4.58579 7.25 4.25 7.58579 4.25 8C4.25 8.41421 4.58579 8.75 5 8.75H6H7C7.41421 8.75 7.75 8.41421 7.75 8C7.75 7.58579 7.41421 7.25 7 7.25H6H5Z" fill="#808080"/></svg>',
      text: 'myApplications',
      route: '/lol',
    },
  ];

  activeRoute: string = ''; // Initialize a variable to store the active route

  constructor(
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
  ) {
    this.route.url.subscribe((segments) => {
      this.activeRoute = segments[0].path;
    });
  }
  sanitizeSvg(svgString: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(svgString);
  }

  toggleSelect() {
    this.showSelect = !this.showSelect;
  }
}
