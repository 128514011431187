<div class="utility-icons-container">
  <button class="btn notification-btn">
    <app-svg-icon name="notification-icon-with-circle"></app-svg-icon>
  </button>
  <button class="btn help-btn">
    <app-svg-icon class="help-icon" name="help-icon"></app-svg-icon>
  </button>
  <div class="custom-dropdown" (click)="toggleSelect()">
    <span>
      <app-svg-icon class="arrow-down" name="arrow-down"></app-svg-icon>
    </span>
    <div
      class="user-guide"
      *ngIf="showSelect"
      (click)="$event.stopPropagation()"
    >
      {{ 'userGuide' | translate }}
    </div>
  </div>
</div>
