import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PdfViewerSyncService {
  private zoomLevelSource = new BehaviorSubject<number | string>('');
  private scrollPositionSource = new BehaviorSubject<{
    top: number;
    left: number;
  }>({ top: 0, left: 0 });

  zoomLevel$ = this.zoomLevelSource.asObservable();
  scrollPosition$ = this.scrollPositionSource.asObservable();

  updateZoomLevel(zoom: number | string) {
    this.zoomLevelSource.next(zoom);
  }

  updateScrollPosition(scrollPosition) {
    this.scrollPositionSource.next(scrollPosition);
  }
}
