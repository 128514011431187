import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ObjectToFormDataService } from 'src/app/services/object-to-form-data.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ExpertApplicationService {
  private url: string = `${environment.apiUrl}/api/TrainingProgramRequest/UpdateStatusTrainingOrganization`;
  constructor(
    private http: HttpClient,
    private objectToFormDataConverter: ObjectToFormDataService,
  ) {}

  submitEvaluation(evaluationData: any): Observable<any> {
    const response = this.http.post<any>(
      this.url,
      this.objectToFormDataConverter.convert(evaluationData),
    );
    return response;
  }
}
