import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UserRole } from 'src/app/types/roles';
import { Votes } from 'src/app/types/votes';
import { AuditService } from '@app/dashboard/services/audit.service';
import { Status } from '@app/dashboard/types/status';
import { VotingService } from './voting.service';
import { LoadingService } from 'src/app/services/loading.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { DocumentNoteType } from '../../types/documentNote.type';
import { FormControl, ValidationErrors } from '@angular/forms';
import { BodyScrollService } from '@app/dashboard/services/body-scroll.service';
import { ArabicNumbersService } from '@app/services/arabic-numbers.service';

@Component({
  selector: 'committee-voting',
  templateUrl: './committee-voting.component.html',
  styleUrls: ['./committee-voting.component.scss'],
})
export class CommitteeVotingComponent implements OnInit {
  @Input() requestData: any;
  @Input() readOnly: boolean;
  @Input() requestId: number;

  @Output('activeTabIndex') tabIndex = new EventEmitter<number>();

  applicationStatus: number;
  applicationVersion: number;
  generalNotesArray: object[] = [];
  userNotes: string = '';
  editMode: boolean = false;
  totalVotes: number;
  trainingOrganizationId: number;
  activeTabIndex: number = 0;
  currentMemeberId: number;
  currentMemberVoteData: any;
  otherMembersVotes: any;
  currentVote: number = Votes.NotVoted;
  submittedVote: boolean = false;
  showSendForPaymentDialog: boolean = false;
  showSendForCommitteeSecretary: boolean = false;
  showReturnFromCommitteeSecretaryModal: boolean = false;
  returnToTechnicalAdvisor: boolean = false;
  sentSuccessfully: boolean = false;
  isCommitteeHead = this.authService.hasRole(UserRole.CommitteeHead);
  isCommitteSecretary = this.authService.hasRole(UserRole.CommitteeSecretary);
  isCommitteeMember = this.authService.hasRole(UserRole.CommitteeMember);
  votes = Votes;
  isSubmitButtonDisabled: boolean = false;
  isRejectionDialogOpen: boolean = false;
  rejectionReasonControl: FormControl;
  rejectionReasonText: string = '';
  noNotesHTML: string =
    '<p class="ql-direction-rtl ql-align-right">لا توجد ملاحظات</p>';
  previousDiscussionBoardsData: any;
  isDiscussionBoardAccordionVisible: boolean = false;

  constructor(
    private votingService: VotingService,
    private authService: AuthService,
    private auditService: AuditService,
    private router: Router,
    private el: ElementRef,
    private loadingService: LoadingService,
    private numbersToArabicService: ArabicNumbersService,
    public bodyScrollService: BodyScrollService,
  ) {
    bodyScrollService.modalScrollEnabled.subscribe((enableScroll) => {
      if (enableScroll) {
        document.body.classList.remove('modal-open');
      } else {
        document.body.classList.add('modal-open');
      }
    });
  }

  isLoading: Observable<boolean> = this.loadingService.isLoading$();
  isLoadingSummary: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );

  ngOnInit(): void {
    this.currentMemeberId = this.authService.getUserId();

    this.trainingOrganizationId =
      this.requestData.trainingOrganization.trainingOrganizationId;

    this.applicationVersion =
      this.requestData.trainingOrganization.applicationVersion;

    this.applicationStatus =
      this.requestData.workFlowTrainingOrganization.trainingOrganizationStatusLookupId;

    this.getVotesData();

    switch (this.applicationStatus) {
      case Status.DiscussionAndVoting:
        this.getExecutiveSummary();
        break;

      case Status.ApprovedByCommitteeHeadWaitingCommitteeSecretary:
      case Status.RejectedByCommitteeHeadToCommitteeSecretary:
      case Status.RenewalRequestHasBeenRejectedByCommitteeHeadToCommitteeSecretary:
        this.getPreviousDiscussionBoardData();
        break;
    }

    if (
      this.applicationStatus ===
        Status.RejectedByCommitteeHeadToCommitteeSecretary ||
      this.applicationStatus ===
        Status.RenewalRequestHasBeenRejectedByCommitteeHeadToCommitteeSecretary
    ) {
      this.rejectionReasonControl = new FormControl('', [
        this.rejectionReasonControlValidator(),
      ]);
    }
  }

  rejectionReasonControlValidator() {
    return (): ValidationErrors | null =>
      this.rejectionReasonText ? null : { required: true };
  }

  getVotesData() {
    this.votingService
      .getVotesData(this.trainingOrganizationId)
      .subscribe((response) => {
        const votingData = response.data;

        this.totalVotes = votingData?.totalVotes;

        this.currentMemberVoteData = votingData.commissionerVoteDtos.find(
          (vote: any) => vote?.member?.id === this.currentMemeberId,
        );
        this.otherMembersVotes = votingData.commissionerVoteDtos.filter(
          (vote: any) => vote?.member?.id !== this.currentMemeberId,
        );

        if (this.currentMemberVoteData) {
          this.editMode =
            this.currentMemberVoteData.commissionerVoteStatusId ===
            Votes.NotVoted;
          this.currentVote =
            this.currentMemberVoteData?.commissionerVoteStatusId;
          this.userNotes = this.currentMemberVoteData.note;
        }
      });
  }

  getExecutiveSummary() {
    this.isLoadingSummary.next(true);
    this.votingService
      .getExecutiveSummary(this.trainingOrganizationId)
      .subscribe((res) => {
        this.generalNotesArray = res.data;
        this.generalNotesArray.forEach(
          (stage: any, stageIndex) =>
            stage.notes?.forEach((note: any) => {
              if (note.roleId === UserRole.InternalAuditor)
                this.generalNotesArray[stageIndex]['internalAuditorNote'] =
                  note.note;
              if (note.roleId === UserRole.TechnicalAdvisor)
                this.generalNotesArray[stageIndex]['technicalAdvisorNote'] =
                  note.note;
              if (note.roleId === UserRole.CommitteeSecretary)
                this.generalNotesArray[stageIndex]['committeeSecretaryNote'] =
                  note.note;
            }),
        );
        this.isLoadingSummary.next(false);
      });
  }

  getPreviousDiscussionBoardData() {
    this.votingService
      .getApplicationDiscussionBoard(this.trainingOrganizationId)
      .subscribe((res) => {
        this.previousDiscussionBoardsData = res.data;
        this.isDiscussionBoardAccordionVisible =
          this.previousDiscussionBoardsData?.some(
            (board) =>
              board.discussionBoardThreads?.length ||
              board.committeeSecretaryNote?.note,
          );
      });
  }

  handleSubmit(): void {
    this.isSubmitButtonDisabled = true;
    this.votingService
      .sendVoteData(
        this.trainingOrganizationId,
        this.currentVote,
        this.userNotes,
        this.currentMemberVoteData?.id,
      )
      .subscribe((response) => {
        if (response.errorCode === 0) {
          this.bodyScrollService.toggleBodyScroll(false);
          this.totalVotes = response.data?.totalVotes;
          this.submittedVote = true;
          this.currentMemberVoteData = response.data?.commissionerVoteDtos.find(
            (vote: any) => vote?.member?.id === this.currentMemeberId,
          );
          this.editMode = false;
          this.scrollIntoView('instant');
        }
      });
  }

  handleVote(status: Votes) {
    this.currentVote = status;
  }

  stageHeaderTextHandler(index: number) {
    return `المرحلة ${this.numbersToArabicService.getNumbersInArabicText(
      index,
    )}`;
  }

  getAcceptVotes() {
    if (this.otherMembersVotes)
      return this.otherMembersVotes.reduce(
        (votesNumber: number, currentVote: any) => {
          if (currentVote.commissionerVoteStatusId === Votes.Accepted)
            return votesNumber + 1;
          else return votesNumber;
        },
        this.currentVote === Votes.Accepted ? 1 : 0,
      );
  }

  getDecisionClass(decision: number) {
    switch (decision) {
      case 1:
        return 'agree';

      case 2:
        return 'disapproval';

      default:
        return 'neutral';
    }
  }

  getVoteString(decision: number) {
    switch (decision) {
      case 1:
        return 'مقبول';
      case 2:
        return 'مرفوض';

      default:
        return 'لم يصوت بعد';
    }
  }

  editVoteClickHandler() {
    if (this.editMode) {
      this.handleSubmit();
    } else {
      this.scrollIntoView();
      this.editMode = true;
    }
  }

  votingResultsButtonText() {
    switch (this.applicationStatus) {
      case Status.ApprovedByCommitteeHeadWaitingCommitteeSecretary:
        return 'ارسال الطلب للجهة للدفع';

      case Status.ApprovedByCommitteeSecretaryWaitingGeneralManager:
        return 'إصدار شهادة الاعتماد';

      case Status.RenewalRequestHasBeenAcceptedByCommitteeSecretaryToGeneralManager:
        return 'تجديد شهادة الاعتماد';

      case Status.RejectedByCommitteeHeadToCommitteeSecretary:
      case Status.RenewalRequestHasBeenRejectedByCommitteeHeadToCommitteeSecretary:
        return 'تسبيب الرفض';

      default:
        return '';
    }
  }

  votingResultsButtonHandler() {
    switch (
      this.requestData.workFlowTrainingOrganization
        .trainingOrganizationStatusLookupId
    ) {
      case Status.ApprovedByCommitteeHeadWaitingCommitteeSecretary:
        this.showSendForPaymentDialog = true;
        this.bodyScrollService.toggleBodyScroll(false);
        break;

      case Status.ApprovedByCommitteeSecretaryWaitingGeneralManager:
      case Status.RenewalRequestHasBeenAcceptedByCommitteeSecretaryToGeneralManager:
        this.router.navigate(['/dashboard/certificate/', this.requestId]);
        break;

      case Status.RejectedByCommitteeHeadToCommitteeSecretary:
      case Status.RenewalRequestHasBeenRejectedByCommitteeHeadToCommitteeSecretary:
        this.isRejectionDialogOpen = true;
        this.bodyScrollService.toggleBodyScroll(false);
        break;

      default:
        break;
    }
  }

  sendApplicationForPayment() {
    this.isSubmitButtonDisabled = true;
    this.sentSuccessfully = true;
    this.loadingService.startLoading();
    this.auditService
      .updateApplicationStatus({
        TrainingOrganizationStatusEnum:
          Status.ApprovedByCommitteeSecretaryWaitingPayment,
        TrainingOrganizationId:
          this.requestData.trainingOrganization?.trainingOrganizationId,
      })
      .subscribe({
        next: () => {
          this.loadingService.stopLoading();
        },
        error: (error) => {
          console.log('Error sending the data', error);
        },
      });
  }

  rejectAppToTrainingOrg() {
    this.sentSuccessfully = true;
    this.loadingService.startLoading();
    this.auditService
      .updateApplicationStatus({
        trainingOrganizationStatusEnum:
          Status.RejectedByCommitteeSecretaryToTrainingOrganization,
        trainingOrganizationId:
          this.requestData.trainingOrganization?.trainingOrganizationId,
        trainingProgramDocumentNotes: [
          {
            documentNoteTypeId: DocumentNoteType.ReasonsOfRejection,
            note: this.rejectionReasonControl.value,
          },
        ],
        HasNotes: true,
      })
      .subscribe({
        next: () => {
          this.loadingService.stopLoading();
        },
        error: (error) => {
          console.log('Error sending the data', error);
        },
      });
  }

  returnAppFromCommitteeSecretary() {
    this.sentSuccessfully = true;
    this.loadingService.startLoading();
    const returnStatus = this.returnToTechnicalAdvisor
      ? Status.ReturnedByCommitteeSecretaryToTechnicalAdvisorAfterFormulationNote
      : Status.ReturnedByCommitteeSecretaryToInternalAuditorAfterFormulationNote;
    this.auditService
      .updateApplicationStatus({
        trainingOrganizationStatusEnum: returnStatus,
        trainingOrganizationId: this.trainingOrganizationId,
      })
      .subscribe({
        next: () => {
          this.loadingService.stopLoading();
        },
        error: (error) => {
          console.log('Error sending the data', error);
        },
      });
  }

  sendAppToCommitteeSecretary() {
    if (this.totalVotes < 5 || this.editMode) return;

    this.sentSuccessfully = true;
    this.loadingService.startLoading();
    const appStatus =
      this.getAcceptVotes() < 3
        ? Status.RejectedByCommitteeHeadToCommitteeSecretary
        : Status.ApprovedByCommitteeHeadWaitingCommitteeSecretary;
    this.auditService
      .updateApplicationStatus({
        trainingOrganizationStatusEnum: appStatus,
        trainingOrganizationId: this.trainingOrganizationId,
      })
      .subscribe({
        next: () => {
          this.loadingService.stopLoading();
        },
        error: (error) => {
          console.log('Error sending the data', error);
        },
      });
  }

  closeRejectionDialog() {
    this.isRejectionDialogOpen = false;
    if (this.showSendForPaymentDialog)
      this.bodyScrollService.toggleBodyScroll(false);
    else this.bodyScrollService.toggleBodyScroll(true);
  }

  nextPage() {
    this.tabIndex.emit(++this.activeTabIndex);
  }

  previousPage() {
    this.tabIndex.emit(--this.activeTabIndex);
  }

  closeModel() {
    if (this.sentSuccessfully) this.router.navigate(['/dashboard']);
    this.showSendForCommitteeSecretary = false;
    this.showReturnFromCommitteeSecretaryModal = false;
    this.returnToTechnicalAdvisor = false;
    this.showSendForPaymentDialog = false;
    this.submittedVote = false;
    this.isSubmitButtonDisabled = false;
    this.bodyScrollService.toggleBodyScroll(true);
  }

  scrollIntoView(behavior: string = 'smooth') {
    this.el.nativeElement.scrollIntoView({
      behavior: behavior,
      block: 'start',
    });
  }

  getTechnicalAdvisorEvaluationByStage(stage: number) {
    return this.requestData.technicalAdvisorEvaluationsFiles.find(
      (f) => f.version == stage,
    );
  }

  getFile(file) {
    this.auditService.getFile(file.id).subscribe({
      next: (url) => {
        this.downloadFile(
          url,
          file?.fileName ??
            file?.name ??
            `expertform(${this.applicationVersion})`,
        );
      },
      error: (error) => {
        this.loadingService.stopLoading();
        console.log('error', error);
      },
    });
  }

  downloadFile(url: any, filename: string) {
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = filename;
    anchor.click();

    window.URL.revokeObjectURL(url);
  }
}
