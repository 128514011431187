export const committeVotes = [
  [
    {
      name: 'Cosmic Explorer',
      position: 'lead researcher',
      agree: true,
      decision: 1, // agree
      notes:
        'يهدف البرنامج الى رفع كفاءة الطاقة في محطات إنتاج القوى الكهربائية والنتاج المزدوج حيث يناقش مواضيع ذات صلة بترشيد إستهالك الطاقة في تشغيل المحطات كاستخدام الوقود النفطي وعملية المحافظة على كفاءة المعدات والعمليات المستخدمة في إنتاج المياه والكهرباء وعمليات الحسابات الهندسية ذات العالقة.',
    },
    {
      name: 'Nova Seeker',
      position: 'communications officer',
      decision: 2, //didn't take part neutral
      notes:
        'يهدف البرنامج الى رفع كفاءة الطاقة في محطات إنتاج القوى الكهربائية والنتاج المزدوج حيث يناقش مواضيع ذات صلة بترشيد إستهالك الطاقة في تشغيل المحطات كاستخدام الوقود النفطي وعملية المحافظة على كفاءة المعدات والعمليات المستخدمة في إنتاج المياه والكهرباء وعمليات الحسابات الهندسية ذات العالقة.',
    },
    {
      name: 'Quantum Harmony',
      position: 'technician',
      decision: 3, //reject
      notes: 'particleAligner',
    },
  ],
];
