import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { UserRole } from 'src/app/types/roles';
import { AuditService } from '@app/dashboard/services/audit.service';
import { LoadingService } from 'src/app/services/loading.service';
import { Status } from '../../types/status';
import { FormControl } from '@angular/forms';
import { Assets } from '@assets/images';
import { DocumentNoteType } from 'src/app/dashboard/types/documentNote.type';

@Component({
  selector: 'general-note',
  templateUrl: './general-note.component.html',
  styleUrl: './general-note.component.scss',
  providers: [LoadingService],
})
export class GeneralNoteComponent implements OnInit {
  @Input() noteData: any = {};
  @Input() trainingOrganizationId: number;
  @Input() requestStatus: number = null;
  @Input() role?: string;
  editNotesSrc = Assets.editNotes;

  editMode: boolean = false;
  noteControl = new FormControl();
  showModal: boolean = false;
  sentSuccessfully: boolean = false;
  showEditButton: boolean;
  committeeSecretaryRoleId = UserRole.CommitteeSecretary;
  textValue: string = '';

  constructor(
    private authService: AuthService,
    private auditService: AuditService,
    private loadingService: LoadingService,
  ) {}

  isLoading = this.loadingService.isLoading$();

  ngOnInit(): void {
    this.noteControl.setValue(this.noteData?.note);
    this.textValue = this.noteData?.note;
    this.showEditButton =
      this.userIsCommitteeSecretary &&
      !this.editMode &&
      this.requestStatus <=
        Status.ApprovedByTechnicalExpertWaitingCommitteeSecretary;
  }

  userIsCommitteeSecretary: boolean = this.authService.hasRole(
    UserRole.CommitteeSecretary,
  );

  clickHandler() {
    this.editMode = true;
  }

  saveHandler() {
    this.loadingService.startLoading();
    this.showModal = true;
    const body = {
      TrainingOrganizationId: this.trainingOrganizationId,
      id: this.noteData.id,
      note: this.textValue ? this.noteControl.value : '',
      documentNoteTypeId: DocumentNoteType.GeneralNotes,
    };

    this.auditService.updateApplicationNote(body).subscribe(({ data }) => {
      this.loadingService.stopLoading();
      this.noteControl.setValue(data?.note);
      this.noteData.note = data?.note;
      this.editMode = false;
    });
  }
}
