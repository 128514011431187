<div class="main-container">
  <div class="spacing">
    <app-page-title
      title="شهادة الاعتماد"
      [customClass]="['application-details']"
    />
  </div>

  <div class="certificate">
    <div class="gradient">
      <img src="assets/images/certificate-icons.svg" />
    </div>

    <div style="margin-block: 40px">
      <img [src]="logo" />
    </div>

    <h1
      style="text-align: center; font-size: 24px; font-weight: 700"
      class="certification-content"
    >
      شهادة اعتماد البرامج التدريبية في كفاءة الطاقة
    </h1>

    <div class="certifcate-text-container">
      <div class="spacing"></div>
      <div class="spacing">
        <p class="certification-content">
          يشهد المركز السعودي لكفاءة الطاقة بأن البرنامج التدريبي:
        </p>
      </div>
      <div>
        <p class="certificate-title">
          {{ certificateData.programName }}
        </p>
        <div class="description-container">
          <p class="certification-content description">
            المقدم من {{ certificateData.organizationName }}، قد اجتاز متطلبات
            الاعتماد وفقاً للائحة اعتماد البرامج التدريبية في مجال كفاءة الطاقة،
            و&nbsp;حسب تقييم الطلب و&nbsp;تصويت لجنة اعتماد البرامج التدريبية في
            مجال كفاءة الطاقة،
          </p>
          <p class="certification-content description">
            تم منحه هذه الشهادة لمدة
            <span class="dynamic-data"> 36 شهراً </span>
            من تاريخ
            <span class="dynamic-data">
              {{ startDate }}
            </span>
            وتنتهي في تاريخ
            <span class="dynamic-data">
              {{ endDate }}
            </span>
          </p>
        </div>
      </div>

      <div class="flex sign-section">
        <div class="sign-section-item">
          <div class="signature border">
            <span class="golden"> مدير عام المركز</span>
            <img
              src="assets/images/digital-stamp.png"
              alt="digital-stamp-image"
              class="digital-stamp-image"
            />
          </div>
        </div>
        <div class="sign-section-item">
          <span class="golden border"> الرقم المرجعي</span>
          <span class="golden">
            {{ certificateData.referenceId }}
          </span>
        </div>
        <div class="sign-section-item">
          <span class="golden border"> التاريخ</span>
          <span class="golden">
            {{ startDate }}
          </span>
        </div>
        <div class="certified-stamp">
          <img class="circle-logo" [src]="circleLogo" alt="circileLogo" />
          <qrcode
            qrdata="https://kld.sociumtech.com/"
            [width]="120"
            [errorCorrectionLevel]="'M'"
          ></qrcode>
        </div>
      </div>
    </div>

    <div class="certificate-bottom-right">
      <img src="assets/images/certificate-right-side-icons.svg" />
    </div>
  </div>

  <app-custom-button
    [disabled]="submitButtonIsClicked"
    [buttonStyle]="'greenButton'"
    [buttonText]="buttonText"
    (click)="buttonClickHandler()"
  />

  <app-modal
    [modalContent]="'submission'"
    [showModal]="showModal"
    (closeModal)="closeModel()"
    [customClass]="'success-modal'"
    [isCloseVisible]="!(isLoading$ | async)"
  >
    <loading-spinner>جاري {{ buttonText }}...</loading-spinner>
    <div *ngIf="!(isLoading$ | async)" class="modal-body">
      <app-svg-icon class="success-icon" name="success-icon"></app-svg-icon>
      <span class="modal-text">تم {{ buttonText }} بنجاح.</span>
    </div>
  </app-modal>
</div>
