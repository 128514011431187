import { Component } from '@angular/core';

@Component({
  selector: 'app-training-programs',
  templateUrl: './training-programs.component.html',
  styleUrls: ['./training-programs.component.scss'],
})
export class TrainingProgramsComponent {
  cardsData = [
    {
      imageSrc: 'assets/images/card1.png',
      title: 'programSlideTitle',
      description: 'programSlideText',
      buttonLabel: 'Learn More',
    },
    {
      imageSrc: 'assets/images/card2.png',
      title: 'programSlideTitle',
      description: 'programSlideText',
      buttonLabel: 'Learn More',
    },
    {
      imageSrc: 'assets/images/card3.png',
      title: 'programSlideTitle',
      description: 'programSlideText',
      buttonLabel: 'Learn More',
    },
    {
      imageSrc: 'assets/images/card4.png',
      title: 'programSlideTitle',
      description: 'programSlideText',
      buttonLabel: 'Learn More',
    },
    {
      imageSrc: 'assets/images/card5.png',
      title: 'programSlideTitle',
      description: 'programSlideText',
      buttonLabel: 'Learn More',
    },
    {
      imageSrc: 'assets/images/card6.png',
      title: 'programSlideTitle',
      description: 'programSlideText',
      buttonLabel: 'Learn More',
    },
    {
      imageSrc: 'assets/images/card1.png',
      title: 'programSlideTitle',
      description: 'programSlideText',
      buttonLabel: 'Learn More',
    },
    {
      imageSrc: 'assets/images/card2.png',
      title: 'programSlideTitle',
      description: 'programSlideText',
      buttonLabel: 'Learn More',
    },
    {
      imageSrc: 'assets/images/card3.png',
      title: 'programSlideTitle',
      description: 'programSlideText',
      buttonLabel: 'Learn More',
    },
  ];
}
