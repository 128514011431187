<button class="notification-btn">
  <ng-container *ngIf="isNewNotification; else noNewNotifications">
    <app-svg-icon
      class="notification-icon-with-circle"
      name="notification-icon-with-circle"
      (click)="handleNotificationClick($event)"
    ></app-svg-icon>
  </ng-container>
  <ng-template #noNewNotifications>
    <app-svg-icon
      class="notification-icon"
      name="notification-icon"
      (click)="handleNotificationClick($event)"
    ></app-svg-icon>
  </ng-template>
  <div
    *ngIf="isNotificationOpen"
    class="dropdown-wrapper"
    (click)="handleDefaultClick($event)"
  >
    <div class="dropdown-header">
      <h1 class="title">الإشعارات</h1>
      <div
        class="more-notification-link"
        (click)="handleSeeMoreNotifications($event)"
        [ngClass]="{
          'more-notification-link': true,
          'no-more-notifications': !isThereMoreNotification
        }"
      >
        المزيد من الإشعارات
      </div>
    </div>
    <div class="notifications-wrapper">
      <ng-container *ngFor="let item of notificationsList">
        <div
          [ngClass]="{
            'notification-card': true,
            'unread-notification': !item.isRead
          }"
          (click)="
            handleReadNotification(
              item.notificationId,
              item.redirectUrl,
              item.isRead
            )
          "
        >
          <div class="msg-img-wrapper">
            <div class="personal-img-container">
              <img src="assets/images/alt-person.png" alt="temp img" />
            </div>
            <div class="message-container">
              <p>
                {{ item.messageAr }}
              </p>
            </div>
          </div>
          <div class="time-container">
            {{ getRelativeTime(item.deliveredAt) }}
          </div>
        </div>
        <div class="notification-divider"></div>
      </ng-container>
      <div *ngIf="notificationsList.length == 0" class="no-new-notifications">
        <!-- <app-svg-icon
          class="no-notifications"
          name="no-notifications"
        ></app-svg-icon> -->
        لا توجد إشعارات جديدة
      </div>
      <div
        class="more-notifications"
        (click)="handleSeeMoreNotifications($event)"
        *ngIf="isMoreNotificationsClicked && isThereMoreNotification"
      >
        المزيد من الإشعارات
      </div>
    </div>
  </div>
</button>
