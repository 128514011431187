import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Rejex } from '@utils/rejex';

@Component({
  selector: 'app-create-new-password',
  templateUrl: './create-new-password.component.html',
  styleUrl: './create-new-password.component.scss',
})
export class CreateNewPasswordComponent {
  createNewPassword: FormGroup;
  showPassword: boolean = false;
  showConfirmPassword: boolean = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
  ) {
    this.createNewPassword = this.fb.group(
      {
        newPassword: [
          '',
          [Validators.required, Validators.pattern(Rejex.StrongPasswordRegx)],
        ],
        confirmNewPassword: ['', [Validators.required]],
      },
      {
        validator: this.passwordMatchValidator,
      },
    );
  }

  confirmNewPassword() {
    if (this.createNewPassword.valid) {
      const newPassword = this.createNewPassword.get('newPassword').value;
      const confirmNewPassword =
        this.createNewPassword.get('confirmNewPassword').value;
      this.authService
        .changePassword(confirmNewPassword, newPassword)
        .subscribe({
          next: (response) => {
            if (response.errorCode === 0) {
              localStorage.removeItem('otp_token');
              this.router.navigate(['/login']);
            }
          },
        });
    }
  }

  togglePassword(field: 'password' | 'confirmPassword') {
    if (field === 'password') {
      this.showPassword = !this.showPassword;
    } else if (field === 'confirmPassword') {
      this.showConfirmPassword = !this.showConfirmPassword;
    }
  }

  passwordMatchValidator(g: FormGroup) {
    return g.get('newPassword').value === g.get('confirmNewPassword').value
      ? null
      : { passwordMismatch: true };
  }
}
