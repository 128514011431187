import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { text } from './testData';
import { ModalService } from 'src/app/dashboard/services/modal.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-user-guide',
  templateUrl: './user-guide.component.html',
  styleUrls: ['./user-guide.component.scss'],
})
export class UserGuideComponent {
  @Input() pdfURL: string;
  @Input() title: string;
  text: string = text;
  textChunks: string[] = [];
  selectedText: string | null = null;
  activePageIndex: number = 0;
  exportingPDF: boolean = false;
  @ViewChild('pdfViewerContainer') pdfViewerContainer: ElementRef;

  @ViewChild('content') content!: ElementRef;

  getFullPDFURL(): string {
    return document.baseURI + this.pdfURL;
  }

  constructor(
    public modalService: ModalService,
    private http: HttpClient,
  ) {}

  downloadPDF(): void {
    const link = document.createElement('a');
    link.href = this.getFullPDFURL();
    link.download = 'downloaded-file.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  closeModal(event: Event) {
    event.stopPropagation();
    this.modalService.closeModal();
  }
}
